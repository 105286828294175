.i-popup-other-offers {
    font-size: 16px;
    line-height: 22px;
    color: #8b8b8b;
    display: none;
    width: 100%;
    max-width: 100%;
    min-width: 320px;
    padding: 12px 16px 16px;
    position: fixed;
    z-index: 1001;
    background: #fff;
    border-radius: 8px 8px 0 0;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    height: 100%
}

@media (min-width: 1024px) {
    .i-popup-other-offers {
        width: calc(100% - 32px);
        max-width: 700px;
        padding: 28px 40px 40px;
        border-radius: 8px;
        position: absolute
    }
}

@media (max-width: 1023.98px) {
    .i-popup-other-offers {
        top: auto !important;
        left: 0 !important;
        bottom: 0;
        max-height: 100%
    }
}

.i-popup-other-offers .close, .i-popup-other-offers .popup__close {
    width: 26px;
    height: 26px;
    display: inline-block;
    position: relative;
    font-size: 0;
    line-height: 1;
    border: none;
    background-color: transparent;
    position: absolute;
    z-index: 1;
    top: 13px;
    right: 12px;
    cursor: pointer
}

.i-popup-other-offers .close:after, .i-popup-other-offers .close:before, .i-popup-other-offers .popup__close:after, .i-popup-other-offers .popup__close:before {
    -webkit-transition: background .3s ease 0s;
    transition: background .3s ease 0s;
    background-color: #999;
    border-radius: 2px;
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg)
}

.i-popup-other-offers .close:before, .i-popup-other-offers .popup__close:before {
    width: 2px;
    height: 20px
}

.i-popup-other-offers .close:after, .i-popup-other-offers .popup__close:after {
    width: 20px;
    height: 2px
}

@media (min-width: 1024px) {
    .i-popup-other-offers .close, .i-popup-other-offers .popup__close {
        top: 8px;
        right: 8px
    }
}

@media (max-width: 575.98px) {
    .i-popup-other-offers .close ~ .popup__content, .i-popup-other-offers .popup__close ~ .popup__content {
        padding-top: 28px
    }
}

@media (max-width: 575.98px) {
    .i-popup-other-offers .close ~ .popup__content .popup__header, .i-popup-other-offers .popup__close ~ .popup__content .popup__header {
        margin-top: -28px
    }
}

.i-popup-other-offers .popup__close-btn {
    font-size: 16px;
    line-height: 22px;
    position: absolute;
    z-index: 1;
    top: 16px;
    right: 16px;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    padding: 4px 12px 6px;
    background-color: #fff;
    border-radius: 24px;
    border: none;
    cursor: pointer
}

@media (max-width: 1023.98px) {
    .i-popup-other-offers .popup__close-btn {
        width: 26px;
        height: 26px;
        display: inline-block;
        position: relative;
        font-size: 0;
        line-height: 1;
        border: none;
        background-color: transparent;
        position: absolute;
        z-index: 1;
        top: 13px;
        right: 12px;
        cursor: pointer;
        line-height: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        padding: 0;
        border-radius: 0
    }

    .i-popup-other-offers .popup__close-btn:after, .i-popup-other-offers .popup__close-btn:before {
        -webkit-transition: background .3s ease 0s;
        transition: background .3s ease 0s;
        background-color: #999;
        border-radius: 2px;
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
        transform: translate(-50%, -50%) rotate(45deg)
    }

    .i-popup-other-offers .popup__close-btn:before {
        width: 2px;
        height: 20px
    }

    .i-popup-other-offers .popup__close-btn:after {
        width: 20px;
        height: 2px
    }
}

@media (max-width: 1023.98px) and (min-width: 1024px) {
    .i-popup-other-offers .popup__close-btn {
        top: 8px;
        right: 8px
    }
}

@media (max-width: 1023.98px) and (max-width: 575.98px) {
    .i-popup-other-offers .popup__close-btn ~ .popup__content {
        padding-top: 28px
    }
}

@media (max-width: 1023.98px) and (max-width: 575.98px) {
    .i-popup-other-offers .popup__close-btn ~ .popup__content .popup__header {
        margin-top: -28px
    }
}

.i-popup-other-offers .popup__header {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: #000;
    margin: 0 0 16px;
    padding-right: 24px
}

@media (min-width: 768px) {
    .i-popup-other-offers .popup__header {
        font-size: 20px;
        line-height: 28px
    }
}

@media (min-width: 1024px) {
    .i-popup-other-offers .popup__header {
        font-size: 28px;
        line-height: 36px
    }
}

@media (min-width: 1024px) {
    .i-popup-other-offers .popup__header {
        margin: 0 0 20px;
        padding-right: 0
    }
}

.i-popup-other-offers .popup__btn {
    margin: 10px 0 0;
    font-size: 0
}

@media (min-width: 576px) {
    .i-popup-other-offers .popup__btn {
        margin: 20px 0 0
    }
}

.i-popup-other-offers .popup__btn-main {
    font-family: system, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Segoe WP', Roboto, Ubuntu, Oxygen, Cantarell, 'Fira Sans', 'Helvetica Neue', Helvetica, 'Lucida Grande', 'Droid Sans', Tahoma, 'Microsoft Sans Serif', sans-serif;
    line-height: 1.1;
    letter-spacing: 0;
    font-weight: 700;
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    background-color: #cb11ab;
    border: 2px solid #cb11ab;
    font-size: 16px;
    padding: 11px 25px 12px;
    margin-top: 12px;
    margin-right: 20px
}

.i-popup-other-offers .popup__btn-main.disabled, .i-popup-other-offers .popup__btn-main.disabled:active, .i-popup-other-offers .popup__btn-main.disabled:hover, .i-popup-other-offers .popup__btn-main:disabled, .i-popup-other-offers .popup__btn-main:disabled:active, .i-popup-other-offers .popup__btn-main:disabled:hover {
    cursor: default
}

.i-popup-other-offers .popup__btn-main.disabled:active:not(.progress), .i-popup-other-offers .popup__btn-main.disabled:hover:not(.progress), .i-popup-other-offers .popup__btn-main.disabled:not(.progress), .i-popup-other-offers .popup__btn-main:disabled:active:not(.progress), .i-popup-other-offers .popup__btn-main:disabled:hover:not(.progress), .i-popup-other-offers .popup__btn-main:disabled:not(.progress) {
    color: #d7d7d7;
    background-color: #fff;
    border: 2px solid #d5d5d5;
    opacity: .9
}

.i-popup-other-offers .popup__btn-main.disabled:active:not(.progress):active, .i-popup-other-offers .popup__btn-main.disabled:hover:not(.progress):active, .i-popup-other-offers .popup__btn-main.disabled:not(.progress):active, .i-popup-other-offers .popup__btn-main:disabled:active:not(.progress):active, .i-popup-other-offers .popup__btn-main:disabled:hover:not(.progress):active, .i-popup-other-offers .popup__btn-main:disabled:not(.progress):active {
    -webkit-transform: none;
    transform: none
}

.i-popup-other-offers .popup__btn-main.progress, .i-popup-other-offers .popup__btn-main.progress:active {
    color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

@-webkit-keyframes mySpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes mySpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.i-popup-other-offers .popup__btn-main.progress:active:after, .i-popup-other-offers .popup__btn-main.progress:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 20px / 2);
    right: calc(50% - 20px / 2);
    -webkit-animation: mySpin .9s linear 0s infinite;
    animation: mySpin .9s linear 0s infinite
}

.i-popup-other-offers .popup__btn-main.progress:active:after, .i-popup-other-offers .popup__btn-main.progress:after {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%208A7.999%207.999%200%201%201%200%208h2.002a5.998%205.998%200%201%200%2011.996%200H16z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
    .i-popup-other-offers .popup__btn-main:hover {
        background-color: #e313bf;
        border-color: #e313bf
    }

    .i-popup-other-offers .popup__btn-main:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (hover: hover) and (pointer: fine) {
    .i-popup-other-offers .popup__btn-main:hover {
        background-color: #e313bf;
        border-color: #e313bf
    }

    .i-popup-other-offers .popup__btn-main:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (max-width: 575.98px) {
    .i-popup-other-offers .popup__btn-main {
        margin-right: 15px
    }
}

.i-popup-other-offers .popup__btn-base {
    font-family: system, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Segoe WP', Roboto, Ubuntu, Oxygen, Cantarell, 'Fira Sans', 'Helvetica Neue', Helvetica, 'Lucida Grande', 'Droid Sans', Tahoma, 'Microsoft Sans Serif', sans-serif;
    line-height: 1.1;
    letter-spacing: 0;
    font-weight: 700;
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    color: #cb11ab;
    font-size: 16px;
    padding: 11px 25px 12px;
    margin-top: 12px
}

.i-popup-other-offers .popup__btn-base.disabled, .i-popup-other-offers .popup__btn-base.disabled:active, .i-popup-other-offers .popup__btn-base.disabled:hover, .i-popup-other-offers .popup__btn-base:disabled, .i-popup-other-offers .popup__btn-base:disabled:active, .i-popup-other-offers .popup__btn-base:disabled:hover {
    cursor: default
}

.i-popup-other-offers .popup__btn-base.disabled:active:not(.progress), .i-popup-other-offers .popup__btn-base.disabled:hover:not(.progress), .i-popup-other-offers .popup__btn-base.disabled:not(.progress), .i-popup-other-offers .popup__btn-base:disabled:active:not(.progress), .i-popup-other-offers .popup__btn-base:disabled:hover:not(.progress), .i-popup-other-offers .popup__btn-base:disabled:not(.progress) {
    color: #d7d7d7;
    background-color: #fff;
    border: 2px solid #d5d5d5;
    opacity: .9
}

.i-popup-other-offers .popup__btn-base.disabled:active:not(.progress):active, .i-popup-other-offers .popup__btn-base.disabled:hover:not(.progress):active, .i-popup-other-offers .popup__btn-base.disabled:not(.progress):active, .i-popup-other-offers .popup__btn-base:disabled:active:not(.progress):active, .i-popup-other-offers .popup__btn-base:disabled:hover:not(.progress):active, .i-popup-other-offers .popup__btn-base:disabled:not(.progress):active {
    -webkit-transform: none;
    transform: none
}

.i-popup-other-offers .popup__btn-base.progress, .i-popup-other-offers .popup__btn-base.progress:active {
    color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

@keyframes mySpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.i-popup-other-offers .popup__btn-base.progress:active:after, .i-popup-other-offers .popup__btn-base.progress:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 20px / 2);
    right: calc(50% - 20px / 2);
    -webkit-animation: mySpin .9s linear 0s infinite;
    animation: mySpin .9s linear 0s infinite
}

.i-popup-other-offers .popup__btn-base.progress:active:after, .i-popup-other-offers .popup__btn-base.progress:after {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%208A7.999%207.999%200%201%201%200%208h2.002a5.998%205.998%200%201%200%2011.996%200H16z%22%20fill%3D%22%23CB11AB%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain
}

.i-popup-other-offers .popup__btn-base, .i-popup-other-offers .popup__btn-base.progress:active, .i-popup-other-offers .popup__btn-base.progress:hover {
    background-color: #fff;
    border: 2px solid #cb11ab
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
    .i-popup-other-offers .popup__btn-base:hover {
        background-color: #cb11ab;
        color: #fff
    }

    .i-popup-other-offers .popup__btn-base:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (hover: hover) and (pointer: fine) {
    .i-popup-other-offers .popup__btn-base:hover {
        background-color: #cb11ab;
        color: #fff
    }

    .i-popup-other-offers .popup__btn-base:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

.i-popup-other-offers .popup__header {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 48px
}

@media (min-width: 768px) {
    .i-popup-other-offers .popup__header {
        -webkit-line-clamp: 1;
        max-height: 28px
    }
}

@media (min-width: 1024px) {
    .i-popup-other-offers .popup__header {
        max-height: 36px
    }
}

.i-popup-other-offers .popup__content {
    height: 100%
}

.i-popup-error-report {
    font-size: 16px;
    line-height: 22px;
    color: #8b8b8b;
    display: none;
    width: 100%;
    max-width: 100%;
    min-width: 320px;
    padding: 12px 16px 16px;
    position: fixed;
    z-index: 1001;
    background: #fff;
    border-radius: 8px 8px 0 0;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2)
}

@media (min-width: 1024px) {
    .i-popup-error-report {
        width: calc(100% - 32px);
        max-width: 400px;
        padding: 28px 40px 40px;
        border-radius: 8px;
        position: absolute
    }
}

@media (max-width: 1023.98px) {
    .i-popup-error-report {
        top: auto !important;
        left: 0 !important;
        bottom: 0;
        max-height: 100%
    }
}

.i-popup-error-report .close, .i-popup-error-report .popup__close {
    width: 26px;
    height: 26px;
    display: inline-block;
    position: relative;
    font-size: 0;
    line-height: 1;
    border: none;
    background-color: transparent;
    position: absolute;
    z-index: 1;
    top: 13px;
    right: 12px;
    cursor: pointer
}

.i-popup-error-report .close:after, .i-popup-error-report .close:before, .i-popup-error-report .popup__close:after, .i-popup-error-report .popup__close:before {
    -webkit-transition: background .3s ease 0s;
    transition: background .3s ease 0s;
    background-color: #999;
    border-radius: 2px;
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg)
}

.i-popup-error-report .close:before, .i-popup-error-report .popup__close:before {
    width: 2px;
    height: 20px
}

.i-popup-error-report .close:after, .i-popup-error-report .popup__close:after {
    width: 20px;
    height: 2px
}

@media (min-width: 1024px) {
    .i-popup-error-report .close, .i-popup-error-report .popup__close {
        top: 8px;
        right: 8px
    }
}

@media (max-width: 575.98px) {
    .i-popup-error-report .close ~ .popup__content, .i-popup-error-report .popup__close ~ .popup__content {
        padding-top: 28px
    }
}

@media (max-width: 575.98px) {
    .i-popup-error-report .close ~ .popup__content .popup__header, .i-popup-error-report .popup__close ~ .popup__content .popup__header {
        margin-top: -28px
    }
}

.i-popup-error-report .popup__close-btn {
    font-size: 16px;
    line-height: 22px;
    position: absolute;
    z-index: 1;
    top: 16px;
    right: 16px;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    padding: 4px 12px 6px;
    background-color: #fff;
    border-radius: 24px;
    border: none;
    cursor: pointer
}

@media (max-width: 1023.98px) {
    .i-popup-error-report .popup__close-btn {
        width: 26px;
        height: 26px;
        display: inline-block;
        position: relative;
        font-size: 0;
        line-height: 1;
        border: none;
        background-color: transparent;
        position: absolute;
        z-index: 1;
        top: 13px;
        right: 12px;
        cursor: pointer;
        line-height: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        padding: 0;
        border-radius: 0
    }

    .i-popup-error-report .popup__close-btn:after, .i-popup-error-report .popup__close-btn:before {
        -webkit-transition: background .3s ease 0s;
        transition: background .3s ease 0s;
        background-color: #999;
        border-radius: 2px;
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
        transform: translate(-50%, -50%) rotate(45deg)
    }

    .i-popup-error-report .popup__close-btn:before {
        width: 2px;
        height: 20px
    }

    .i-popup-error-report .popup__close-btn:after {
        width: 20px;
        height: 2px
    }
}

@media (max-width: 1023.98px) and (min-width: 1024px) {
    .i-popup-error-report .popup__close-btn {
        top: 8px;
        right: 8px
    }
}

@media (max-width: 1023.98px) and (max-width: 575.98px) {
    .i-popup-error-report .popup__close-btn ~ .popup__content {
        padding-top: 28px
    }
}

@media (max-width: 1023.98px) and (max-width: 575.98px) {
    .i-popup-error-report .popup__close-btn ~ .popup__content .popup__header {
        margin-top: -28px
    }
}

.i-popup-error-report .popup__header {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: #000;
    margin: 0 0 16px;
    padding-right: 24px
}

@media (min-width: 768px) {
    .i-popup-error-report .popup__header {
        font-size: 20px;
        line-height: 28px
    }
}

@media (min-width: 1024px) {
    .i-popup-error-report .popup__header {
        font-size: 28px;
        line-height: 36px
    }
}

@media (min-width: 1024px) {
    .i-popup-error-report .popup__header {
        margin: 0 0 20px;
        padding-right: 0
    }
}

.i-popup-error-report .popup__btn {
    margin: 10px 0 0;
    font-size: 0
}

@media (min-width: 576px) {
    .i-popup-error-report .popup__btn {
        margin: 20px 0 0
    }
}

.i-popup-error-report .popup__btn-main {
    font-family: system, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Segoe WP', Roboto, Ubuntu, Oxygen, Cantarell, 'Fira Sans', 'Helvetica Neue', Helvetica, 'Lucida Grande', 'Droid Sans', Tahoma, 'Microsoft Sans Serif', sans-serif;
    line-height: 1.1;
    letter-spacing: 0;
    font-weight: 700;
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    background-color: #cb11ab;
    border: 2px solid #cb11ab;
    font-size: 16px;
    padding: 11px 25px 12px;
    margin-top: 12px;
    margin-right: 20px
}

.i-popup-error-report .popup__btn-main.disabled, .i-popup-error-report .popup__btn-main.disabled:active, .i-popup-error-report .popup__btn-main.disabled:hover, .i-popup-error-report .popup__btn-main:disabled, .i-popup-error-report .popup__btn-main:disabled:active, .i-popup-error-report .popup__btn-main:disabled:hover {
    cursor: default
}

.i-popup-error-report .popup__btn-main.disabled:active:not(.progress), .i-popup-error-report .popup__btn-main.disabled:hover:not(.progress), .i-popup-error-report .popup__btn-main.disabled:not(.progress), .i-popup-error-report .popup__btn-main:disabled:active:not(.progress), .i-popup-error-report .popup__btn-main:disabled:hover:not(.progress), .i-popup-error-report .popup__btn-main:disabled:not(.progress) {
    color: #d7d7d7;
    background-color: #fff;
    border: 2px solid #d5d5d5;
    opacity: .9
}

.i-popup-error-report .popup__btn-main.disabled:active:not(.progress):active, .i-popup-error-report .popup__btn-main.disabled:hover:not(.progress):active, .i-popup-error-report .popup__btn-main.disabled:not(.progress):active, .i-popup-error-report .popup__btn-main:disabled:active:not(.progress):active, .i-popup-error-report .popup__btn-main:disabled:hover:not(.progress):active, .i-popup-error-report .popup__btn-main:disabled:not(.progress):active {
    -webkit-transform: none;
    transform: none
}

.i-popup-error-report .popup__btn-main.progress, .i-popup-error-report .popup__btn-main.progress:active {
    color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

@keyframes mySpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.i-popup-error-report .popup__btn-main.progress:active:after, .i-popup-error-report .popup__btn-main.progress:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 20px / 2);
    right: calc(50% - 20px / 2);
    -webkit-animation: mySpin .9s linear 0s infinite;
    animation: mySpin .9s linear 0s infinite
}

.i-popup-error-report .popup__btn-main.progress:active:after, .i-popup-error-report .popup__btn-main.progress:after {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%208A7.999%207.999%200%201%201%200%208h2.002a5.998%205.998%200%201%200%2011.996%200H16z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
    .i-popup-error-report .popup__btn-main:hover {
        background-color: #e313bf;
        border-color: #e313bf
    }

    .i-popup-error-report .popup__btn-main:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (hover: hover) and (pointer: fine) {
    .i-popup-error-report .popup__btn-main:hover {
        background-color: #e313bf;
        border-color: #e313bf
    }

    .i-popup-error-report .popup__btn-main:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (max-width: 575.98px) {
    .i-popup-error-report .popup__btn-main {
        margin-right: 15px
    }
}

.i-popup-error-report .popup__btn-base {
    font-family: system, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Segoe WP', Roboto, Ubuntu, Oxygen, Cantarell, 'Fira Sans', 'Helvetica Neue', Helvetica, 'Lucida Grande', 'Droid Sans', Tahoma, 'Microsoft Sans Serif', sans-serif;
    line-height: 1.1;
    letter-spacing: 0;
    font-weight: 700;
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    color: #cb11ab;
    font-size: 16px;
    padding: 11px 25px 12px;
    margin-top: 12px
}

.i-popup-error-report .popup__btn-base.disabled, .i-popup-error-report .popup__btn-base.disabled:active, .i-popup-error-report .popup__btn-base.disabled:hover, .i-popup-error-report .popup__btn-base:disabled, .i-popup-error-report .popup__btn-base:disabled:active, .i-popup-error-report .popup__btn-base:disabled:hover {
    cursor: default
}

.i-popup-error-report .popup__btn-base.disabled:active:not(.progress), .i-popup-error-report .popup__btn-base.disabled:hover:not(.progress), .i-popup-error-report .popup__btn-base.disabled:not(.progress), .i-popup-error-report .popup__btn-base:disabled:active:not(.progress), .i-popup-error-report .popup__btn-base:disabled:hover:not(.progress), .i-popup-error-report .popup__btn-base:disabled:not(.progress) {
    color: #d7d7d7;
    background-color: #fff;
    border: 2px solid #d5d5d5;
    opacity: .9
}

.i-popup-error-report .popup__btn-base.disabled:active:not(.progress):active, .i-popup-error-report .popup__btn-base.disabled:hover:not(.progress):active, .i-popup-error-report .popup__btn-base.disabled:not(.progress):active, .i-popup-error-report .popup__btn-base:disabled:active:not(.progress):active, .i-popup-error-report .popup__btn-base:disabled:hover:not(.progress):active, .i-popup-error-report .popup__btn-base:disabled:not(.progress):active {
    -webkit-transform: none;
    transform: none
}

.i-popup-error-report .popup__btn-base.progress, .i-popup-error-report .popup__btn-base.progress:active {
    color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

@keyframes mySpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.i-popup-error-report .popup__btn-base.progress:active:after, .i-popup-error-report .popup__btn-base.progress:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 20px / 2);
    right: calc(50% - 20px / 2);
    -webkit-animation: mySpin .9s linear 0s infinite;
    animation: mySpin .9s linear 0s infinite
}

.i-popup-error-report .popup__btn-base.progress:active:after, .i-popup-error-report .popup__btn-base.progress:after {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%208A7.999%207.999%200%201%201%200%208h2.002a5.998%205.998%200%201%200%2011.996%200H16z%22%20fill%3D%22%23CB11AB%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain
}

.i-popup-error-report .popup__btn-base, .i-popup-error-report .popup__btn-base.progress:active, .i-popup-error-report .popup__btn-base.progress:hover {
    background-color: #fff;
    border: 2px solid #cb11ab
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
    .i-popup-error-report .popup__btn-base:hover {
        background-color: #cb11ab;
        color: #fff
    }

    .i-popup-error-report .popup__btn-base:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (hover: hover) and (pointer: fine) {
    .i-popup-error-report .popup__btn-base:hover {
        background-color: #cb11ab;
        color: #fff
    }

    .i-popup-error-report .popup__btn-base:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (max-width: 767.98px) {
    .i-popup-error-report:not(.popup__second-part) {
        height: 100%
    }
}

.i-popup-error-report p ~ p {
    margin-top: 16px
}

@media (max-width: 767.98px) {
    .i-popup-error-report .popup__content {
        padding-top: 0 !important;
        height: 100%
    }
}

.i-popup-error-report .popup__header {
    margin-top: 0 !important
}

@media (max-width: 767.98px) {
    .i-popup-error-report .popup__form {
        scrollbar-width: none;
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        overflow-y: auto
    }

    .i-popup-error-report .popup__form::-webkit-scrollbar {
        display: none
    }
}

.i-popup-error-report .popup__text {
    margin-bottom: 20px
}

@media (max-width: 1023.98px) {
    .i-popup-error-report .popup__text {
        font-size: 14px;
        line-height: 20px
    }
}

@media (min-width: 1024px) {
    .i-popup-error-report .popup__text {
        margin-bottom: 16px
    }
}

.i-popup-error-report .popup__form-item ~ .popup__form-item {
    margin-top: 20px
}

@media (min-width: 1024px) {
    .i-popup-error-report .popup__form-item ~ .popup__form-item {
        margin-top: 32px
    }
}

.i-popup-error-report .popup__label {
    font-size: 14px;
    line-height: 20px;
    display: inline-block;
    vertical-align: top;
    margin: 0 0 4px;
    cursor: pointer
}

@media (min-width: 768px) {
    .i-popup-error-report .popup__label {
        font-size: 16px;
        line-height: 22px;
        margin: 0 0 8px
    }
}

.i-popup-error-report .popup__btn-block {
    margin-top: 20px
}

@media (min-width: 768px) {
    .i-popup-error-report .popup__btn-block {
        margin-top: 32px
    }
}

@media (max-width: 767.98px) {
    .i-popup-error-report .textarea-block {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1
    }
}

@media (max-width: 767.98px) {
    .i-popup-error-report .textarea-block__textarea-wrap {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1
    }
}

@media (max-width: 767.98px) {
    .i-popup-error-report textarea {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1
    }
}

@media (min-width: 768px) {
    .i-popup-error-report textarea {
        height: 258px
    }
}

@media (min-width: 1024px) {
    .i-popup-error-report textarea {
        height: 90px
    }
}

.i-popup-error-report .field-validation-error {
    font-size: 14px;
    line-height: 20px;
    color: #cb11ab;
    display: block;
    margin-top: 8px
}

.i-popup-error-report .input-validation-error ~ .popup__select .drop-btn, .i-popup-error-report .input-validation-error ~ .textarea-block__textarea-wrap textarea {
    border-color: #cb11ab
}

@media (max-width: 767.98px) {
    .i-popup-error-report .drop-radio-base .drop-list {
        -webkit-box-shadow: initial;
        box-shadow: initial;
        border: 1px solid #d5d5d5
    }
}

@media (max-width: 1023.98px) {
    .i-popup-error-report .btn-main {
        width: 100%
    }
}

.i-popup-comment-rules {
    font-size: 16px;
    line-height: 22px;
    color: #8b8b8b;
    display: none;
    width: 100%;
    max-width: 100%;
    min-width: 320px;
    padding: 12px 16px 16px;
    position: fixed;
    z-index: 1001;
    background: #fff;
    border-radius: 8px 8px 0 0;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2)
}

@media (min-width: 1024px) {
    .i-popup-comment-rules {
        width: calc(100% - 32px);
        max-width: 700px;
        padding: 28px 40px 40px;
        border-radius: 8px;
        position: absolute
    }
}

@media (max-width: 1023.98px) {
    .i-popup-comment-rules {
        top: auto !important;
        left: 0 !important;
        bottom: 0;
        max-height: 100%
    }
}

.i-popup-comment-rules .close, .i-popup-comment-rules .popup__close {
    width: 26px;
    height: 26px;
    display: inline-block;
    position: relative;
    font-size: 0;
    line-height: 1;
    border: none;
    background-color: transparent;
    position: absolute;
    z-index: 1;
    top: 13px;
    right: 12px;
    cursor: pointer
}

.i-popup-comment-rules .close:after, .i-popup-comment-rules .close:before, .i-popup-comment-rules .popup__close:after, .i-popup-comment-rules .popup__close:before {
    -webkit-transition: background .3s ease 0s;
    transition: background .3s ease 0s;
    background-color: #999;
    border-radius: 2px;
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg)
}

.i-popup-comment-rules .close:before, .i-popup-comment-rules .popup__close:before {
    width: 2px;
    height: 20px
}

.i-popup-comment-rules .close:after, .i-popup-comment-rules .popup__close:after {
    width: 20px;
    height: 2px
}

@media (min-width: 1024px) {
    .i-popup-comment-rules .close, .i-popup-comment-rules .popup__close {
        top: 8px;
        right: 8px
    }
}

@media (max-width: 575.98px) {
    .i-popup-comment-rules .close ~ .popup__content, .i-popup-comment-rules .popup__close ~ .popup__content {
        padding-top: 28px
    }
}

@media (max-width: 575.98px) {
    .i-popup-comment-rules .close ~ .popup__content .popup__header, .i-popup-comment-rules .popup__close ~ .popup__content .popup__header {
        margin-top: -28px
    }
}

.i-popup-comment-rules .popup__close-btn {
    font-size: 16px;
    line-height: 22px;
    position: absolute;
    z-index: 1;
    top: 16px;
    right: 16px;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    padding: 4px 12px 6px;
    background-color: #fff;
    border-radius: 24px;
    border: none;
    cursor: pointer
}

@media (max-width: 1023.98px) {
    .i-popup-comment-rules .popup__close-btn {
        width: 26px;
        height: 26px;
        display: inline-block;
        position: relative;
        font-size: 0;
        line-height: 1;
        border: none;
        background-color: transparent;
        position: absolute;
        z-index: 1;
        top: 13px;
        right: 12px;
        cursor: pointer;
        line-height: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        padding: 0;
        border-radius: 0
    }

    .i-popup-comment-rules .popup__close-btn:after, .i-popup-comment-rules .popup__close-btn:before {
        -webkit-transition: background .3s ease 0s;
        transition: background .3s ease 0s;
        background-color: #999;
        border-radius: 2px;
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
        transform: translate(-50%, -50%) rotate(45deg)
    }

    .i-popup-comment-rules .popup__close-btn:before {
        width: 2px;
        height: 20px
    }

    .i-popup-comment-rules .popup__close-btn:after {
        width: 20px;
        height: 2px
    }
}

@media (max-width: 1023.98px) and (min-width: 1024px) {
    .i-popup-comment-rules .popup__close-btn {
        top: 8px;
        right: 8px
    }
}

@media (max-width: 1023.98px) and (max-width: 575.98px) {
    .i-popup-comment-rules .popup__close-btn ~ .popup__content {
        padding-top: 28px
    }
}

@media (max-width: 1023.98px) and (max-width: 575.98px) {
    .i-popup-comment-rules .popup__close-btn ~ .popup__content .popup__header {
        margin-top: -28px
    }
}

.i-popup-comment-rules .popup__header {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: #000;
    margin: 0 0 16px;
    padding-right: 24px
}

@media (min-width: 768px) {
    .i-popup-comment-rules .popup__header {
        font-size: 20px;
        line-height: 28px
    }
}

@media (min-width: 1024px) {
    .i-popup-comment-rules .popup__header {
        font-size: 28px;
        line-height: 36px
    }
}

@media (min-width: 1024px) {
    .i-popup-comment-rules .popup__header {
        margin: 0 0 20px;
        padding-right: 0
    }
}

.i-popup-comment-rules .popup__btn {
    margin: 10px 0 0;
    font-size: 0
}

@media (min-width: 576px) {
    .i-popup-comment-rules .popup__btn {
        margin: 20px 0 0
    }
}

.i-popup-comment-rules .popup__btn-main {
    font-family: system, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Segoe WP', Roboto, Ubuntu, Oxygen, Cantarell, 'Fira Sans', 'Helvetica Neue', Helvetica, 'Lucida Grande', 'Droid Sans', Tahoma, 'Microsoft Sans Serif', sans-serif;
    line-height: 1.1;
    letter-spacing: 0;
    font-weight: 700;
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    background-color: #cb11ab;
    border: 2px solid #cb11ab;
    font-size: 16px;
    padding: 11px 25px 12px;
    margin-top: 12px;
    margin-right: 20px
}

.i-popup-comment-rules .popup__btn-main.disabled, .i-popup-comment-rules .popup__btn-main.disabled:active, .i-popup-comment-rules .popup__btn-main.disabled:hover, .i-popup-comment-rules .popup__btn-main:disabled, .i-popup-comment-rules .popup__btn-main:disabled:active, .i-popup-comment-rules .popup__btn-main:disabled:hover {
    cursor: default
}

.i-popup-comment-rules .popup__btn-main.disabled:active:not(.progress), .i-popup-comment-rules .popup__btn-main.disabled:hover:not(.progress), .i-popup-comment-rules .popup__btn-main.disabled:not(.progress), .i-popup-comment-rules .popup__btn-main:disabled:active:not(.progress), .i-popup-comment-rules .popup__btn-main:disabled:hover:not(.progress), .i-popup-comment-rules .popup__btn-main:disabled:not(.progress) {
    color: #d7d7d7;
    background-color: #fff;
    border: 2px solid #d5d5d5;
    opacity: .9
}

.i-popup-comment-rules .popup__btn-main.disabled:active:not(.progress):active, .i-popup-comment-rules .popup__btn-main.disabled:hover:not(.progress):active, .i-popup-comment-rules .popup__btn-main.disabled:not(.progress):active, .i-popup-comment-rules .popup__btn-main:disabled:active:not(.progress):active, .i-popup-comment-rules .popup__btn-main:disabled:hover:not(.progress):active, .i-popup-comment-rules .popup__btn-main:disabled:not(.progress):active {
    -webkit-transform: none;
    transform: none
}

.i-popup-comment-rules .popup__btn-main.progress, .i-popup-comment-rules .popup__btn-main.progress:active {
    color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

@keyframes mySpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.i-popup-comment-rules .popup__btn-main.progress:active:after, .i-popup-comment-rules .popup__btn-main.progress:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 20px / 2);
    right: calc(50% - 20px / 2);
    -webkit-animation: mySpin .9s linear 0s infinite;
    animation: mySpin .9s linear 0s infinite
}

.i-popup-comment-rules .popup__btn-main.progress:active:after, .i-popup-comment-rules .popup__btn-main.progress:after {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%208A7.999%207.999%200%201%201%200%208h2.002a5.998%205.998%200%201%200%2011.996%200H16z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
    .i-popup-comment-rules .popup__btn-main:hover {
        background-color: #e313bf;
        border-color: #e313bf
    }

    .i-popup-comment-rules .popup__btn-main:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (hover: hover) and (pointer: fine) {
    .i-popup-comment-rules .popup__btn-main:hover {
        background-color: #e313bf;
        border-color: #e313bf
    }

    .i-popup-comment-rules .popup__btn-main:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (max-width: 575.98px) {
    .i-popup-comment-rules .popup__btn-main {
        margin-right: 15px
    }
}

.i-popup-comment-rules .popup__btn-base {
    font-family: system, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Segoe WP', Roboto, Ubuntu, Oxygen, Cantarell, 'Fira Sans', 'Helvetica Neue', Helvetica, 'Lucida Grande', 'Droid Sans', Tahoma, 'Microsoft Sans Serif', sans-serif;
    line-height: 1.1;
    letter-spacing: 0;
    font-weight: 700;
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    color: #cb11ab;
    font-size: 16px;
    padding: 11px 25px 12px;
    margin-top: 12px
}

.i-popup-comment-rules .popup__btn-base.disabled, .i-popup-comment-rules .popup__btn-base.disabled:active, .i-popup-comment-rules .popup__btn-base.disabled:hover, .i-popup-comment-rules .popup__btn-base:disabled, .i-popup-comment-rules .popup__btn-base:disabled:active, .i-popup-comment-rules .popup__btn-base:disabled:hover {
    cursor: default
}

.i-popup-comment-rules .popup__btn-base.disabled:active:not(.progress), .i-popup-comment-rules .popup__btn-base.disabled:hover:not(.progress), .i-popup-comment-rules .popup__btn-base.disabled:not(.progress), .i-popup-comment-rules .popup__btn-base:disabled:active:not(.progress), .i-popup-comment-rules .popup__btn-base:disabled:hover:not(.progress), .i-popup-comment-rules .popup__btn-base:disabled:not(.progress) {
    color: #d7d7d7;
    background-color: #fff;
    border: 2px solid #d5d5d5;
    opacity: .9
}

.i-popup-comment-rules .popup__btn-base.disabled:active:not(.progress):active, .i-popup-comment-rules .popup__btn-base.disabled:hover:not(.progress):active, .i-popup-comment-rules .popup__btn-base.disabled:not(.progress):active, .i-popup-comment-rules .popup__btn-base:disabled:active:not(.progress):active, .i-popup-comment-rules .popup__btn-base:disabled:hover:not(.progress):active, .i-popup-comment-rules .popup__btn-base:disabled:not(.progress):active {
    -webkit-transform: none;
    transform: none
}

.i-popup-comment-rules .popup__btn-base.progress, .i-popup-comment-rules .popup__btn-base.progress:active {
    color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

@keyframes mySpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.i-popup-comment-rules .popup__btn-base.progress:active:after, .i-popup-comment-rules .popup__btn-base.progress:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 20px / 2);
    right: calc(50% - 20px / 2);
    -webkit-animation: mySpin .9s linear 0s infinite;
    animation: mySpin .9s linear 0s infinite
}

.i-popup-comment-rules .popup__btn-base.progress:active:after, .i-popup-comment-rules .popup__btn-base.progress:after {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%208A7.999%207.999%200%201%201%200%208h2.002a5.998%205.998%200%201%200%2011.996%200H16z%22%20fill%3D%22%23CB11AB%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain
}

.i-popup-comment-rules .popup__btn-base, .i-popup-comment-rules .popup__btn-base.progress:active, .i-popup-comment-rules .popup__btn-base.progress:hover {
    background-color: #fff;
    border: 2px solid #cb11ab
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
    .i-popup-comment-rules .popup__btn-base:hover {
        background-color: #cb11ab;
        color: #fff
    }

    .i-popup-comment-rules .popup__btn-base:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (hover: hover) and (pointer: fine) {
    .i-popup-comment-rules .popup__btn-base:hover {
        background-color: #cb11ab;
        color: #fff
    }

    .i-popup-comment-rules .popup__btn-base:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (max-width: 767.98px) {
    .i-popup-comment-rules {
        font-size: 14px;
        line-height: 20px;
        height: 100%
    }
}

.i-popup-comment-rules p ~ p {
    margin-top: 12px
}

@media (min-width: 768px) {
    .i-popup-comment-rules p ~ p {
        margin-top: 20px
    }
}

@media (min-width: 1024px) {
    .i-popup-comment-rules p ~ p {
        margin-top: 16px
    }
}

@media (max-width: 767.98px) {
    .i-popup-comment-rules .popup__header {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        max-height: 48px
    }
}

@media (max-width: 767.98px) {
    .i-popup-comment-rules .popup__content {
        height: 100%
    }
}

@media (max-width: 767.98px) {
    .i-popup-comment-rules .popup__scroll-container {
        overflow-y: scroll;
        scrollbar-color: #d5d5d5 transparent;
        scrollbar-width: thin;
        margin-right: -6px;
        padding-right: 6px;
        max-height: calc(100% - 64px + 28px);
        overflow-y: auto
    }

    .i-popup-comment-rules .popup__scroll-container::-webkit-scrollbar {
        width: 4px
    }

    .i-popup-comment-rules .popup__scroll-container::-webkit-scrollbar-track {
        background-color: transparent
    }

    .i-popup-comment-rules .popup__scroll-container::-webkit-scrollbar-thumb {
        height: 30px;
        background-color: #d5d5d5;
        border-radius: 4px
    }

    .i-popup-comment-rules .popup__scroll-container::-webkit-scrollbar-thumb:hover {
        background: #646464
    }
}

.i-popup-comment-rules .popup__list-wrap {
    color: #000;
    margin: 16px 0
}

@media (min-width: 768px) {
    .i-popup-comment-rules .popup__list-wrap {
        margin: 36px 0 20px
    }
}

@media (min-width: 1024px) {
    .i-popup-comment-rules .popup__list-wrap {
        margin: 28px 0
    }
}

.i-popup-comment-rules .popup__list-wrap p {
    margin-bottom: 12px
}

@media (min-width: 768px) {
    .i-popup-comment-rules .popup__list-wrap p {
        margin-bottom: 20px
    }
}

@media (min-width: 1024px) {
    .i-popup-comment-rules .popup__list-wrap p {
        margin-bottom: 16px
    }
}

.i-popup-comment-rules li {
    position: relative;
    padding-left: 26px
}

.i-popup-comment-rules li::before {
    content: "—";
    position: absolute;
    left: 0
}

.i-popup-comment-rules li ~ li {
    margin-top: 12px
}

@media (min-width: 1024px) {
    .i-popup-comment-rules li ~ li {
        margin-top: 8px
    }
}

.i-popup-complain-feedback {
    font-size: 16px;
    line-height: 22px;
    color: #8b8b8b;
    display: none;
    width: 100%;
    max-width: 100%;
    min-width: 320px;
    padding: 12px 16px 16px;
    position: fixed;
    z-index: 1001;
    background: #fff;
    border-radius: 8px 8px 0 0;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2)
}

@media (min-width: 1024px) {
    .i-popup-complain-feedback {
        width: calc(100% - 32px);
        max-width: 400px;
        padding: 28px 40px 40px;
        border-radius: 8px;
        position: absolute
    }
}

@media (max-width: 1023.98px) {
    .i-popup-complain-feedback {
        top: auto !important;
        left: 0 !important;
        bottom: 0;
        max-height: 100%
    }
}

.i-popup-complain-feedback .close, .i-popup-complain-feedback .popup__close {
    width: 26px;
    height: 26px;
    display: inline-block;
    position: relative;
    font-size: 0;
    line-height: 1;
    border: none;
    background-color: transparent;
    position: absolute;
    z-index: 1;
    top: 13px;
    right: 12px;
    cursor: pointer
}

.i-popup-complain-feedback .close:after, .i-popup-complain-feedback .close:before, .i-popup-complain-feedback .popup__close:after, .i-popup-complain-feedback .popup__close:before {
    -webkit-transition: background .3s ease 0s;
    transition: background .3s ease 0s;
    background-color: #999;
    border-radius: 2px;
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg)
}

.i-popup-complain-feedback .close:before, .i-popup-complain-feedback .popup__close:before {
    width: 2px;
    height: 20px
}

.i-popup-complain-feedback .close:after, .i-popup-complain-feedback .popup__close:after {
    width: 20px;
    height: 2px
}

@media (min-width: 1024px) {
    .i-popup-complain-feedback .close, .i-popup-complain-feedback .popup__close {
        top: 8px;
        right: 8px
    }
}

@media (max-width: 575.98px) {
    .i-popup-complain-feedback .close ~ .popup__content, .i-popup-complain-feedback .popup__close ~ .popup__content {
        padding-top: 28px
    }
}

@media (max-width: 575.98px) {
    .i-popup-complain-feedback .close ~ .popup__content .popup__header, .i-popup-complain-feedback .popup__close ~ .popup__content .popup__header {
        margin-top: -28px
    }
}

.i-popup-complain-feedback .popup__close-btn {
    font-size: 16px;
    line-height: 22px;
    position: absolute;
    z-index: 1;
    top: 16px;
    right: 16px;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    padding: 4px 12px 6px;
    background-color: #fff;
    border-radius: 24px;
    border: none;
    cursor: pointer
}

@media (max-width: 1023.98px) {
    .i-popup-complain-feedback .popup__close-btn {
        width: 26px;
        height: 26px;
        display: inline-block;
        position: relative;
        font-size: 0;
        line-height: 1;
        border: none;
        background-color: transparent;
        position: absolute;
        z-index: 1;
        top: 13px;
        right: 12px;
        cursor: pointer;
        line-height: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        padding: 0;
        border-radius: 0
    }

    .i-popup-complain-feedback .popup__close-btn:after, .i-popup-complain-feedback .popup__close-btn:before {
        -webkit-transition: background .3s ease 0s;
        transition: background .3s ease 0s;
        background-color: #999;
        border-radius: 2px;
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
        transform: translate(-50%, -50%) rotate(45deg)
    }

    .i-popup-complain-feedback .popup__close-btn:before {
        width: 2px;
        height: 20px
    }

    .i-popup-complain-feedback .popup__close-btn:after {
        width: 20px;
        height: 2px
    }
}

@media (max-width: 1023.98px) and (min-width: 1024px) {
    .i-popup-complain-feedback .popup__close-btn {
        top: 8px;
        right: 8px
    }
}

@media (max-width: 1023.98px) and (max-width: 575.98px) {
    .i-popup-complain-feedback .popup__close-btn ~ .popup__content {
        padding-top: 28px
    }
}

@media (max-width: 1023.98px) and (max-width: 575.98px) {
    .i-popup-complain-feedback .popup__close-btn ~ .popup__content .popup__header {
        margin-top: -28px
    }
}

.i-popup-complain-feedback .popup__header {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: #000;
    margin: 0 0 16px;
    padding-right: 24px
}

@media (min-width: 768px) {
    .i-popup-complain-feedback .popup__header {
        font-size: 20px;
        line-height: 28px
    }
}

@media (min-width: 1024px) {
    .i-popup-complain-feedback .popup__header {
        font-size: 28px;
        line-height: 36px
    }
}

@media (min-width: 1024px) {
    .i-popup-complain-feedback .popup__header {
        margin: 0 0 20px;
        padding-right: 0
    }
}

.i-popup-complain-feedback .popup__btn {
    margin: 10px 0 0;
    font-size: 0
}

@media (min-width: 576px) {
    .i-popup-complain-feedback .popup__btn {
        margin: 20px 0 0
    }
}

.i-popup-complain-feedback .popup__btn-main {
    font-family: system, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Segoe WP', Roboto, Ubuntu, Oxygen, Cantarell, 'Fira Sans', 'Helvetica Neue', Helvetica, 'Lucida Grande', 'Droid Sans', Tahoma, 'Microsoft Sans Serif', sans-serif;
    line-height: 1.1;
    letter-spacing: 0;
    font-weight: 700;
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    background-color: #cb11ab;
    border: 2px solid #cb11ab;
    font-size: 16px;
    padding: 11px 25px 12px;
    margin-top: 12px;
    margin-right: 20px
}

.i-popup-complain-feedback .popup__btn-main.disabled, .i-popup-complain-feedback .popup__btn-main.disabled:active, .i-popup-complain-feedback .popup__btn-main.disabled:hover, .i-popup-complain-feedback .popup__btn-main:disabled, .i-popup-complain-feedback .popup__btn-main:disabled:active, .i-popup-complain-feedback .popup__btn-main:disabled:hover {
    cursor: default
}

.i-popup-complain-feedback .popup__btn-main.disabled:active:not(.progress), .i-popup-complain-feedback .popup__btn-main.disabled:hover:not(.progress), .i-popup-complain-feedback .popup__btn-main.disabled:not(.progress), .i-popup-complain-feedback .popup__btn-main:disabled:active:not(.progress), .i-popup-complain-feedback .popup__btn-main:disabled:hover:not(.progress), .i-popup-complain-feedback .popup__btn-main:disabled:not(.progress) {
    color: #d7d7d7;
    background-color: #fff;
    border: 2px solid #d5d5d5;
    opacity: .9
}

.i-popup-complain-feedback .popup__btn-main.disabled:active:not(.progress):active, .i-popup-complain-feedback .popup__btn-main.disabled:hover:not(.progress):active, .i-popup-complain-feedback .popup__btn-main.disabled:not(.progress):active, .i-popup-complain-feedback .popup__btn-main:disabled:active:not(.progress):active, .i-popup-complain-feedback .popup__btn-main:disabled:hover:not(.progress):active, .i-popup-complain-feedback .popup__btn-main:disabled:not(.progress):active {
    -webkit-transform: none;
    transform: none
}

.i-popup-complain-feedback .popup__btn-main.progress, .i-popup-complain-feedback .popup__btn-main.progress:active {
    color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

@keyframes mySpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.i-popup-complain-feedback .popup__btn-main.progress:active:after, .i-popup-complain-feedback .popup__btn-main.progress:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 20px / 2);
    right: calc(50% - 20px / 2);
    -webkit-animation: mySpin .9s linear 0s infinite;
    animation: mySpin .9s linear 0s infinite
}

.i-popup-complain-feedback .popup__btn-main.progress:active:after, .i-popup-complain-feedback .popup__btn-main.progress:after {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%208A7.999%207.999%200%201%201%200%208h2.002a5.998%205.998%200%201%200%2011.996%200H16z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
    .i-popup-complain-feedback .popup__btn-main:hover {
        background-color: #e313bf;
        border-color: #e313bf
    }

    .i-popup-complain-feedback .popup__btn-main:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (hover: hover) and (pointer: fine) {
    .i-popup-complain-feedback .popup__btn-main:hover {
        background-color: #e313bf;
        border-color: #e313bf
    }

    .i-popup-complain-feedback .popup__btn-main:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (max-width: 575.98px) {
    .i-popup-complain-feedback .popup__btn-main {
        margin-right: 15px
    }
}

.i-popup-complain-feedback .popup__btn-base {
    font-family: system, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Segoe WP', Roboto, Ubuntu, Oxygen, Cantarell, 'Fira Sans', 'Helvetica Neue', Helvetica, 'Lucida Grande', 'Droid Sans', Tahoma, 'Microsoft Sans Serif', sans-serif;
    line-height: 1.1;
    letter-spacing: 0;
    font-weight: 700;
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    color: #cb11ab;
    font-size: 16px;
    padding: 11px 25px 12px;
    margin-top: 12px
}

.i-popup-complain-feedback .popup__btn-base.disabled, .i-popup-complain-feedback .popup__btn-base.disabled:active, .i-popup-complain-feedback .popup__btn-base.disabled:hover, .i-popup-complain-feedback .popup__btn-base:disabled, .i-popup-complain-feedback .popup__btn-base:disabled:active, .i-popup-complain-feedback .popup__btn-base:disabled:hover {
    cursor: default
}

.i-popup-complain-feedback .popup__btn-base.disabled:active:not(.progress), .i-popup-complain-feedback .popup__btn-base.disabled:hover:not(.progress), .i-popup-complain-feedback .popup__btn-base.disabled:not(.progress), .i-popup-complain-feedback .popup__btn-base:disabled:active:not(.progress), .i-popup-complain-feedback .popup__btn-base:disabled:hover:not(.progress), .i-popup-complain-feedback .popup__btn-base:disabled:not(.progress) {
    color: #d7d7d7;
    background-color: #fff;
    border: 2px solid #d5d5d5;
    opacity: .9
}

.i-popup-complain-feedback .popup__btn-base.disabled:active:not(.progress):active, .i-popup-complain-feedback .popup__btn-base.disabled:hover:not(.progress):active, .i-popup-complain-feedback .popup__btn-base.disabled:not(.progress):active, .i-popup-complain-feedback .popup__btn-base:disabled:active:not(.progress):active, .i-popup-complain-feedback .popup__btn-base:disabled:hover:not(.progress):active, .i-popup-complain-feedback .popup__btn-base:disabled:not(.progress):active {
    -webkit-transform: none;
    transform: none
}

.i-popup-complain-feedback .popup__btn-base.progress, .i-popup-complain-feedback .popup__btn-base.progress:active {
    color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

@keyframes mySpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.i-popup-complain-feedback .popup__btn-base.progress:active:after, .i-popup-complain-feedback .popup__btn-base.progress:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 20px / 2);
    right: calc(50% - 20px / 2);
    -webkit-animation: mySpin .9s linear 0s infinite;
    animation: mySpin .9s linear 0s infinite
}

.i-popup-complain-feedback .popup__btn-base.progress:active:after, .i-popup-complain-feedback .popup__btn-base.progress:after {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%208A7.999%207.999%200%201%201%200%208h2.002a5.998%205.998%200%201%200%2011.996%200H16z%22%20fill%3D%22%23CB11AB%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain
}

.i-popup-complain-feedback .popup__btn-base, .i-popup-complain-feedback .popup__btn-base.progress:active, .i-popup-complain-feedback .popup__btn-base.progress:hover {
    background-color: #fff;
    border: 2px solid #cb11ab
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
    .i-popup-complain-feedback .popup__btn-base:hover {
        background-color: #cb11ab;
        color: #fff
    }

    .i-popup-complain-feedback .popup__btn-base:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (hover: hover) and (pointer: fine) {
    .i-popup-complain-feedback .popup__btn-base:hover {
        background-color: #cb11ab;
        color: #fff
    }

    .i-popup-complain-feedback .popup__btn-base:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (min-width: 1024px) {
    .i-popup-complain-feedback.centered {
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%)
    }
}

.i-popup-complain-feedback .popup-complain-feedback__link {
    font-size: 16px;
    line-height: 22px;
    color: #000;
    border-color: initial;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
    color: #cb11ab;
    border-color: transparent
}

.i-popup-complain-feedback .popup-complain-feedback__link:hover {
    color: #cb11ab;
    border-color: initial
}

.i-popup-complain-feedback .popup-complain-feedback__link:hover {
    color: #000;
    border-color: transparent
}

.i-popup-complain-feedback .popup__btn-block {
    margin-top: 20px
}

@media (min-width: 1024px) {
    .i-popup-complain-feedback .popup__btn-block {
        margin-top: 32px
    }
}

.i-popup-complain-feedback .radio-with-text {
    display: block
}

.i-popup-complain-feedback .radio-with-text ~ .radio-with-text {
    margin-top: 16px
}

.i-popup-complain-feedback .field-validation-error {
    font-size: 14px;
    line-height: 20px;
    color: #cb11ab;
    display: block;
    margin-top: 8px
}

.i-popup-complain-feedback .btn-main {
    font-family: system, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Segoe WP', Roboto, Ubuntu, Oxygen, Cantarell, 'Fira Sans', 'Helvetica Neue', Helvetica, 'Lucida Grande', 'Droid Sans', Tahoma, 'Microsoft Sans Serif', sans-serif;
    line-height: 1.1;
    letter-spacing: 0;
    font-weight: 700;
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    background-color: #cb11ab;
    border: 2px solid #cb11ab;
    font-size: 16px;
    padding: 11px 25px 12px
}

.i-popup-complain-feedback .btn-main.disabled, .i-popup-complain-feedback .btn-main.disabled:active, .i-popup-complain-feedback .btn-main.disabled:hover, .i-popup-complain-feedback .btn-main:disabled, .i-popup-complain-feedback .btn-main:disabled:active, .i-popup-complain-feedback .btn-main:disabled:hover {
    cursor: default
}

.i-popup-complain-feedback .btn-main.disabled:active:not(.progress), .i-popup-complain-feedback .btn-main.disabled:hover:not(.progress), .i-popup-complain-feedback .btn-main.disabled:not(.progress), .i-popup-complain-feedback .btn-main:disabled:active:not(.progress), .i-popup-complain-feedback .btn-main:disabled:hover:not(.progress), .i-popup-complain-feedback .btn-main:disabled:not(.progress) {
    color: #d7d7d7;
    background-color: #fff;
    border: 2px solid #d5d5d5;
    opacity: .9
}

.i-popup-complain-feedback .btn-main.disabled:active:not(.progress):active, .i-popup-complain-feedback .btn-main.disabled:hover:not(.progress):active, .i-popup-complain-feedback .btn-main.disabled:not(.progress):active, .i-popup-complain-feedback .btn-main:disabled:active:not(.progress):active, .i-popup-complain-feedback .btn-main:disabled:hover:not(.progress):active, .i-popup-complain-feedback .btn-main:disabled:not(.progress):active {
    -webkit-transform: none;
    transform: none
}

.i-popup-complain-feedback .btn-main.progress, .i-popup-complain-feedback .btn-main.progress:active {
    color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

@keyframes mySpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.i-popup-complain-feedback .btn-main.progress:active:after, .i-popup-complain-feedback .btn-main.progress:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 20px / 2);
    right: calc(50% - 20px / 2);
    -webkit-animation: mySpin .9s linear 0s infinite;
    animation: mySpin .9s linear 0s infinite
}

.i-popup-complain-feedback .btn-main.progress:active:after, .i-popup-complain-feedback .btn-main.progress:after {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%208A7.999%207.999%200%201%201%200%208h2.002a5.998%205.998%200%201%200%2011.996%200H16z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
    .i-popup-complain-feedback .btn-main:hover {
        background-color: #e313bf;
        border-color: #e313bf
    }

    .i-popup-complain-feedback .btn-main:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (hover: hover) and (pointer: fine) {
    .i-popup-complain-feedback .btn-main:hover {
        background-color: #e313bf;
        border-color: #e313bf
    }

    .i-popup-complain-feedback .btn-main:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (max-width: 1023.98px) {
    .i-popup-complain-feedback .btn-main {
        width: 100%
    }
}

.i-popup-complain-answer {
    font-size: 16px;
    line-height: 22px;
    color: #8b8b8b;
    display: none;
    width: 100%;
    max-width: 100%;
    min-width: 320px;
    padding: 12px 16px 16px;
    position: fixed;
    z-index: 1001;
    background: #fff;
    border-radius: 8px 8px 0 0;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2)
}

@media (min-width: 1024px) {
    .i-popup-complain-answer {
        width: calc(100% - 32px);
        max-width: 400px;
        padding: 28px 40px 40px;
        border-radius: 8px;
        position: absolute
    }
}

@media (max-width: 1023.98px) {
    .i-popup-complain-answer {
        top: auto !important;
        left: 0 !important;
        bottom: 0;
        max-height: 100%
    }
}

.i-popup-complain-answer .close, .i-popup-complain-answer .popup__close {
    width: 26px;
    height: 26px;
    display: inline-block;
    position: relative;
    font-size: 0;
    line-height: 1;
    border: none;
    background-color: transparent;
    position: absolute;
    z-index: 1;
    top: 13px;
    right: 12px;
    cursor: pointer
}

.i-popup-complain-answer .close:after, .i-popup-complain-answer .close:before, .i-popup-complain-answer .popup__close:after, .i-popup-complain-answer .popup__close:before {
    -webkit-transition: background .3s ease 0s;
    transition: background .3s ease 0s;
    background-color: #999;
    border-radius: 2px;
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg)
}

.i-popup-complain-answer .close:before, .i-popup-complain-answer .popup__close:before {
    width: 2px;
    height: 20px
}

.i-popup-complain-answer .close:after, .i-popup-complain-answer .popup__close:after {
    width: 20px;
    height: 2px
}

@media (min-width: 1024px) {
    .i-popup-complain-answer .close, .i-popup-complain-answer .popup__close {
        top: 8px;
        right: 8px
    }
}

@media (max-width: 575.98px) {
    .i-popup-complain-answer .close ~ .popup__content, .i-popup-complain-answer .popup__close ~ .popup__content {
        padding-top: 28px
    }
}

@media (max-width: 575.98px) {
    .i-popup-complain-answer .close ~ .popup__content .popup__header, .i-popup-complain-answer .popup__close ~ .popup__content .popup__header {
        margin-top: -28px
    }
}

.i-popup-complain-answer .popup__close-btn {
    font-size: 16px;
    line-height: 22px;
    position: absolute;
    z-index: 1;
    top: 16px;
    right: 16px;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    padding: 4px 12px 6px;
    background-color: #fff;
    border-radius: 24px;
    border: none;
    cursor: pointer
}

@media (max-width: 1023.98px) {
    .i-popup-complain-answer .popup__close-btn {
        width: 26px;
        height: 26px;
        display: inline-block;
        position: relative;
        font-size: 0;
        line-height: 1;
        border: none;
        background-color: transparent;
        position: absolute;
        z-index: 1;
        top: 13px;
        right: 12px;
        cursor: pointer;
        line-height: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        padding: 0;
        border-radius: 0
    }

    .i-popup-complain-answer .popup__close-btn:after, .i-popup-complain-answer .popup__close-btn:before {
        -webkit-transition: background .3s ease 0s;
        transition: background .3s ease 0s;
        background-color: #999;
        border-radius: 2px;
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
        transform: translate(-50%, -50%) rotate(45deg)
    }

    .i-popup-complain-answer .popup__close-btn:before {
        width: 2px;
        height: 20px
    }

    .i-popup-complain-answer .popup__close-btn:after {
        width: 20px;
        height: 2px
    }
}

@media (max-width: 1023.98px) and (min-width: 1024px) {
    .i-popup-complain-answer .popup__close-btn {
        top: 8px;
        right: 8px
    }
}

@media (max-width: 1023.98px) and (max-width: 575.98px) {
    .i-popup-complain-answer .popup__close-btn ~ .popup__content {
        padding-top: 28px
    }
}

@media (max-width: 1023.98px) and (max-width: 575.98px) {
    .i-popup-complain-answer .popup__close-btn ~ .popup__content .popup__header {
        margin-top: -28px
    }
}

.i-popup-complain-answer .popup__header {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: #000;
    margin: 0 0 16px;
    padding-right: 24px
}

@media (min-width: 768px) {
    .i-popup-complain-answer .popup__header {
        font-size: 20px;
        line-height: 28px
    }
}

@media (min-width: 1024px) {
    .i-popup-complain-answer .popup__header {
        font-size: 28px;
        line-height: 36px
    }
}

@media (min-width: 1024px) {
    .i-popup-complain-answer .popup__header {
        margin: 0 0 20px;
        padding-right: 0
    }
}

.i-popup-complain-answer .popup__btn {
    margin: 10px 0 0;
    font-size: 0
}

@media (min-width: 576px) {
    .i-popup-complain-answer .popup__btn {
        margin: 20px 0 0
    }
}

.i-popup-complain-answer .popup__btn-main {
    font-family: system, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Segoe WP', Roboto, Ubuntu, Oxygen, Cantarell, 'Fira Sans', 'Helvetica Neue', Helvetica, 'Lucida Grande', 'Droid Sans', Tahoma, 'Microsoft Sans Serif', sans-serif;
    line-height: 1.1;
    letter-spacing: 0;
    font-weight: 700;
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    background-color: #cb11ab;
    border: 2px solid #cb11ab;
    font-size: 16px;
    padding: 11px 25px 12px;
    margin-top: 12px;
    margin-right: 20px
}

.i-popup-complain-answer .popup__btn-main.disabled, .i-popup-complain-answer .popup__btn-main.disabled:active, .i-popup-complain-answer .popup__btn-main.disabled:hover, .i-popup-complain-answer .popup__btn-main:disabled, .i-popup-complain-answer .popup__btn-main:disabled:active, .i-popup-complain-answer .popup__btn-main:disabled:hover {
    cursor: default
}

.i-popup-complain-answer .popup__btn-main.disabled:active:not(.progress), .i-popup-complain-answer .popup__btn-main.disabled:hover:not(.progress), .i-popup-complain-answer .popup__btn-main.disabled:not(.progress), .i-popup-complain-answer .popup__btn-main:disabled:active:not(.progress), .i-popup-complain-answer .popup__btn-main:disabled:hover:not(.progress), .i-popup-complain-answer .popup__btn-main:disabled:not(.progress) {
    color: #d7d7d7;
    background-color: #fff;
    border: 2px solid #d5d5d5;
    opacity: .9
}

.i-popup-complain-answer .popup__btn-main.disabled:active:not(.progress):active, .i-popup-complain-answer .popup__btn-main.disabled:hover:not(.progress):active, .i-popup-complain-answer .popup__btn-main.disabled:not(.progress):active, .i-popup-complain-answer .popup__btn-main:disabled:active:not(.progress):active, .i-popup-complain-answer .popup__btn-main:disabled:hover:not(.progress):active, .i-popup-complain-answer .popup__btn-main:disabled:not(.progress):active {
    -webkit-transform: none;
    transform: none
}

.i-popup-complain-answer .popup__btn-main.progress, .i-popup-complain-answer .popup__btn-main.progress:active {
    color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

@keyframes mySpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.i-popup-complain-answer .popup__btn-main.progress:active:after, .i-popup-complain-answer .popup__btn-main.progress:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 20px / 2);
    right: calc(50% - 20px / 2);
    -webkit-animation: mySpin .9s linear 0s infinite;
    animation: mySpin .9s linear 0s infinite
}

.i-popup-complain-answer .popup__btn-main.progress:active:after, .i-popup-complain-answer .popup__btn-main.progress:after {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%208A7.999%207.999%200%201%201%200%208h2.002a5.998%205.998%200%201%200%2011.996%200H16z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
    .i-popup-complain-answer .popup__btn-main:hover {
        background-color: #e313bf;
        border-color: #e313bf
    }

    .i-popup-complain-answer .popup__btn-main:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (hover: hover) and (pointer: fine) {
    .i-popup-complain-answer .popup__btn-main:hover {
        background-color: #e313bf;
        border-color: #e313bf
    }

    .i-popup-complain-answer .popup__btn-main:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (max-width: 575.98px) {
    .i-popup-complain-answer .popup__btn-main {
        margin-right: 15px
    }
}

.i-popup-complain-answer .popup__btn-base {
    font-family: system, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Segoe WP', Roboto, Ubuntu, Oxygen, Cantarell, 'Fira Sans', 'Helvetica Neue', Helvetica, 'Lucida Grande', 'Droid Sans', Tahoma, 'Microsoft Sans Serif', sans-serif;
    line-height: 1.1;
    letter-spacing: 0;
    font-weight: 700;
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    color: #cb11ab;
    font-size: 16px;
    padding: 11px 25px 12px;
    margin-top: 12px
}

.i-popup-complain-answer .popup__btn-base.disabled, .i-popup-complain-answer .popup__btn-base.disabled:active, .i-popup-complain-answer .popup__btn-base.disabled:hover, .i-popup-complain-answer .popup__btn-base:disabled, .i-popup-complain-answer .popup__btn-base:disabled:active, .i-popup-complain-answer .popup__btn-base:disabled:hover {
    cursor: default
}

.i-popup-complain-answer .popup__btn-base.disabled:active:not(.progress), .i-popup-complain-answer .popup__btn-base.disabled:hover:not(.progress), .i-popup-complain-answer .popup__btn-base.disabled:not(.progress), .i-popup-complain-answer .popup__btn-base:disabled:active:not(.progress), .i-popup-complain-answer .popup__btn-base:disabled:hover:not(.progress), .i-popup-complain-answer .popup__btn-base:disabled:not(.progress) {
    color: #d7d7d7;
    background-color: #fff;
    border: 2px solid #d5d5d5;
    opacity: .9
}

.i-popup-complain-answer .popup__btn-base.disabled:active:not(.progress):active, .i-popup-complain-answer .popup__btn-base.disabled:hover:not(.progress):active, .i-popup-complain-answer .popup__btn-base.disabled:not(.progress):active, .i-popup-complain-answer .popup__btn-base:disabled:active:not(.progress):active, .i-popup-complain-answer .popup__btn-base:disabled:hover:not(.progress):active, .i-popup-complain-answer .popup__btn-base:disabled:not(.progress):active {
    -webkit-transform: none;
    transform: none
}

.i-popup-complain-answer .popup__btn-base.progress, .i-popup-complain-answer .popup__btn-base.progress:active {
    color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

@keyframes mySpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.i-popup-complain-answer .popup__btn-base.progress:active:after, .i-popup-complain-answer .popup__btn-base.progress:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 20px / 2);
    right: calc(50% - 20px / 2);
    -webkit-animation: mySpin .9s linear 0s infinite;
    animation: mySpin .9s linear 0s infinite
}

.i-popup-complain-answer .popup__btn-base.progress:active:after, .i-popup-complain-answer .popup__btn-base.progress:after {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%208A7.999%207.999%200%201%201%200%208h2.002a5.998%205.998%200%201%200%2011.996%200H16z%22%20fill%3D%22%23CB11AB%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain
}

.i-popup-complain-answer .popup__btn-base, .i-popup-complain-answer .popup__btn-base.progress:active, .i-popup-complain-answer .popup__btn-base.progress:hover {
    background-color: #fff;
    border: 2px solid #cb11ab
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
    .i-popup-complain-answer .popup__btn-base:hover {
        background-color: #cb11ab;
        color: #fff
    }

    .i-popup-complain-answer .popup__btn-base:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (hover: hover) and (pointer: fine) {
    .i-popup-complain-answer .popup__btn-base:hover {
        background-color: #cb11ab;
        color: #fff
    }

    .i-popup-complain-answer .popup__btn-base:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

.i-popup-complain-answer .popup__btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

@media (max-width: 1023.98px) {
    .i-popup-complain-answer .popup__btn {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between
    }
}

@media (min-width: 1024px) {
    .i-popup-complain-answer .popup__btn {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-top: 32px
    }
}

@media (max-width: 1023.98px) {
    .i-popup-complain-answer .btn-base, .i-popup-complain-answer .btn-main {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: calc((100% - 8px) / 2);
        padding-left: 10px;
        padding-right: 10px
    }
}

@media (min-width: 1024px) {
    .i-popup-complain-answer .btn-base {
        margin-left: 20px
    }
}

.i-popup-publication-answer {
    font-size: 16px;
    line-height: 22px;
    color: #8b8b8b;
    display: none;
    width: 100%;
    max-width: 100%;
    min-width: 320px;
    padding: 12px 16px 16px;
    position: fixed;
    z-index: 1001;
    background: #fff;
    border-radius: 8px 8px 0 0;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2)
}

@media (min-width: 1024px) {
    .i-popup-publication-answer {
        width: calc(100% - 32px);
        max-width: 410px;
        padding: 28px 40px 40px;
        border-radius: 8px;
        position: absolute
    }
}

@media (max-width: 1023.98px) {
    .i-popup-publication-answer {
        top: auto !important;
        left: 0 !important;
        bottom: 0;
        max-height: 100%
    }
}

.i-popup-publication-answer .close, .i-popup-publication-answer .popup__close {
    width: 26px;
    height: 26px;
    display: inline-block;
    position: relative;
    font-size: 0;
    line-height: 1;
    border: none;
    background-color: transparent;
    position: absolute;
    z-index: 1;
    top: 13px;
    right: 12px;
    cursor: pointer
}

.i-popup-publication-answer .close:after, .i-popup-publication-answer .close:before, .i-popup-publication-answer .popup__close:after, .i-popup-publication-answer .popup__close:before {
    -webkit-transition: background .3s ease 0s;
    transition: background .3s ease 0s;
    background-color: #999;
    border-radius: 2px;
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg)
}

.i-popup-publication-answer .close:before, .i-popup-publication-answer .popup__close:before {
    width: 2px;
    height: 20px
}

.i-popup-publication-answer .close:after, .i-popup-publication-answer .popup__close:after {
    width: 20px;
    height: 2px
}

@media (min-width: 1024px) {
    .i-popup-publication-answer .close, .i-popup-publication-answer .popup__close {
        top: 8px;
        right: 8px
    }
}

@media (max-width: 575.98px) {
    .i-popup-publication-answer .close ~ .popup__content, .i-popup-publication-answer .popup__close ~ .popup__content {
        padding-top: 28px
    }
}

@media (max-width: 575.98px) {
    .i-popup-publication-answer .close ~ .popup__content .popup__header, .i-popup-publication-answer .popup__close ~ .popup__content .popup__header {
        margin-top: -28px
    }
}

.i-popup-publication-answer .popup__close-btn {
    font-size: 16px;
    line-height: 22px;
    position: absolute;
    z-index: 1;
    top: 16px;
    right: 16px;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    padding: 4px 12px 6px;
    background-color: #fff;
    border-radius: 24px;
    border: none;
    cursor: pointer
}

@media (max-width: 1023.98px) {
    .i-popup-publication-answer .popup__close-btn {
        width: 26px;
        height: 26px;
        display: inline-block;
        position: relative;
        font-size: 0;
        line-height: 1;
        border: none;
        background-color: transparent;
        position: absolute;
        z-index: 1;
        top: 13px;
        right: 12px;
        cursor: pointer;
        line-height: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        padding: 0;
        border-radius: 0
    }

    .i-popup-publication-answer .popup__close-btn:after, .i-popup-publication-answer .popup__close-btn:before {
        -webkit-transition: background .3s ease 0s;
        transition: background .3s ease 0s;
        background-color: #999;
        border-radius: 2px;
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
        transform: translate(-50%, -50%) rotate(45deg)
    }

    .i-popup-publication-answer .popup__close-btn:before {
        width: 2px;
        height: 20px
    }

    .i-popup-publication-answer .popup__close-btn:after {
        width: 20px;
        height: 2px
    }
}

@media (max-width: 1023.98px) and (min-width: 1024px) {
    .i-popup-publication-answer .popup__close-btn {
        top: 8px;
        right: 8px
    }
}

@media (max-width: 1023.98px) and (max-width: 575.98px) {
    .i-popup-publication-answer .popup__close-btn ~ .popup__content {
        padding-top: 28px
    }
}

@media (max-width: 1023.98px) and (max-width: 575.98px) {
    .i-popup-publication-answer .popup__close-btn ~ .popup__content .popup__header {
        margin-top: -28px
    }
}

.i-popup-publication-answer .popup__header {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: #000;
    margin: 0 0 16px;
    padding-right: 24px
}

@media (min-width: 768px) {
    .i-popup-publication-answer .popup__header {
        font-size: 20px;
        line-height: 28px
    }
}

@media (min-width: 1024px) {
    .i-popup-publication-answer .popup__header {
        font-size: 28px;
        line-height: 36px
    }
}

@media (min-width: 1024px) {
    .i-popup-publication-answer .popup__header {
        margin: 0 0 20px;
        padding-right: 0
    }
}

.i-popup-publication-answer .popup__btn {
    margin: 10px 0 0;
    font-size: 0
}

@media (min-width: 576px) {
    .i-popup-publication-answer .popup__btn {
        margin: 20px 0 0
    }
}

.i-popup-publication-answer .popup__btn-main {
    font-family: system, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Segoe WP', Roboto, Ubuntu, Oxygen, Cantarell, 'Fira Sans', 'Helvetica Neue', Helvetica, 'Lucida Grande', 'Droid Sans', Tahoma, 'Microsoft Sans Serif', sans-serif;
    line-height: 1.1;
    letter-spacing: 0;
    font-weight: 700;
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    background-color: #cb11ab;
    border: 2px solid #cb11ab;
    font-size: 16px;
    padding: 11px 25px 12px;
    margin-top: 12px;
    margin-right: 20px
}

.i-popup-publication-answer .popup__btn-main.disabled, .i-popup-publication-answer .popup__btn-main.disabled:active, .i-popup-publication-answer .popup__btn-main.disabled:hover, .i-popup-publication-answer .popup__btn-main:disabled, .i-popup-publication-answer .popup__btn-main:disabled:active, .i-popup-publication-answer .popup__btn-main:disabled:hover {
    cursor: default
}

.i-popup-publication-answer .popup__btn-main.disabled:active:not(.progress), .i-popup-publication-answer .popup__btn-main.disabled:hover:not(.progress), .i-popup-publication-answer .popup__btn-main.disabled:not(.progress), .i-popup-publication-answer .popup__btn-main:disabled:active:not(.progress), .i-popup-publication-answer .popup__btn-main:disabled:hover:not(.progress), .i-popup-publication-answer .popup__btn-main:disabled:not(.progress) {
    color: #d7d7d7;
    background-color: #fff;
    border: 2px solid #d5d5d5;
    opacity: .9
}

.i-popup-publication-answer .popup__btn-main.disabled:active:not(.progress):active, .i-popup-publication-answer .popup__btn-main.disabled:hover:not(.progress):active, .i-popup-publication-answer .popup__btn-main.disabled:not(.progress):active, .i-popup-publication-answer .popup__btn-main:disabled:active:not(.progress):active, .i-popup-publication-answer .popup__btn-main:disabled:hover:not(.progress):active, .i-popup-publication-answer .popup__btn-main:disabled:not(.progress):active {
    -webkit-transform: none;
    transform: none
}

.i-popup-publication-answer .popup__btn-main.progress, .i-popup-publication-answer .popup__btn-main.progress:active {
    color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

@keyframes mySpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.i-popup-publication-answer .popup__btn-main.progress:active:after, .i-popup-publication-answer .popup__btn-main.progress:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 20px / 2);
    right: calc(50% - 20px / 2);
    -webkit-animation: mySpin .9s linear 0s infinite;
    animation: mySpin .9s linear 0s infinite
}

.i-popup-publication-answer .popup__btn-main.progress:active:after, .i-popup-publication-answer .popup__btn-main.progress:after {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%208A7.999%207.999%200%201%201%200%208h2.002a5.998%205.998%200%201%200%2011.996%200H16z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
    .i-popup-publication-answer .popup__btn-main:hover {
        background-color: #e313bf;
        border-color: #e313bf
    }

    .i-popup-publication-answer .popup__btn-main:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (hover: hover) and (pointer: fine) {
    .i-popup-publication-answer .popup__btn-main:hover {
        background-color: #e313bf;
        border-color: #e313bf
    }

    .i-popup-publication-answer .popup__btn-main:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (max-width: 575.98px) {
    .i-popup-publication-answer .popup__btn-main {
        margin-right: 15px
    }
}

.i-popup-publication-answer .popup__btn-base {
    font-family: system, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Segoe WP', Roboto, Ubuntu, Oxygen, Cantarell, 'Fira Sans', 'Helvetica Neue', Helvetica, 'Lucida Grande', 'Droid Sans', Tahoma, 'Microsoft Sans Serif', sans-serif;
    line-height: 1.1;
    letter-spacing: 0;
    font-weight: 700;
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    color: #cb11ab;
    font-size: 16px;
    padding: 11px 25px 12px;
    margin-top: 12px
}

.i-popup-publication-answer .popup__btn-base.disabled, .i-popup-publication-answer .popup__btn-base.disabled:active, .i-popup-publication-answer .popup__btn-base.disabled:hover, .i-popup-publication-answer .popup__btn-base:disabled, .i-popup-publication-answer .popup__btn-base:disabled:active, .i-popup-publication-answer .popup__btn-base:disabled:hover {
    cursor: default
}

.i-popup-publication-answer .popup__btn-base.disabled:active:not(.progress), .i-popup-publication-answer .popup__btn-base.disabled:hover:not(.progress), .i-popup-publication-answer .popup__btn-base.disabled:not(.progress), .i-popup-publication-answer .popup__btn-base:disabled:active:not(.progress), .i-popup-publication-answer .popup__btn-base:disabled:hover:not(.progress), .i-popup-publication-answer .popup__btn-base:disabled:not(.progress) {
    color: #d7d7d7;
    background-color: #fff;
    border: 2px solid #d5d5d5;
    opacity: .9
}

.i-popup-publication-answer .popup__btn-base.disabled:active:not(.progress):active, .i-popup-publication-answer .popup__btn-base.disabled:hover:not(.progress):active, .i-popup-publication-answer .popup__btn-base.disabled:not(.progress):active, .i-popup-publication-answer .popup__btn-base:disabled:active:not(.progress):active, .i-popup-publication-answer .popup__btn-base:disabled:hover:not(.progress):active, .i-popup-publication-answer .popup__btn-base:disabled:not(.progress):active {
    -webkit-transform: none;
    transform: none
}

.i-popup-publication-answer .popup__btn-base.progress, .i-popup-publication-answer .popup__btn-base.progress:active {
    color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

@keyframes mySpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.i-popup-publication-answer .popup__btn-base.progress:active:after, .i-popup-publication-answer .popup__btn-base.progress:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 20px / 2);
    right: calc(50% - 20px / 2);
    -webkit-animation: mySpin .9s linear 0s infinite;
    animation: mySpin .9s linear 0s infinite
}

.i-popup-publication-answer .popup__btn-base.progress:active:after, .i-popup-publication-answer .popup__btn-base.progress:after {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%208A7.999%207.999%200%201%201%200%208h2.002a5.998%205.998%200%201%200%2011.996%200H16z%22%20fill%3D%22%23CB11AB%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain
}

.i-popup-publication-answer .popup__btn-base, .i-popup-publication-answer .popup__btn-base.progress:active, .i-popup-publication-answer .popup__btn-base.progress:hover {
    background-color: #fff;
    border: 2px solid #cb11ab
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
    .i-popup-publication-answer .popup__btn-base:hover {
        background-color: #cb11ab;
        color: #fff
    }

    .i-popup-publication-answer .popup__btn-base:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (hover: hover) and (pointer: fine) {
    .i-popup-publication-answer .popup__btn-base:hover {
        background-color: #cb11ab;
        color: #fff
    }

    .i-popup-publication-answer .popup__btn-base:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

.i-popup-publication-answer .popup__btn-block {
    margin-top: 20px
}

@media (min-width: 1024px) {
    .i-popup-publication-answer .popup__btn-block {
        margin-top: 32px
    }
}

@media (max-width: 1023.98px) {
    .i-popup-publication-answer .btn-main {
        width: 100%
    }
}

.i-popup-image-crop {
    font-size: 16px;
    line-height: 22px;
    color: #8b8b8b;
    display: none;
    width: 100%;
    max-width: 100%;
    min-width: 320px;
    padding: 12px 16px 16px;
    position: fixed;
    z-index: 1001;
    background: #fff;
    border-radius: 8px;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2)
}

@media (min-width: 1024px) {
    .i-popup-image-crop {
        width: calc(100% - 32px);
        max-width: 1440px;
        padding: 28px 40px 40px;
        border-radius: 8px;
        position: absolute
    }
}

@media (max-width: 1023.98px) {
    .i-popup-image-crop {
        max-height: 100%
    }
}

.i-popup-image-crop .close, .i-popup-image-crop .popup__close {
    width: 26px;
    height: 26px;
    display: inline-block;
    position: relative;
    font-size: 0;
    line-height: 1;
    border: none;
    background-color: transparent;
    position: absolute;
    z-index: 1;
    top: 13px;
    right: 12px;
    cursor: pointer
}

.i-popup-image-crop .close:after, .i-popup-image-crop .close:before, .i-popup-image-crop .popup__close:after, .i-popup-image-crop .popup__close:before {
    -webkit-transition: background .3s ease 0s;
    transition: background .3s ease 0s;
    background-color: #999;
    border-radius: 2px;
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg)
}

.i-popup-image-crop .close:before, .i-popup-image-crop .popup__close:before {
    width: 2px;
    height: 20px
}

.i-popup-image-crop .close:after, .i-popup-image-crop .popup__close:after {
    width: 20px;
    height: 2px
}

@media (min-width: 1024px) {
    .i-popup-image-crop .close, .i-popup-image-crop .popup__close {
        top: 8px;
        right: 8px
    }
}

@media (max-width: 575.98px) {
    .i-popup-image-crop .close ~ .popup__content, .i-popup-image-crop .popup__close ~ .popup__content {
        padding-top: 28px
    }
}

@media (max-width: 575.98px) {
    .i-popup-image-crop .close ~ .popup__content .popup__header, .i-popup-image-crop .popup__close ~ .popup__content .popup__header {
        margin-top: -28px
    }
}

.i-popup-image-crop .popup__close-btn {
    font-size: 16px;
    line-height: 22px;
    position: absolute;
    z-index: 1;
    top: 16px;
    right: 16px;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    padding: 4px 12px 6px;
    background-color: #fff;
    border-radius: 24px;
    border: none;
    cursor: pointer
}

@media (max-width: 1023.98px) {
    .i-popup-image-crop .popup__close-btn {
        width: 26px;
        height: 26px;
        display: inline-block;
        position: relative;
        font-size: 0;
        line-height: 1;
        border: none;
        background-color: transparent;
        position: absolute;
        z-index: 1;
        top: 13px;
        right: 12px;
        cursor: pointer;
        line-height: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        padding: 0;
        border-radius: 0
    }

    .i-popup-image-crop .popup__close-btn:after, .i-popup-image-crop .popup__close-btn:before {
        -webkit-transition: background .3s ease 0s;
        transition: background .3s ease 0s;
        background-color: #999;
        border-radius: 2px;
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
        transform: translate(-50%, -50%) rotate(45deg)
    }

    .i-popup-image-crop .popup__close-btn:before {
        width: 2px;
        height: 20px
    }

    .i-popup-image-crop .popup__close-btn:after {
        width: 20px;
        height: 2px
    }
}

@media (max-width: 1023.98px) and (min-width: 1024px) {
    .i-popup-image-crop .popup__close-btn {
        top: 8px;
        right: 8px
    }
}

@media (max-width: 1023.98px) and (max-width: 575.98px) {
    .i-popup-image-crop .popup__close-btn ~ .popup__content {
        padding-top: 28px
    }
}

@media (max-width: 1023.98px) and (max-width: 575.98px) {
    .i-popup-image-crop .popup__close-btn ~ .popup__content .popup__header {
        margin-top: -28px
    }
}

.i-popup-image-crop .popup__header {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: #000;
    margin: 0 0 16px;
    padding-right: 24px
}

@media (min-width: 768px) {
    .i-popup-image-crop .popup__header {
        font-size: 20px;
        line-height: 28px
    }
}

@media (min-width: 1024px) {
    .i-popup-image-crop .popup__header {
        font-size: 28px;
        line-height: 36px
    }
}

@media (min-width: 1024px) {
    .i-popup-image-crop .popup__header {
        margin: 0 0 20px;
        padding-right: 0
    }
}

.i-popup-image-crop .popup__btn {
    margin: 10px 0 0;
    font-size: 0
}

@media (min-width: 576px) {
    .i-popup-image-crop .popup__btn {
        margin: 20px 0 0
    }
}

.i-popup-image-crop .popup__btn-main {
    font-family: system, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Segoe WP', Roboto, Ubuntu, Oxygen, Cantarell, 'Fira Sans', 'Helvetica Neue', Helvetica, 'Lucida Grande', 'Droid Sans', Tahoma, 'Microsoft Sans Serif', sans-serif;
    line-height: 1.1;
    letter-spacing: 0;
    font-weight: 700;
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    background-color: #cb11ab;
    border: 2px solid #cb11ab;
    font-size: 16px;
    padding: 11px 25px 12px;
    margin-top: 12px;
    margin-right: 20px
}

.i-popup-image-crop .popup__btn-main.disabled, .i-popup-image-crop .popup__btn-main.disabled:active, .i-popup-image-crop .popup__btn-main.disabled:hover, .i-popup-image-crop .popup__btn-main:disabled, .i-popup-image-crop .popup__btn-main:disabled:active, .i-popup-image-crop .popup__btn-main:disabled:hover {
    cursor: default
}

.i-popup-image-crop .popup__btn-main.disabled:active:not(.progress), .i-popup-image-crop .popup__btn-main.disabled:hover:not(.progress), .i-popup-image-crop .popup__btn-main.disabled:not(.progress), .i-popup-image-crop .popup__btn-main:disabled:active:not(.progress), .i-popup-image-crop .popup__btn-main:disabled:hover:not(.progress), .i-popup-image-crop .popup__btn-main:disabled:not(.progress) {
    color: #d7d7d7;
    background-color: #fff;
    border: 2px solid #d5d5d5;
    opacity: .9
}

.i-popup-image-crop .popup__btn-main.disabled:active:not(.progress):active, .i-popup-image-crop .popup__btn-main.disabled:hover:not(.progress):active, .i-popup-image-crop .popup__btn-main.disabled:not(.progress):active, .i-popup-image-crop .popup__btn-main:disabled:active:not(.progress):active, .i-popup-image-crop .popup__btn-main:disabled:hover:not(.progress):active, .i-popup-image-crop .popup__btn-main:disabled:not(.progress):active {
    -webkit-transform: none;
    transform: none
}

.i-popup-image-crop .popup__btn-main.progress, .i-popup-image-crop .popup__btn-main.progress:active {
    color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

@keyframes mySpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.i-popup-image-crop .popup__btn-main.progress:active:after, .i-popup-image-crop .popup__btn-main.progress:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 20px / 2);
    right: calc(50% - 20px / 2);
    -webkit-animation: mySpin .9s linear 0s infinite;
    animation: mySpin .9s linear 0s infinite
}

.i-popup-image-crop .popup__btn-main.progress:active:after, .i-popup-image-crop .popup__btn-main.progress:after {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%208A7.999%207.999%200%201%201%200%208h2.002a5.998%205.998%200%201%200%2011.996%200H16z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
    .i-popup-image-crop .popup__btn-main:hover {
        background-color: #e313bf;
        border-color: #e313bf
    }

    .i-popup-image-crop .popup__btn-main:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (hover: hover) and (pointer: fine) {
    .i-popup-image-crop .popup__btn-main:hover {
        background-color: #e313bf;
        border-color: #e313bf
    }

    .i-popup-image-crop .popup__btn-main:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (max-width: 575.98px) {
    .i-popup-image-crop .popup__btn-main {
        margin-right: 15px
    }
}

.i-popup-image-crop .popup__btn-base {
    font-family: system, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Segoe WP', Roboto, Ubuntu, Oxygen, Cantarell, 'Fira Sans', 'Helvetica Neue', Helvetica, 'Lucida Grande', 'Droid Sans', Tahoma, 'Microsoft Sans Serif', sans-serif;
    line-height: 1.1;
    letter-spacing: 0;
    font-weight: 700;
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    color: #cb11ab;
    font-size: 16px;
    padding: 11px 25px 12px;
    margin-top: 12px
}

.i-popup-image-crop .popup__btn-base.disabled, .i-popup-image-crop .popup__btn-base.disabled:active, .i-popup-image-crop .popup__btn-base.disabled:hover, .i-popup-image-crop .popup__btn-base:disabled, .i-popup-image-crop .popup__btn-base:disabled:active, .i-popup-image-crop .popup__btn-base:disabled:hover {
    cursor: default
}

.i-popup-image-crop .popup__btn-base.disabled:active:not(.progress), .i-popup-image-crop .popup__btn-base.disabled:hover:not(.progress), .i-popup-image-crop .popup__btn-base.disabled:not(.progress), .i-popup-image-crop .popup__btn-base:disabled:active:not(.progress), .i-popup-image-crop .popup__btn-base:disabled:hover:not(.progress), .i-popup-image-crop .popup__btn-base:disabled:not(.progress) {
    color: #d7d7d7;
    background-color: #fff;
    border: 2px solid #d5d5d5;
    opacity: .9
}

.i-popup-image-crop .popup__btn-base.disabled:active:not(.progress):active, .i-popup-image-crop .popup__btn-base.disabled:hover:not(.progress):active, .i-popup-image-crop .popup__btn-base.disabled:not(.progress):active, .i-popup-image-crop .popup__btn-base:disabled:active:not(.progress):active, .i-popup-image-crop .popup__btn-base:disabled:hover:not(.progress):active, .i-popup-image-crop .popup__btn-base:disabled:not(.progress):active {
    -webkit-transform: none;
    transform: none
}

.i-popup-image-crop .popup__btn-base.progress, .i-popup-image-crop .popup__btn-base.progress:active {
    color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

@keyframes mySpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.i-popup-image-crop .popup__btn-base.progress:active:after, .i-popup-image-crop .popup__btn-base.progress:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 20px / 2);
    right: calc(50% - 20px / 2);
    -webkit-animation: mySpin .9s linear 0s infinite;
    animation: mySpin .9s linear 0s infinite
}

.i-popup-image-crop .popup__btn-base.progress:active:after, .i-popup-image-crop .popup__btn-base.progress:after {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%208A7.999%207.999%200%201%201%200%208h2.002a5.998%205.998%200%201%200%2011.996%200H16z%22%20fill%3D%22%23CB11AB%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain
}

.i-popup-image-crop .popup__btn-base, .i-popup-image-crop .popup__btn-base.progress:active, .i-popup-image-crop .popup__btn-base.progress:hover {
    background-color: #fff;
    border: 2px solid #cb11ab
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
    .i-popup-image-crop .popup__btn-base:hover {
        background-color: #cb11ab;
        color: #fff
    }

    .i-popup-image-crop .popup__btn-base:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (hover: hover) and (pointer: fine) {
    .i-popup-image-crop .popup__btn-base:hover {
        background-color: #cb11ab;
        color: #fff
    }

    .i-popup-image-crop .popup__btn-base:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (max-width: 1023.98px) {
    .i-popup-image-crop {
        height: 100%
    }
}

@media (min-width: 1024px) {
    .i-popup-image-crop {
        height: 830px
    }
}

.i-popup-image-crop .popup__header {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 24px
}

@media (min-width: 768px) {
    .i-popup-image-crop .popup__header {
        max-height: 28px
    }
}

@media (min-width: 1024px) {
    .i-popup-image-crop .popup__header {
        max-height: 36px
    }
}

.i-popup-image-crop .popup__content, .i-popup-image-crop .popup__content form {
    height: 100%
}

.i-popup-image-crop .popup__top-wrap {
    min-height: 82px;
    margin-bottom: 4px
}

@media (min-width: 768px) {
    .i-popup-image-crop .popup__top-wrap {
        min-height: 56px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start
    }
}

@media (min-width: 1024px) {
    .i-popup-image-crop .popup__top-wrap {
        min-height: initial;
        margin-bottom: 20px
    }
}

.i-popup-image-crop .popup__text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 40px
}

@media (max-width: 767.98px) {
    .i-popup-image-crop .popup__text {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 8px
    }
}

@media (min-width: 768px) {
    .i-popup-image-crop .popup__text {
        -ms-flex-negative: 0;
        flex-shrink: 0;
        width: calc(100% - 280px - 20px);
        max-height: 50px;
        margin-right: 20px;
        padding-top: 6px
    }
}

@media (min-width: 1024px) {
    .i-popup-image-crop .popup__text {
        -webkit-line-clamp: 1;
        max-height: 28px
    }
}

.i-popup-image-crop .popup__btn-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

@media (min-width: 768px) {
    .i-popup-image-crop .popup__btn-wrap {
        width: 280px;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end
    }
}

.i-popup-image-crop .popup__btn-turn {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 16px;
    line-height: 22px;
    padding: 6px 0 6px 26px;
    -webkit-transition: color .3s ease 0s;
    transition: color .3s ease 0s
}

.i-popup-image-crop .popup__btn-turn:hover {
    color: #cb11ab
}

.i-popup-image-crop .popup__btn-turn--left {
    background: left 0 top 13px 10px no-repeat url(data:image/svg+xml,%3Csvg%20width%3D%2220%22%20height%3D%2210%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M2%205.366c.5-.654%201.15-1.492%201.57-1.953C5.535%201.258%207.762%200%2010.5%200%2016.89%200%2020%204.412%2020%2010h-2c0-4.603-2.395-8-7.5-8-2.091%200-3.832.983-5.45%202.76-.424.464-1.23%201.522-1.782%202.24H9v2H0V0h2v5.366z%22%20fill%3D%22%23CB11AB%22%2F%3E%3C%2Fsvg%3E)
}

@media (min-width: 768px) {
    .i-popup-image-crop .popup__btn-turn--left {
        margin-left: 22px
    }
}

.i-popup-image-crop .popup__btn-turn--right {
    background: left 0 top 13px 10px no-repeat url(data:image/svg+xml,%3Csvg%20width%3D%2220%22%20height%3D%2210%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M18%205.366V0h2v9h-9V7h5.732c-.552-.718-1.358-1.776-1.781-2.24C13.332%202.983%2011.59%202%209.5%202%204.395%202%202%205.397%202%2010H0C0%204.412%203.11%200%209.5%200c2.739%200%204.966%201.258%206.93%203.413.42.461%201.07%201.299%201.57%201.953z%22%20fill%3D%22%23CB11AB%22%2F%3E%3C%2Fsvg%3E);
    margin-left: 22px
}

.i-popup-image-crop .popup__crop-container {
    height: calc(100% - 40px - 86px - 60px + 28px)
}

@media (max-width: 1023.98px) {
    .i-popup-image-crop .popup__crop-container {
        margin: 0 -16px
    }
}

@media (min-width: 576px) {
    .i-popup-image-crop .popup__crop-container {
        height: calc(100% - 40px - 86px - 60px)
    }
}

@media (min-width: 768px) {
    .i-popup-image-crop .popup__crop-container {
        height: calc(100% - 44px - 60px - 72px)
    }
}

@media (min-width: 1024px) {
    .i-popup-image-crop .popup__crop-container {
        height: calc(100% - 56px - 54px - 72px)
    }
}

.i-popup-image-crop .cropper-preview {
    border-color: #cb11ab
}

.i-popup-image-crop .cropper-dashed, .i-popup-image-crop .cropper-line {
    display: none
}

.i-popup-image-crop .cropper-point {
    width: 16px;
    height: 16px;
    background: #cb11ab;
    opacity: 1
}

.i-popup-image-crop .cropper-point.point-e, .i-popup-image-crop .cropper-point.point-w {
    top: 50%;
    margin-top: -8px
}

.i-popup-image-crop .cropper-point.point-n, .i-popup-image-crop .cropper-point.point-s {
    left: 50%;
    margin-left: -8px
}

.i-popup-image-crop .cropper-point.point-e, .i-popup-image-crop .cropper-point.point-ne, .i-popup-image-crop .cropper-point.point-se {
    right: 0
}

.i-popup-image-crop .cropper-point.point-s, .i-popup-image-crop .cropper-point.point-se, .i-popup-image-crop .cropper-point.point-sw {
    bottom: 0
}

.i-popup-image-crop .cropper-point.point-nw, .i-popup-image-crop .cropper-point.point-sw, .i-popup-image-crop .cropper-point.point-w {
    left: 0
}

.i-popup-image-crop .cropper-point.point-n, .i-popup-image-crop .cropper-point.point-ne, .i-popup-image-crop .cropper-point.point-nw {
    top: 0
}

.i-popup-image-crop.round-image .cropper-canvas::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000;
    opacity: .5;
    z-index: 1
}

.i-popup-image-crop.round-image .cropper-view-box {
    outline: 0;
    border-radius: 50%
}

.i-popup-image-crop.round-image .cropper-line {
    display: none
}

.i-popup-image-crop.round-image .cropper-face {
    border-radius: 50%
}

.i-popup-image-crop.round-image .cropper-point {
    width: 16px;
    height: 16px;
    background-color: #cb11ab;
    opacity: 1
}

.i-popup-image-crop.round-image .point-e, .i-popup-image-crop.round-image .point-n, .i-popup-image-crop.round-image .point-s, .i-popup-image-crop.round-image .point-w {
    display: none
}

.i-popup-image-crop.round-image .point-nw {
    left: 0;
    top: 0
}

.i-popup-image-crop.round-image .point-sw {
    left: 0;
    bottom: 0
}

.i-popup-image-crop.round-image .point-ne {
    right: 0;
    top: 0
}

.i-popup-image-crop.round-image .point-se {
    right: 0;
    bottom: 0
}

.i-popup-image-crop .popup__btn-block {
    margin-top: 16px
}

@media (max-width: 1023.98px) {
    .i-popup-image-crop .popup__btn-block {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between
    }
}

@media (min-width: 768px) {
    .i-popup-image-crop .popup__btn-block {
        margin-top: 28px
    }
}

@media (max-width: 1023.98px) {
    .i-popup-image-crop .btn-base, .i-popup-image-crop .btn-main {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: calc((100% - 8px) / 2);
        padding-left: 10px;
        padding-right: 10px
    }
}

@media (min-width: 1024px) {
    .i-popup-image-crop .btn-base {
        margin-left: 20px
    }
}

.i-popup-quick-order {
    font-size: 16px;
    line-height: 22px;
    color: #8b8b8b;
    display: none;
    width: 100%;
    max-width: 100%;
    min-width: 320px;
    padding: 12px 16px 16px;
    position: fixed;
    z-index: 1001;
    background: #fff;
    border-radius: 8px 8px 0 0;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2)
}

@media (min-width: 1024px) {
    .i-popup-quick-order {
        width: calc(100% - 32px);
        max-width: 400px;
        padding: 28px 40px 40px;
        border-radius: 8px;
        position: absolute
    }
}

@media (max-width: 1023.98px) {
    .i-popup-quick-order {
        top: auto !important;
        left: 0 !important;
        bottom: 0;
        max-height: 100%
    }
}

.i-popup-quick-order .close, .i-popup-quick-order .popup__close {
    width: 26px;
    height: 26px;
    display: inline-block;
    position: relative;
    font-size: 0;
    line-height: 1;
    border: none;
    background-color: transparent;
    position: absolute;
    z-index: 1;
    top: 13px;
    right: 12px;
    cursor: pointer
}

.i-popup-quick-order .close:after, .i-popup-quick-order .close:before, .i-popup-quick-order .popup__close:after, .i-popup-quick-order .popup__close:before {
    -webkit-transition: background .3s ease 0s;
    transition: background .3s ease 0s;
    background-color: #999;
    border-radius: 2px;
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg)
}

.i-popup-quick-order .close:before, .i-popup-quick-order .popup__close:before {
    width: 2px;
    height: 20px
}

.i-popup-quick-order .close:after, .i-popup-quick-order .popup__close:after {
    width: 20px;
    height: 2px
}

@media (min-width: 1024px) {
    .i-popup-quick-order .close, .i-popup-quick-order .popup__close {
        top: 8px;
        right: 8px
    }
}

@media (max-width: 575.98px) {
    .i-popup-quick-order .close ~ .popup__content, .i-popup-quick-order .popup__close ~ .popup__content {
        padding-top: 28px
    }
}

@media (max-width: 575.98px) {
    .i-popup-quick-order .close ~ .popup__content .popup__header, .i-popup-quick-order .popup__close ~ .popup__content .popup__header {
        margin-top: -28px
    }
}

.i-popup-quick-order .popup__close-btn {
    font-size: 16px;
    line-height: 22px;
    position: absolute;
    z-index: 1;
    top: 16px;
    right: 16px;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    padding: 4px 12px 6px;
    background-color: #fff;
    border-radius: 24px;
    border: none;
    cursor: pointer
}

@media (max-width: 1023.98px) {
    .i-popup-quick-order .popup__close-btn {
        width: 26px;
        height: 26px;
        display: inline-block;
        position: relative;
        font-size: 0;
        line-height: 1;
        border: none;
        background-color: transparent;
        position: absolute;
        z-index: 1;
        top: 13px;
        right: 12px;
        cursor: pointer;
        line-height: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        padding: 0;
        border-radius: 0
    }

    .i-popup-quick-order .popup__close-btn:after, .i-popup-quick-order .popup__close-btn:before {
        -webkit-transition: background .3s ease 0s;
        transition: background .3s ease 0s;
        background-color: #999;
        border-radius: 2px;
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
        transform: translate(-50%, -50%) rotate(45deg)
    }

    .i-popup-quick-order .popup__close-btn:before {
        width: 2px;
        height: 20px
    }

    .i-popup-quick-order .popup__close-btn:after {
        width: 20px;
        height: 2px
    }
}

@media (max-width: 1023.98px) and (min-width: 1024px) {
    .i-popup-quick-order .popup__close-btn {
        top: 8px;
        right: 8px
    }
}

@media (max-width: 1023.98px) and (max-width: 575.98px) {
    .i-popup-quick-order .popup__close-btn ~ .popup__content {
        padding-top: 28px
    }
}

@media (max-width: 1023.98px) and (max-width: 575.98px) {
    .i-popup-quick-order .popup__close-btn ~ .popup__content .popup__header {
        margin-top: -28px
    }
}

.i-popup-quick-order .popup__header {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: #000;
    margin: 0 0 16px;
    padding-right: 24px
}

@media (min-width: 768px) {
    .i-popup-quick-order .popup__header {
        font-size: 20px;
        line-height: 28px
    }
}

@media (min-width: 1024px) {
    .i-popup-quick-order .popup__header {
        font-size: 28px;
        line-height: 36px
    }
}

@media (min-width: 1024px) {
    .i-popup-quick-order .popup__header {
        margin: 0 0 20px;
        padding-right: 0
    }
}

.i-popup-quick-order .popup__btn {
    margin: 10px 0 0;
    font-size: 0
}

@media (min-width: 576px) {
    .i-popup-quick-order .popup__btn {
        margin: 20px 0 0
    }
}

.i-popup-quick-order .popup__btn-main {
    font-family: system, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Segoe WP', Roboto, Ubuntu, Oxygen, Cantarell, 'Fira Sans', 'Helvetica Neue', Helvetica, 'Lucida Grande', 'Droid Sans', Tahoma, 'Microsoft Sans Serif', sans-serif;
    line-height: 1.1;
    letter-spacing: 0;
    font-weight: 700;
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    background-color: #cb11ab;
    border: 2px solid #cb11ab;
    font-size: 16px;
    padding: 11px 25px 12px;
    margin-top: 12px;
    margin-right: 20px
}

.i-popup-quick-order .popup__btn-main.disabled, .i-popup-quick-order .popup__btn-main.disabled:active, .i-popup-quick-order .popup__btn-main.disabled:hover, .i-popup-quick-order .popup__btn-main:disabled, .i-popup-quick-order .popup__btn-main:disabled:active, .i-popup-quick-order .popup__btn-main:disabled:hover {
    cursor: default
}

.i-popup-quick-order .popup__btn-main.disabled:active:not(.progress), .i-popup-quick-order .popup__btn-main.disabled:hover:not(.progress), .i-popup-quick-order .popup__btn-main.disabled:not(.progress), .i-popup-quick-order .popup__btn-main:disabled:active:not(.progress), .i-popup-quick-order .popup__btn-main:disabled:hover:not(.progress), .i-popup-quick-order .popup__btn-main:disabled:not(.progress) {
    color: #d7d7d7;
    background-color: #fff;
    border: 2px solid #d5d5d5;
    opacity: .9
}

.i-popup-quick-order .popup__btn-main.disabled:active:not(.progress):active, .i-popup-quick-order .popup__btn-main.disabled:hover:not(.progress):active, .i-popup-quick-order .popup__btn-main.disabled:not(.progress):active, .i-popup-quick-order .popup__btn-main:disabled:active:not(.progress):active, .i-popup-quick-order .popup__btn-main:disabled:hover:not(.progress):active, .i-popup-quick-order .popup__btn-main:disabled:not(.progress):active {
    -webkit-transform: none;
    transform: none
}

.i-popup-quick-order .popup__btn-main.progress, .i-popup-quick-order .popup__btn-main.progress:active {
    color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

@keyframes mySpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.i-popup-quick-order .popup__btn-main.progress:active:after, .i-popup-quick-order .popup__btn-main.progress:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 20px / 2);
    right: calc(50% - 20px / 2);
    -webkit-animation: mySpin .9s linear 0s infinite;
    animation: mySpin .9s linear 0s infinite
}

.i-popup-quick-order .popup__btn-main.progress:active:after, .i-popup-quick-order .popup__btn-main.progress:after {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%208A7.999%207.999%200%201%201%200%208h2.002a5.998%205.998%200%201%200%2011.996%200H16z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
    .i-popup-quick-order .popup__btn-main:hover {
        background-color: #e313bf;
        border-color: #e313bf
    }

    .i-popup-quick-order .popup__btn-main:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (hover: hover) and (pointer: fine) {
    .i-popup-quick-order .popup__btn-main:hover {
        background-color: #e313bf;
        border-color: #e313bf
    }

    .i-popup-quick-order .popup__btn-main:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (max-width: 575.98px) {
    .i-popup-quick-order .popup__btn-main {
        margin-right: 15px
    }
}

.i-popup-quick-order .popup__btn-base {
    font-family: system, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Segoe WP', Roboto, Ubuntu, Oxygen, Cantarell, 'Fira Sans', 'Helvetica Neue', Helvetica, 'Lucida Grande', 'Droid Sans', Tahoma, 'Microsoft Sans Serif', sans-serif;
    line-height: 1.1;
    letter-spacing: 0;
    font-weight: 700;
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    color: #cb11ab;
    font-size: 16px;
    padding: 11px 25px 12px;
    margin-top: 12px
}

.i-popup-quick-order .popup__btn-base.disabled, .i-popup-quick-order .popup__btn-base.disabled:active, .i-popup-quick-order .popup__btn-base.disabled:hover, .i-popup-quick-order .popup__btn-base:disabled, .i-popup-quick-order .popup__btn-base:disabled:active, .i-popup-quick-order .popup__btn-base:disabled:hover {
    cursor: default
}

.i-popup-quick-order .popup__btn-base.disabled:active:not(.progress), .i-popup-quick-order .popup__btn-base.disabled:hover:not(.progress), .i-popup-quick-order .popup__btn-base.disabled:not(.progress), .i-popup-quick-order .popup__btn-base:disabled:active:not(.progress), .i-popup-quick-order .popup__btn-base:disabled:hover:not(.progress), .i-popup-quick-order .popup__btn-base:disabled:not(.progress) {
    color: #d7d7d7;
    background-color: #fff;
    border: 2px solid #d5d5d5;
    opacity: .9
}

.i-popup-quick-order .popup__btn-base.disabled:active:not(.progress):active, .i-popup-quick-order .popup__btn-base.disabled:hover:not(.progress):active, .i-popup-quick-order .popup__btn-base.disabled:not(.progress):active, .i-popup-quick-order .popup__btn-base:disabled:active:not(.progress):active, .i-popup-quick-order .popup__btn-base:disabled:hover:not(.progress):active, .i-popup-quick-order .popup__btn-base:disabled:not(.progress):active {
    -webkit-transform: none;
    transform: none
}

.i-popup-quick-order .popup__btn-base.progress, .i-popup-quick-order .popup__btn-base.progress:active {
    color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

@keyframes mySpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.i-popup-quick-order .popup__btn-base.progress:active:after, .i-popup-quick-order .popup__btn-base.progress:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 20px / 2);
    right: calc(50% - 20px / 2);
    -webkit-animation: mySpin .9s linear 0s infinite;
    animation: mySpin .9s linear 0s infinite
}

.i-popup-quick-order .popup__btn-base.progress:active:after, .i-popup-quick-order .popup__btn-base.progress:after {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%208A7.999%207.999%200%201%201%200%208h2.002a5.998%205.998%200%201%200%2011.996%200H16z%22%20fill%3D%22%23CB11AB%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain
}

.i-popup-quick-order .popup__btn-base, .i-popup-quick-order .popup__btn-base.progress:active, .i-popup-quick-order .popup__btn-base.progress:hover {
    background-color: #fff;
    border: 2px solid #cb11ab
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
    .i-popup-quick-order .popup__btn-base:hover {
        background-color: #cb11ab;
        color: #fff
    }

    .i-popup-quick-order .popup__btn-base:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (hover: hover) and (pointer: fine) {
    .i-popup-quick-order .popup__btn-base:hover {
        background-color: #cb11ab;
        color: #fff
    }

    .i-popup-quick-order .popup__btn-base:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

.i-popup-quick-order .sizes-list {
    overflow-y: scroll;
    scrollbar-color: #d5d5d5 transparent;
    scrollbar-width: thin;
    max-height: 300px;
    overflow-y: auto
}

.i-popup-quick-order .sizes-list::-webkit-scrollbar {
    width: 4px
}

.i-popup-quick-order .sizes-list::-webkit-scrollbar-track {
    background-color: transparent
}

.i-popup-quick-order .sizes-list::-webkit-scrollbar-thumb {
    height: 30px;
    background-color: #d5d5d5;
    border-radius: 4px
}

.i-popup-quick-order .sizes-list::-webkit-scrollbar-thumb:hover {
    background: #646464
}

.tooltip-price-history {
    position: absolute;
    z-index: 50;
    cursor: default;
    background: #fff;
    border-radius: 8px;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    display: block;
    white-space: nowrap;
    text-align: center;
    pointer-events: none;
    z-index: 90 !important
}

.tooltip-price-history .tooltipster-content {
    font-size: 14px;
    line-height: 20px;
    padding: 12px;
    width: auto
}

@media (min-width: 1024px) {
    .tooltip-price-history {
        border-radius: 4px
    }
}

.tooltip-price-history .tooltipster-arrow {
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1
}

.tooltip-price-history .tooltipster-arrow span {
    width: 0;
    height: 0;
    position: absolute
}

.tooltip-price-history .tooltipster-arrow-right span {
    border-top: 9px solid transparent !important;
    border-bottom: 9px solid transparent !important;
    border-right: 10px solid;
    top: 50%;
    margin-top: -9px;
    left: -10px
}

.tooltip-price-history .tooltipster-arrow-left span {
    border-top: 9px solid transparent !important;
    border-bottom: 9px solid transparent !important;
    border-left: 10px solid;
    top: 50%;
    margin-top: -9px;
    right: -10px
}

.tooltip-price-history .tooltipster-arrow-top span, .tooltip-price-history .tooltipster-arrow-top-left span, .tooltip-price-history .tooltipster-arrow-top-right span {
    border-left: 9px solid transparent !important;
    border-right: 9px solid transparent !important;
    border-top: 10px solid;
    bottom: -10px
}

.tooltip-price-history .tooltipster-arrow-bottom span, .tooltip-price-history .tooltipster-arrow-bottom-left span, .tooltip-price-history .tooltipster-arrow-bottom-right span {
    border-left: 9px solid transparent !important;
    border-right: 9px solid transparent !important;
    border-bottom: 10px solid;
    top: -10px
}

.tooltip-price-history .tooltipster-arrow-bottom span, .tooltip-price-history .tooltipster-arrow-top span {
    left: 0;
    right: 0;
    margin: auto
}

.tooltip-price-history .tooltipster-arrow-bottom-left span, .tooltip-price-history .tooltipster-arrow-top-left span {
    left: 20px
}

.tooltip-price-history .tooltipster-arrow-bottom-right span, .tooltip-price-history .tooltipster-arrow-top-right span {
    right: 20px
}

@media (max-width: 1023.98px) {
    .tooltip-price-history {
        margin-top: 4px
    }
}

.tooltip-price-history .tooltipster-content {
    padding: 4px 8px
}

.tooltip-price-history span {
    display: block;
    margin-top: 2px;
    font-weight: 700
}

@media (min-width: 1024px) {
    .product-detail .sorting {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-align: baseline;
        -ms-flex-align: baseline;
        align-items: baseline
    }
}

.product-detail .sorting .sorting__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.product-detail .sorting .sorting__header {
    font-size: 16px;
    line-height: 22px;
    color: #a7a7a7;
    font-weight: 400;
    margin: 0 12px 12px 0
}

@media (max-width: 1023.98px) {
    .product-detail .sorting .sorting__header {
        display: none
    }
}

@media (min-width: 1024px) {
    .product-detail .sorting .sorting__header {
        margin-right: 20px
    }
}

.product-detail .sorting .sorting__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline
}

@media (max-width: 1023.98px) {
    .product-detail .sorting .sorting__list {
        display: none
    }
}

.product-detail .sorting .sorting__item {
    margin: 0 12px 12px 0
}

@media (min-width: 1024px) {
    .product-detail .sorting .sorting__item {
        margin-right: 20px
    }
}

.product-detail .sorting a {
    border-bottom: 1px dashed;
    font-size: 16px;
    line-height: 22px;
    color: #000;
    border-color: initial;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
    text-transform: capitalize;
    position: relative
}

.product-detail .sorting a:hover {
    color: #cb11ab;
    border-color: initial
}

.product-detail .sorting a.sorting__selected {
    color: #cb11ab;
    border-color: #cb11ab;
    margin-right: 8px
}

.product-detail .sorting .sorting__decor {
    width: 6px;
    height: 10px;
    position: absolute;
    right: -8px;
    top: 8px;
    background: url(data:image/svg+xml,%3Csvg%20width%3D%226%22%20height%3D%2210%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M.468%207.634a.563.563%200%200%201%20.752-.839l1.167%201.046V1.5a.597.597%200%200%201%201.193%200v6.37l1.2-1.075a.563.563%200%200%201%20.752.839l-1.864%201.67a1%201%200%200%201-1.335.001L.468%207.634z%22%20fill%3D%22%23CB11AB%22%2F%3E%3C%2Fsvg%3E) center/contain no-repeat
}

.product-detail .sorting .sorting__decor--up {
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1)
}

.product-detail .sorting .sorting__checkbox {
    margin: 0 12px 12px 0
}

.product-detail .select-radio {
    display: block;
    position: relative
}

.product-detail .select-radio .select-radio__btn {
    display: block;
    position: relative;
    padding: 3px 28px 6px 12px;
    border: 1px solid #d5d5d5;
    border-radius: 4px;
    cursor: pointer
}

.product-detail .select-radio .select-radio__btn::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: 50%;
    right: 10px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #000;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transform-origin: 4px 1px;
    transform-origin: 4px 1px;
    -webkit-transition: -webkit-transform .3s ease 0s;
    transition: -webkit-transform .3s ease 0s;
    transition: transform .3s ease 0s;
    transition: transform .3s ease 0s, -webkit-transform .3s ease 0s
}

.product-detail .select-radio .select-radio__btn.opened::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg)
}

.product-detail .select-radio .select-radio__btn-inner {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.product-detail .select-radio .select-radio__btn-inner::after {
    content: "";
    width: 6px;
    height: 21px;
    margin-left: 4px;
    background: url(data:image/svg+xml,%3Csvg%20width%3D%226%22%20height%3D%2210%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M.468%207.634a.563.563%200%200%201%20.752-.839l1.167%201.046V1.5a.597.597%200%200%201%201.193%200v6.37l1.2-1.075a.563.563%200%200%201%20.752.839l-1.864%201.67a1%201%200%200%201-1.335.001L.468%207.634z%22%20fill%3D%22%23CB11AB%22%2F%3E%3C%2Fsvg%3E) center/contain no-repeat
}

.product-detail .select-radio .select-radio__btn-inner--asc::after {
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1)
}

.product-detail .select-radio .select-radio__btn-text {
    border-bottom: 1px dashed;
    font-size: 16px;
    line-height: 22px;
    color: #000;
    border-color: initial;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
    color: #cb11ab;
    border-color: #cb11ab;
    font-size: 14px;
    line-height: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-transform: capitalize;
    max-width: calc(100% - 10px)
}

.product-detail .select-radio .select-radio__btn-text:hover {
    color: #cb11ab;
    border-color: initial
}

.product-detail .select-radio .select-radio__list {
    display: none;
    min-width: 100%;
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    z-index: 20;
    padding: 8px;
    background: #fff;
    border-radius: 4px;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    box-shadow: 0 0 20px rgba(0, 0, 0, .1)
}

.product-detail .select-radio .select-radio__list.opened {
    display: block
}

.product-detail .select-radio .select-radio__item {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    display: block;
    padding: 4px;
    margin: 0 0 4px;
    border-radius: 4px;
    cursor: pointer;
    -webkit-transition: background .3s ease 0s;
    transition: background .3s ease 0s
}

.product-detail .select-radio .select-radio__item:hover {
    background: #f6f6f6
}

@media (min-width: 768px) {
    .product-detail .select-radio .select-radio__item {
        margin: 0 0 8px
    }
}

.product-detail .select-radio input {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden
}

.product-detail .select-radio input:checked ~ .select-radio__text {
    color: #cb11ab
}

.product-detail .select-radio .select-radio__text {
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding-right: 10px;
    -webkit-transition: color .3s ease 0s;
    transition: color .3s ease 0s
}

.product-detail .select-radio .select-radio__text::after {
    content: "";
    display: block;
    width: 6px;
    height: 10px;
    position: absolute;
    right: 0;
    top: 6px;
    background: url(data:image/svg+xml,%3Csvg%20width%3D%226%22%20height%3D%2210%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M.468%207.634a.563.563%200%200%201%20.752-.839l1.167%201.046V1.5a.597.597%200%200%201%201.193%200v6.37l1.2-1.075a.563.563%200%200%201%20.752.839l-1.864%201.67a1%201%200%200%201-1.335.001L.468%207.634z%22%20fill%3D%22%23000000%22%2F%3E%3C%2Fsvg%3E) center/contain no-repeat
}

.product-detail .select-radio input:checked ~ .select-radio__text::after {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%226%22%20height%3D%2210%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M.468%207.634a.563.563%200%200%201%20.752-.839l1.167%201.046V1.5a.597.597%200%200%201%201.193%200v6.37l1.2-1.075a.563.563%200%200%201%20.752.839l-1.864%201.67a1%201%200%200%201-1.335.001L.468%207.634z%22%20fill%3D%22%23CB11AB%22%2F%3E%3C%2Fsvg%3E)
}

.product-detail .select-radio .select-radio__item--asc .select-radio__text::after {
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1)
}

.product-detail .btn-share {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2220%22%20height%3D%2222%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M15.467%2014.45a3.66%203.66%200%2000-2.432.9l-5.174-3.1a3.075%203.075%200%20000-2.184l5.409-3.227a3.689%203.689%200%20002.197.712C17.412%207.55%2019%206.079%2019%204.275%2019%202.472%2017.412%201%2015.467%201c-1.946%200-3.533%201.472-3.533%203.275%200%20.484.117.946.322%201.364L6.97%208.789a3.653%203.653%200%2000-2.438-.907C2.588%207.882%201%209.354%201%2011.158c0%201.808%201.582%203.28%203.533%203.28a3.68%203.68%200%20002.438-.907l5.168%203.09a3.011%203.011%200%2000-.205%201.104c0%201.803%201.588%203.275%203.533%203.275C17.412%2021%2019%2019.528%2019%2017.725c0-1.804-1.588-3.276-3.533-3.276zm0-11.924c1.043%200%201.892.788%201.892%201.755%200%20.967-.85%201.754-1.892%201.754-1.043%200-1.893-.787-1.893-1.754s.85-1.755%201.893-1.755zM4.533%2012.917c-1.043%200-1.892-.787-1.892-1.754s.85-1.755%201.892-1.755c1.043%200%201.893.788%201.893%201.755%200%20.967-.85%201.754-1.893%201.754zm10.934%206.557c-1.043%200-1.893-.788-1.893-1.755%200-.967.85-1.754%201.893-1.754%201.043%200%201.892.787%201.892%201.754s-.85%201.755-1.892%201.755z%22%20fill%3D%22%23CB11AB%22%20stroke%3D%22%23CB11AB%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    width: 28px;
    height: 28px;
    background-size: 52% auto;
    background-position: 40% center;
    border: 2px solid #cb11ab;
    border-radius: 50%;
    -webkit-transition: background-color .2s ease-out;
    transition: background-color .2s ease-out
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
    .product-detail .btn-share:hover {
        background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2220%22%20height%3D%2222%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M15.467%2014.45a3.66%203.66%200%2000-2.432.9l-5.174-3.1a3.075%203.075%200%20000-2.184l5.409-3.227a3.689%203.689%200%20002.197.712C17.412%207.55%2019%206.079%2019%204.275%2019%202.472%2017.412%201%2015.467%201c-1.946%200-3.533%201.472-3.533%203.275%200%20.484.117.946.322%201.364L6.97%208.789a3.653%203.653%200%2000-2.438-.907C2.588%207.882%201%209.354%201%2011.158c0%201.808%201.582%203.28%203.533%203.28a3.68%203.68%200%20002.438-.907l5.168%203.09a3.011%203.011%200%2000-.205%201.104c0%201.803%201.588%203.275%203.533%203.275C17.412%2021%2019%2019.528%2019%2017.725c0-1.804-1.588-3.276-3.533-3.276zm0-11.924c1.043%200%201.892.788%201.892%201.755%200%20.967-.85%201.754-1.892%201.754-1.043%200-1.893-.787-1.893-1.754s.85-1.755%201.893-1.755zM4.533%2012.917c-1.043%200-1.892-.787-1.892-1.754s.85-1.755%201.892-1.755c1.043%200%201.893.788%201.893%201.755%200%20.967-.85%201.754-1.893%201.754zm10.934%206.557c-1.043%200-1.893-.788-1.893-1.755%200-.967.85-1.754%201.893-1.754%201.043%200%201.892.787%201.892%201.754s-.85%201.755-1.892%201.755z%22%20fill%3D%22%23fff%22%20stroke%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E);
        background-repeat: no-repeat;
        background-color: #cb11ab
    }
}

@media (hover: hover) and (pointer: fine) {
    .product-detail .btn-share:hover {
        background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2220%22%20height%3D%2222%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M15.467%2014.45a3.66%203.66%200%2000-2.432.9l-5.174-3.1a3.075%203.075%200%20000-2.184l5.409-3.227a3.689%203.689%200%20002.197.712C17.412%207.55%2019%206.079%2019%204.275%2019%202.472%2017.412%201%2015.467%201c-1.946%200-3.533%201.472-3.533%203.275%200%20.484.117.946.322%201.364L6.97%208.789a3.653%203.653%200%2000-2.438-.907C2.588%207.882%201%209.354%201%2011.158c0%201.808%201.582%203.28%203.533%203.28a3.68%203.68%200%20002.438-.907l5.168%203.09a3.011%203.011%200%2000-.205%201.104c0%201.803%201.588%203.275%203.533%203.275C17.412%2021%2019%2019.528%2019%2017.725c0-1.804-1.588-3.276-3.533-3.276zm0-11.924c1.043%200%201.892.788%201.892%201.755%200%20.967-.85%201.754-1.892%201.754-1.043%200-1.893-.787-1.893-1.754s.85-1.755%201.893-1.755zM4.533%2012.917c-1.043%200-1.892-.787-1.892-1.754s.85-1.755%201.892-1.755c1.043%200%201.893.788%201.893%201.755%200%20.967-.85%201.754-1.893%201.754zm10.934%206.557c-1.043%200-1.893-.788-1.893-1.755%200-.967.85-1.754%201.893-1.754%201.043%200%201.892.787%201.892%201.754s-.85%201.755-1.892%201.755z%22%20fill%3D%22%23fff%22%20stroke%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E);
        background-repeat: no-repeat;
        background-color: #cb11ab
    }
}

.product-detail .badge-abroad, .product-detail .badge-express {
    position: absolute;
    top: 12px;
    left: 12px;
    z-index: 1
}

.product-detail .badge-abroad-sm, .product-detail .badge-express-sm {
    position: absolute;
    top: 52px;
    left: 16px;
    z-index: 2
}

@media (min-width: 1024px) {
    .product-detail .badge-abroad-sm, .product-detail .badge-express-sm {
        display: none
    }
}

.product-detail .product-detail__top-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

@media (min-width: 1024px) {
    .product-detail .product-detail__top-wrap {
        margin-bottom: 8px
    }
}

.product-detail .product-detail__breadcrumbs {
    width: calc(100% - 28px - 8px)
}

@media (min-width: 1024px) {
    .product-detail .product-detail__breadcrumbs {
        width: calc(100% - 40px - 24px)
    }
}

.product-detail .product-detail__share {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-left: 8px
}

@media (min-width: 1024px) {
    .product-detail .product-detail__share {
        margin-left: 24px
    }
}

@media (min-width: 1024px) {
    .product-detail .product-detail__same-part-kt {
        margin-bottom: 72px;
        padding: 0
    }
}

@media (min-width: 1024px) {
    .product-detail .product-detail__section-wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-item-align: start;
        align-self: flex-start
    }
}

@media (min-width: 1024px) {
    .product-detail .product-detail__details-wrap {
        width: calc(100% - 400px);
        padding: 0 72px 0 0
    }
}

@media (min-width: 1200px) {
    .product-detail .product-detail__details-wrap {
        width: calc(100% - 600px)
    }
}

@media (max-width: 1023.98px) {
    .product-detail .product-detail__details-wrap {
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #e8e8e8
    }
}

.product-detail .product-detail__details {
    margin-bottom: 16px
}

@media (min-width: 1024px) {
    .product-detail .product-detail__details {
        margin-bottom: 40px
    }
}

.product-detail .product-detail__details:last-of-type {
    margin: 0
}

@media (max-width: 1023.98px) {
    .product-detail .product-detail__other-offers:not(:empty) {
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #d5d5d5
    }
}

@media (min-width: 1024px) {
    .product-detail .product-detail__short-wrap {
        width: 400px;
        margin-bottom: -72px
    }
}

@media (min-width: 1200px) {
    .product-detail .product-detail__short-wrap {
        width: 600px
    }
}

.product-detail .product-detail__short-slider .goods-slider {
    margin-top: 0;
    margin-bottom: 32px
}

@media (min-width: 1024px) {
    .product-detail .product-detail__short-slider .goods-slider {
        margin-bottom: 72px
    }
}

.product-detail .product-detail__search-tags {
    margin: 32px 0 0
}

@media (min-width: 1024px) {
    .product-detail .product-detail__search-tags {
        margin-top: 72px
    }
}

.product-detail .product-detail__search-tags:empty {
    display: none
}

.product-detail .product-detail__goods-slider {
    margin: 32px 0 0
}

@media (min-width: 1024px) {
    .product-detail .product-detail__goods-slider {
        margin-top: 72px
    }
}

.product-detail .product-detail__video-review {
    margin: 32px 0 0
}

@media (min-width: 1024px) {
    .product-detail .product-detail__video-review {
        margin-top: 72px
    }
}

@media (min-width: 1024px) {
    .product-detail .product-detail__user-activity {
        margin-top: 72px
    }
}

@media (max-width: 1023.98px) {
    .product-detail .product-detail__user-activity {
        margin-top: 16px;
        padding-top: 16px;
        border-top: 1px solid #e8e8e8
    }
}

.product-detail .product-detail__description-error {
    margin: 32px 0 0
}

@media (min-width: 1024px) {
    .product-detail .product-detail__description-error {
        margin-top: 16px
    }
}

.for-adults.product-detail .feedback__photo *, .for-adults.product-detail .goods-slider .goods-card__img-wrap *, .for-adults.product-detail .sw-slider-user-images .swiper-slide * {
    -webkit-filter: none;
    filter: none
}

.for-adults.product-detail .feedback__photo:before, .for-adults.product-detail .goods-slider .goods-card__img-wrap:before, .for-adults.product-detail .sw-slider-user-images .swiper-slide:before {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAQEBAQEBAQEBAQGBgUGBggHBwcHCAwJCQkJCQwTDA4MDA4MExEUEA8QFBEeFxUVFx4iHRsdIiolJSo0MjRERFwBBAQEBAQEBAQEBAYGBQYGCAcHBwcIDAkJCQkJDBMMDgwMDgwTERQQDxAUER4XFRUXHiIdGx0iKiUlKjQyNEREXP/CABEIAVAA/AMBIgACEQEDEQH/xAAYAAEBAQEBAAAAAAAAAAAAAAABAAIDCP/aAAgBAQAAAAD36AkRUVDEjTU7CiKKIqqqljVqKIoKKqqqmZ1FBFEFVVNSzbCIgKKKqmaVdWYAoIiqpmWV1ZCAgiqGqVlbUBGaAiqpq1LadAAZiKCqalVtOgMkBBETUsqq6AyFkgiiadTaVdGQAACKqpdS6VQAMgEVFSqurSoGQyEEUTLOrWp1rJkDIBBJTKrp06TBmyZCzRTTLp060pnJkyBZihZl06dbbOQyZDMRE0q60u9aMmchnNmAqZtNt062hjNkyZAipZ1O3TrdZznIZMkBUtp1bdOtuc5M5M5CIq1OrTrWl3rGc5DOAIqpVV1rTrWsYMBnIFA06tK61p1rXPOMmTMBQzal1b061p5Gc5DERQ06lda0u9XIznMZAolltTrWtOtXLOTJZCKlpVdatunXDJZzGYqmpVdadLp4mTJAVEzKruXWl5ZMhGYqpmXTpXTp4AGSIqppV1OtK645AAKGqtStq06V4gQZKqplZbTqdPECAKqpmW1blV4xmIKGpplXUuq5QBRVDTNaWW03MzFFFVMysq1q5BFFVVSzKy0vKzRVVVSzSy0vMKIkqmmZlmV5ERVVTU00szP/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAIAQIQAAAAAAAAAAAAAsAAAsAAqWAApAAoCClCCFlqKSANILCClSkgGgIiwUVJUBQEACpZYAAABYAAAAAAAAAP/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/9oACAEDEAAAAFoAAAsACiiIFoAyaCghFKoJAWiwMlpaIjJbaAyZtWgJJNLSkJGdLSoMpNNAEjK20CRJNVVJBlLoAMmdLQSCTRQQiLQCQC0QgAACT//EABwQAAIDAQEBAQAAAAAAAAAAAAERABAgMECBUP/aAAgBAQABPwD8t4dun1O3Tp+5xxxx5cdOPxOOOOOnT8zsx2447cdOPznLy8C3Hox7cccenBl6eXHHTw8PAjsUNvBt5dOnsW+Lp048vD08PR7O3hwc3ydvDp4ejRNvg44+Yn3Zp9wcODmcPL4ixo2cvk8DIw4bMOD2Ghgw0YcvsLFDm/GMHie40NHutDIwcHxjmaPjGRg4O1wFqDAwYaMMOT1EEVivlGHB5rKoCDZh6KKLiIoqWDyUUWFS7HqosKhYGRFg2vGNjiosLSiipUuq5KlFSiiwIOah0uisWqUVq1wXBQWNHCwYootKK1Fag6K1gYUXNbVrovMoooqVLa2tKKK1tWqUVLkuStcF4V1XH//EAB0RAAEDBQEAAAAAAAAAAAAAABEBIEAAECEwYFD/2gAIAQIBAT8A9YcMIIYIwYtZeks7ToW5o3MFeV//xAAbEQACAgMBAAAAAAAAAAAAAAABEQAwIEBQEP/aAAgBAwEBPwCDXXPUUVa4y5g0BQbTmKzk98WPJxx5O52vx6r8/9k=) center/cover no-repeat;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000px;
    -webkit-transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
    transform: translate3d(0, 0, 0)
}

.for-adults.product-detail .goods-slider .goods-card__img-wrap img {
    z-index: 0
}

.for-adults.product-detail .goods-slider .goods-card__label-adults {
    display: block;
    -webkit-filter: none;
    filter: none;
    z-index: 2
}

.for-adults.product-detail .goods-slider .goods-card__discount, .for-adults.product-detail .goods-slider .goods-card__preview-btn {
    display: none
}

@media (min-width: 1024px) {
    .product-detail .btn-share {
        width: 40px;
        height: 40px
    }
}

.product-detail .same-part-kt__slider-wrap {
    margin: -16px 0 12px
}

@media (min-width: 576px) {
    .product-detail .same-part-kt__slider-wrap {
        margin: 0 0 12px
    }
}

@media (min-width: 1024px) {
    .product-detail .same-part-kt__slider-wrap {
        width: 44%;
        margin: 0
    }
}

@media (min-width: 1024px) {
    .product-detail .same-part-kt__sticky-wrap {
        position: relative
    }

    @supports ((position:-webkit-sticky) or (position:sticky)) {
        .product-detail .same-part-kt__sticky-wrap {
            position: -webkit-sticky;
            position: sticky;
            top: 50px
        }
    }.product-detail .same-part-kt__sticky-wrap.doFixed {
         position: fixed;
         top: 50px
     }

    .product-detail .same-part-kt__sticky-wrap.doFixedBottom {
        position: absolute;
        bottom: 0
    }
}

@media (max-width: 1023.98px) {
    .product-detail .same-part-kt__header-wrap {
        margin: 16px 0;
        padding: 16px 0;
        border-top: 1px solid #e8e8e8;
        border-bottom: 1px solid #e8e8e8
    }
}

.product-detail .same-part-kt__brand-logo {
    margin: 0 0 0 12px
}

@media (min-width: 1024px) {
    .product-detail .same-part-kt__brand-logo {
        margin: 40px 0
    }
}

.product-detail .same-part-kt__common-info {
    margin-top: 8px
}

@media (min-width: 1024px) {
    .product-detail .same-part-kt__common-info {
        margin: 20px 0 44px
    }
}

@media (min-width: 1024px) {
    .product-detail .same-part-kt__info-wrap {
        width: 56%;
        padding-left: 20px
    }
}

@media (min-width: 1200px) {
    .product-detail .same-part-kt__info-wrap {
        padding-left: 72px
    }
}

.product-detail .same-part-kt__booking {
    background-color: #f6f6f6;
    border-radius: 8px;
    padding: 8px;
    margin-top: 12px
}

@media (min-width: 1024px) {
    .product-detail .same-part-kt__booking {
        display: inline-block;
        vertical-align: middle;
        padding: 12px 16px;
        margin-top: 0
    }
}

.product-detail .same-part-kt__booking-desc {
    font-size: 12px;
    line-height: 16px;
    display: block;
    margin: 0 0 8px
}

@media (min-width: 1024px) {
    .product-detail .same-part-kt__booking-desc {
        font-size: 14px;
        line-height: 20px;
        margin: 0 0 12px
    }
}

.product-detail .same-part-kt__booking-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.product-detail .same-part-kt__booking-content ~ .same-part-kt__booking-content {
    margin: 8px 0 0
}

@media (min-width: 1024px) {
    .product-detail .same-part-kt__booking-content ~ .same-part-kt__booking-content {
        margin: 12px 0 0
    }
}

.product-detail .same-part-kt__booking-text {
    font-size: 12px;
    line-height: 16px;
    color: #8b8b8b;
    margin-right: 4px
}

@media (min-width: 1024px) {
    .product-detail .same-part-kt__booking-text {
        font-size: 14px;
        line-height: 20px
    }
}

.product-detail .same-part-kt__booking-link {
    -webkit-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
    border-bottom: 1px dashed;
    font-size: 16px;
    line-height: 22px;
    color: #000;
    border-color: initial;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 12px;
    line-height: 16px
}

.product-detail .same-part-kt__booking-link:hover {
    color: #cb11ab;
    border-color: initial
}

.product-detail .same-part-kt__booking-link:hover {
    color: #000;
    border-color: transparent
}

@media (min-width: 1024px) {
    .product-detail .same-part-kt__booking-link {
        font-size: 14px;
        line-height: 20px
    }
}

@media (min-width: 768px) {
    .product-detail .spec-action {
        width: 100%
    }
}

@media (max-width: 1023.98px) {
    .product-detail .spec-action {
        width: 100%;
        margin-top: 12px
    }
}

.product-detail .same-part-kt__price-block .price-block {
    margin-bottom: 16px
}

@media (min-width: 1024px) {
    .product-detail .same-part-kt__price-block .price-block {
        margin-bottom: 20px
    }
}

@media (min-width: 1024px) and (max-width: 1280.98px) {
    .product-detail .colors-tones__list {
        width: 520px
    }
}

@media (min-width: 1281px) and (max-width: 1360.98px) {
    .product-detail .colors-tones__list {
        width: calc(520px + 52px)
    }
}

@media (min-width: 1361px) and (max-width: 1450.98px) {
    .product-detail .colors-tones__list {
        width: calc(520px + 2 * 52px)
    }
}

@media (min-width: 1451px) {
    .product-detail .colors-tones__list {
        width: calc(520px + 3 * 52px)
    }
}

@media (min-width: 1024px) and (max-width: 1280.98px) {
    .product-detail .colors-tones__item:nth-child(20):before {
        content: attr(data-reminder)
    }
}

@media (min-width: 1281px) and (max-width: 1360.98px) {
    .product-detail .colors-tones__item:nth-child(22):before {
        content: attr(data-reminder)
    }
}

@media (min-width: 1361px) and (max-width: 1450.98px) {
    .product-detail .colors-tones__item:nth-child(24):before {
        content: attr(data-reminder)
    }
}

@media (min-width: 1451px) {
    .product-detail .colors-tones__item:nth-child(26):before {
        content: attr(data-reminder)
    }
}

.product-detail .details__header-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline
}

@media (max-width: 1023.98px) {
    .product-detail .details__header {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 4px
    }
}

.product-detail .details__link-wrap {
    text-align: right;
    margin-left: 16px
}

.product-detail .details__content {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 16px
}

@media (min-width: 768px) {
    .product-detail .details__content {
        margin-bottom: 20px
    }
}

@media (min-width: 1024px) {
    .product-detail .details__content {
        margin-bottom: 40px;
        font-size: 16px;
        line-height: 22px
    }
}

.product-detail .details__content:last-of-type {
    margin-bottom: 0
}

.product-detail .details__link {
    border-bottom: 1px dashed;
    font-size: 16px;
    line-height: 22px;
    color: #000;
    border-color: initial;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
    color: #cb11ab;
    border-color: rgba(203, 17, 171, .2)
}

.product-detail .details__link:hover {
    color: #cb11ab;
    border-color: initial
}

.product-detail .details__link:hover {
    color: #cb11ab;
    border-color: #cb11ab
}

@media (max-width: 1023.98px) {
    .product-detail .details__link {
        font-size: 14px;
        line-height: 20px
    }
}

@media (min-width: 1024px) {
    .product-detail .details__link {
        font-weight: 600
    }
}

.product-detail .product-params__table {
    font-size: 14px;
    line-height: 20px;
    width: 100%
}

@media (min-width: 1024px) {
    .product-detail .product-params__table {
        font-size: 16px;
        line-height: 22px
    }
}

.product-detail .product-params__table ~ .product-params__table {
    margin-top: 12px
}

@media (min-width: 1024px) {
    .product-detail .product-params__table ~ .product-params__table {
        margin-top: 22px
    }
}

.product-detail .product-params__caption {
    font-weight: 700;
    text-align: left;
    margin-bottom: 4px
}

@media (min-width: 1024px) {
    .product-detail .product-params__caption {
        margin-bottom: 10px
    }
}

.product-detail .product-params__table th {
    width: 50%;
    color: #8b8b8b;
    font-weight: 400;
    text-align: left;
    vertical-align: top;
    padding: 4px 0
}

@media (min-width: 1024px) {
    .product-detail .product-params__table th {
        width: 61%;
        padding: 6px 0
    }
}

.product-detail .product-params__cell-decor {
    position: relative;
    display: inline-block;
    vertical-align: bottom;
    width: 100%
}

.product-detail .product-params__cell-decor::after {
    content: "";
    display: block;
    width: 100%;
    height: 20px;
    position: absolute;
    left: 0;
    bottom: 5px;
    border-bottom: 1px dashed #ccc
}

.product-detail .product-params__cell-decor span {
    padding-right: 12px;
    position: relative;
    z-index: 1;
    background-color: #fff
}

.product-detail .product-params__table td {
    width: 50%;
    text-align: left;
    vertical-align: bottom;
    padding: 4px 0 4px 12px
}

@media (min-width: 1024px) {
    .product-detail .product-params__table td {
        width: 39%;
        padding: 6px 0 6px 12px
    }
}

@media (min-width: 1024px) {
    .product-detail .description-error {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start
    }
}

.product-detail .description-error__text {
    font-size: 12px;
    line-height: 16px;
    color: #8b8b8b
}

@media (min-width: 1024px) {
    .product-detail .description-error__text {
        font-size: 14px;
        line-height: 20px;
        width: 61%
    }
}

.product-detail .description-error__btn {
    font-size: 14px;
    line-height: 20px;
    color: #8b8b8b;
    text-align: left;
    margin: 12px 0 0
}

@media (min-width: 1024px) {
    .product-detail .description-error__btn {
        max-width: 145px;
        margin: 0 0 0 12px
    }
}

.product-detail .description-error__btn:hover {
    color: #000
}

.product-detail .description-error__btn span {
    border-bottom: 1px dashed #8b8b8b
}

.product-detail .user-activity__header-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.product-detail .user-activity__menu {
    position: relative;
    width: 24px;
    height: 24px;
    padding: 0
}

.product-detail .user-activity__menu::before {
    content: "";
    display: block;
    width: 4px;
    height: 4px;
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    border-radius: 50%;
    background-color: #8b8b8b;
    -webkit-box-shadow: 0 -8px #8b8b8b, 0 8px #8b8b8b;
    box-shadow: 0 -8px #8b8b8b, 0 8px #8b8b8b
}

.product-detail .user-activity__tabs-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.product-detail .user-activity__rules {
    border-bottom: 1px dashed;
    font-size: 16px;
    line-height: 22px;
    color: #000;
    border-color: initial;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
    color: #8b8b8b;
    border-color: #8b8b8b;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-negative: 0;
    flex-shrink: 0
}

.product-detail .user-activity__rules:hover {
    color: #cb11ab;
    border-color: initial
}

.product-detail .user-activity__rules:hover {
    color: #000;
    border-color: #000
}

.product-detail .user-activity__tabs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.product-detail .user-activity__tab {
    margin-right: 20px
}

@media (min-width: 1024px) {
    .product-detail .user-activity__tab {
        margin-right: 32px
    }
}

.product-detail .user-activity__tab a {
    font-size: 14px;
    line-height: 20px;
    position: relative;
    display: block;
    color: #8b8b8b;
    padding-bottom: 4px;
    -webkit-transition: color .3s ease 0s;
    transition: color .3s ease 0s
}

@media (min-width: 1024px) {
    .product-detail .user-activity__tab a {
        font-size: 16px;
        line-height: 22px
    }
}

.product-detail .user-activity__tab a::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    height: 2px;
    width: 100%;
    background-color: #cb11ab;
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
    -webkit-transition: -webkit-transform .3s ease 0s;
    transition: -webkit-transform .3s ease 0s;
    transition: transform .3s ease 0s;
    transition: transform .3s ease 0s, -webkit-transform .3s ease 0s
}

.product-detail .user-activity__tab.active a, .product-detail .user-activity__tab:hover a {
    color: #000
}

.product-detail .user-activity__tab.active a::after, .product-detail .user-activity__tab:hover a::after {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1)
}

.product-detail .user-activity__tab.active a {
    cursor: default
}

.product-detail .user-activity__content {
    margin-top: 20px
}

@media (min-width: 1024px) {
    .product-detail .user-activity__content {
        margin-top: 36px
    }
}

.product-detail .alcohol-warning__text {
    font-size: 12px;
    line-height: 16px;
    color: #8b8b8b;
    margin: 0 0 12px;
    max-width: 420px
}

@media (min-width: 1024px) {
    .product-detail .alcohol-warning__text {
        font-size: 14px;
        line-height: 20px
    }
}

.product-detail .alcohol-warning__text a {
    font-size: 16px;
    line-height: 22px;
    color: #000;
    border-color: initial;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
    color: #cb11ab;
    border-color: transparent;
    font-size: 12px;
    line-height: 16px
}

.product-detail .alcohol-warning__text a:hover {
    color: #cb11ab;
    border-color: initial
}

.product-detail .alcohol-warning__text a:hover {
    color: #000;
    border-color: transparent
}

@media (min-width: 1024px) {
    .product-detail .alcohol-warning__text a {
        font-size: 14px;
        line-height: 20px
    }
}

.product-detail .warning-info-alcohol {
    font-size: 12px;
    line-height: 16px;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px
}

@media (min-width: 1024px) {
    .product-detail .warning-info-alcohol {
        font-size: 16px;
        line-height: 22px
    }
}

.product-detail .warning-info-alcohol::before {
    font-size: 10px;
    line-height: 13px;
    text-transform: uppercase;
    content: '18+';
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 22px;
    height: 22px;
    border: 1px solid #cb11ab;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: -3px;
    color: #cb11ab;
    font-weight: 400
}

@media (min-width: 1024px) {
    .product-detail .warning-info-alcohol::before {
        top: -1px
    }
}

@media (min-width: 1024px) {
    .product-detail .warning-info-alcohol {
        font-size: 14px;
        line-height: 20px
    }
}

.product-detail .goods-slider__header-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline
}

@media (max-width: 1023.98px) {
    .product-detail .goods-slider__header-wrap {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between
    }
}

.product-detail .goods-slider__header {
    margin-right: 8px
}

@media (min-width: 1024px) {
    .product-detail .goods-slider__header {
        margin-right: 20px
    }
}

.product-detail .goods-slider__see-all {
    border-bottom: 1px solid;
    color: #000;
    border-color: initial;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
    color: #cb11ab;
    border-color: transparent;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    -ms-flex-negative: 0;
    flex-shrink: 0
}

.product-detail .goods-slider__see-all:hover {
    color: #cb11ab;
    border-color: initial
}

.product-detail .goods-slider__see-all:hover {
    color: #cb11ab;
    border-color: #cb11ab
}

@media (max-width: 1023.98px) {
    .product-detail .goods-slider__see-all {
        font-size: 14px;
        line-height: 20px
    }
}

.product-detail .price-history {
    margin-bottom: 32px
}

@media (min-width: 1024px) {
    .product-detail .price-history {
        margin-bottom: 72px
    }
}

.product-detail .price-history__text {
    font-size: 14px;
    line-height: 20px;
    color: #8b8b8b;
    margin-bottom: 8px
}

@media (min-width: 1024px) {
    .product-detail .price-history__text {
        font-size: 16px;
        line-height: 22px;
        color: #000;
        margin-bottom: 16px
    }
}

.product-detail .price-history__date {
    font-size: 14px;
    line-height: 20px;
    color: #8b8b8b;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

@media (max-width: 1023.98px) {
    .product-detail .price-history__title {
        padding-right: 20px;
        position: relative;
        cursor: pointer
    }
}

@media (max-width: 1023.98px) {
    .product-detail .price-history__dropdown-icon {
        width: 16px;
        height: 16px;
        position: absolute;
        top: calc(50% - 8px);
        right: 0
    }

    .product-detail .price-history__dropdown-icon::after, .product-detail .price-history__dropdown-icon::before {
        content: '';
        display: block;
        width: 16px;
        height: 4px;
        position: absolute;
        top: calc(50% - 1px);
        left: calc(50% - 8px);
        background: #000
    }

    .product-detail .price-history__dropdown-icon::before {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        -webkit-transition: -webkit-transform .3s ease 0s;
        transition: -webkit-transform .3s ease 0s;
        transition: transform .3s ease 0s;
        transition: transform .3s ease 0s, -webkit-transform .3s ease 0s
    }
}

@media (max-width: 1023.98px) {
    .product-detail .price-history__dropdown {
        max-height: 0;
        overflow: hidden;
        margin: 0 -16px;
        padding: 0 16px;
        -webkit-transition: max-height .3s cubic-bezier(0, 1, .5, 1) 0s;
        transition: max-height .3s cubic-bezier(0, 1, .5, 1) 0s
    }
}

@media (max-width: 1023.98px) {
    .product-detail .dropdown-open .price-history__dropdown-icon::before {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
}

.product-detail .dropdown-open .price-history__dropdown {
    max-height: 3000px;
    -webkit-transition: max-height .3s cubic-bezier(1, 0, 1, .5) 0s;
    transition: max-height .3s cubic-bezier(1, 0, 1, .5) 0s
}

.product-detail .history-chart {
    cursor: pointer;
    overflow: visible;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.product-detail .history-chart__circle {
    r: 16;
}

@media (min-width: 576px) {
    .product-detail .history-chart__circle {
        r: 12;
    }
}

@media (min-width: 768px) {
    .product-detail .history-chart__circle {
        r: 8;
    }
}

@media (min-width: 1024px) {
    .product-detail .history-chart__circle {
        r: 12;
    }
}

@media (min-width: 1200px) {
    .product-detail .history-chart__circle {
        r: 8;
    }
}