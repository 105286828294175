.gldp {
    position: absolute;
    margin-top: 5px;
    margin-left: -6px;
    font-size: 12px
}

.gldp .has-goods {
    position: relative
}

.gldp .has-goods:after, .gldp .has-goods:before {
    border-style: solid;
    border-width: 0 8px 8px 0;
    border-color: transparent #fff transparent transparent;
    content: '';
    display: block;
    height: 0;
    position: absolute;
    top: 0;
    right: 0;
    width: 0
}

.gldp .has-goods:before {
    border-width: 0 7px 7px 0;
    border-color: transparent #824cac transparent transparent;
    z-index: 1
}

.gldp .goods-on-date {
    position: relative;
    z-index: 3;
    float: left
}

.gldp .goods-on-date p {
    color: #767676;
    margin: 11px 0 11px 10px
}

.gldp .goods-on-date span {
    color: #272727;
    display: block;
    margin: 11px 0 11px 10px
}

.gldp .goods-on-date .selected {
    background: 0 0;
    color: #ff48a5
}

.gldp:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: -2px;
    right: 4px;
    bottom: -3px;
    left: -2px;
    background: #fff;
    border: 1px solid #e2e4ea
}

.gldp .core {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    z-index: 999;
    float: left;
    padding: 0;
    margin: 0;
    text-align: center;
    text-indent: -1px;
    outline: 1px solid #fff
}

.gldp .border {
    border: 0 solid #fff
}

.gldp .monyear, .gldp .monyear select {
    font-weight: 700
}

.gldp .title {
    padding-top: 2px
}

.gldp .monyear span {
    margin: 0 3px 0 3px;
    cursor: pointer;
    text-transform: capitalize
}

.gldp .next-arrow, .gldp .next-arrow-off, .gldp .prev-arrow, .gldp .prev-arrow-off {
    display: inline-block;
    vertical-align: middle;
    height: 15px;
    width: 9px;
    font-size: 1px;
    color: transparent
}

.gldp .next-arrow, .gldp .prev-arrow {
    background-color: #8d2fa6;
    cursor: pointer
}

.gldp .next-arrow-off, .gldp .prev-arrow-off {
    background-color: #d8d8d8;
    display: none
}

.gldp .next-arrow, .gldp .next-arrow-off {
    background-position: -190px -53px
}

.gldp .next-arrow:active, .gldp .prev-arrow:active {
    margin-top: 1px
}

.gldp .dow {
    font-size: 11px;
    text-transform: uppercase
}

.gldp .fri, .gldp .mon, .gldp .thu, .gldp .tue, .gldp .wed {
    background: #eaeaea;
    cursor: pointer
}

.gldp .sat, .gldp .sun {
    background: #fce7f5;
    color: #ed008e;
    cursor: pointer
}

.gldp .outday {
    color: #666
}

.gldp .fri:hover, .gldp .mon:hover, .gldp .sat:hover, .gldp .sun:hover, .gldp .thu:hover, .gldp .tue:hover, .gldp .wed:hover {
    background: #ed008e;
    color: #fff;
    border-color: #ed008e
}

.gldp .noday {
    background: #f9f8fa;
    color: #c7c6c8
}

.gldp .today {
    border-color: #ed008e;
    border-width: 1px 3px 3px 1px !important;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.gldp .selected {
    background: #ed008e;
    color: #fff
}

.popup-requisites {
    font-size: 16px;
    line-height: 22px;
    color: #8b8b8b;
    display: none;
    width: 100%;
    max-width: 100%;
    min-width: 320px;
    padding: 12px 16px 16px;
    position: fixed;
    z-index: 1001;
    background: #fff;
    border-radius: 8px 8px 0 0;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2)
}

@media (min-width: 1024px) {
    .popup-requisites {
        width: calc(100% - 32px);
        max-width: 964px;
        padding: 28px 40px 40px;
        border-radius: 8px;
        position: absolute
    }
}

@media (max-width: 1023.98px) {
    .popup-requisites {
        top: auto !important;
        left: 0 !important;
        bottom: 0;
        max-height: 100%
    }
}

.popup-requisites .close, .popup-requisites .popup__close {
    width: 26px;
    height: 26px;
    display: inline-block;
    position: relative;
    font-size: 0;
    line-height: 1;
    border: none;
    background-color: transparent;
    position: absolute;
    z-index: 1;
    top: 13px;
    right: 12px;
    cursor: pointer
}

.popup-requisites .close:after, .popup-requisites .close:before, .popup-requisites .popup__close:after, .popup-requisites .popup__close:before {
    -webkit-transition: background .3s ease 0s;
    transition: background .3s ease 0s;
    background-color: #999;
    border-radius: 2px;
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg)
}

.popup-requisites .close:before, .popup-requisites .popup__close:before {
    width: 2px;
    height: 20px
}

.popup-requisites .close:after, .popup-requisites .popup__close:after {
    width: 20px;
    height: 2px
}

@media (min-width: 1024px) {
    .popup-requisites .close, .popup-requisites .popup__close {
        top: 8px;
        right: 8px
    }
}

@media (max-width: 575.98px) {
    .popup-requisites .close ~ .popup__content, .popup-requisites .popup__close ~ .popup__content {
        padding-top: 28px
    }
}

@media (max-width: 575.98px) {
    .popup-requisites .close ~ .popup__content .popup__header, .popup-requisites .popup__close ~ .popup__content .popup__header {
        margin-top: -28px
    }
}

.popup-requisites .popup__close-btn {
    font-size: 16px;
    line-height: 22px;
    position: absolute;
    z-index: 1;
    top: 16px;
    right: 16px;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    padding: 4px 12px 6px;
    background-color: #fff;
    border-radius: 24px;
    border: none;
    cursor: pointer
}

@media (max-width: 1023.98px) {
    .popup-requisites .popup__close-btn {
        width: 26px;
        height: 26px;
        display: inline-block;
        position: relative;
        font-size: 0;
        line-height: 1;
        border: none;
        background-color: transparent;
        position: absolute;
        z-index: 1;
        top: 13px;
        right: 12px;
        cursor: pointer;
        line-height: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        padding: 0;
        border-radius: 0
    }

    .popup-requisites .popup__close-btn:after, .popup-requisites .popup__close-btn:before {
        -webkit-transition: background .3s ease 0s;
        transition: background .3s ease 0s;
        background-color: #999;
        border-radius: 2px;
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
        transform: translate(-50%, -50%) rotate(45deg)
    }

    .popup-requisites .popup__close-btn:before {
        width: 2px;
        height: 20px
    }

    .popup-requisites .popup__close-btn:after {
        width: 20px;
        height: 2px
    }
}

@media (max-width: 1023.98px) and (min-width: 1024px) {
    .popup-requisites .popup__close-btn {
        top: 8px;
        right: 8px
    }
}

@media (max-width: 1023.98px) and (max-width: 575.98px) {
    .popup-requisites .popup__close-btn ~ .popup__content {
        padding-top: 28px
    }
}

@media (max-width: 1023.98px) and (max-width: 575.98px) {
    .popup-requisites .popup__close-btn ~ .popup__content .popup__header {
        margin-top: -28px
    }
}

.popup-requisites .popup__header {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: #000;
    margin: 0 0 16px;
    padding-right: 24px
}

@media (min-width: 768px) {
    .popup-requisites .popup__header {
        font-size: 20px;
        line-height: 28px
    }
}

@media (min-width: 1024px) {
    .popup-requisites .popup__header {
        font-size: 28px;
        line-height: 36px
    }
}

@media (min-width: 1024px) {
    .popup-requisites .popup__header {
        margin: 0 0 20px;
        padding-right: 0
    }
}

.popup-requisites .popup__btn {
    margin: 10px 0 0;
    font-size: 0
}

@media (min-width: 576px) {
    .popup-requisites .popup__btn {
        margin: 20px 0 0
    }
}

.popup-requisites .popup__btn-main {
    font-family: system, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Segoe WP', Roboto, Ubuntu, Oxygen, Cantarell, 'Fira Sans', 'Helvetica Neue', Helvetica, 'Lucida Grande', 'Droid Sans', Tahoma, 'Microsoft Sans Serif', sans-serif;
    line-height: 1.1;
    letter-spacing: 0;
    font-weight: 700;
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    background-color: #cb11ab;
    border: 2px solid #cb11ab;
    font-size: 16px;
    padding: 11px 25px 12px;
    margin-top: 12px;
    margin-right: 20px
}

.popup-requisites .popup__btn-main.disabled, .popup-requisites .popup__btn-main.disabled:active, .popup-requisites .popup__btn-main.disabled:hover, .popup-requisites .popup__btn-main:disabled, .popup-requisites .popup__btn-main:disabled:active, .popup-requisites .popup__btn-main:disabled:hover {
    cursor: default
}

.popup-requisites .popup__btn-main.disabled:active:not(.progress), .popup-requisites .popup__btn-main.disabled:hover:not(.progress), .popup-requisites .popup__btn-main.disabled:not(.progress), .popup-requisites .popup__btn-main:disabled:active:not(.progress), .popup-requisites .popup__btn-main:disabled:hover:not(.progress), .popup-requisites .popup__btn-main:disabled:not(.progress) {
    color: #d7d7d7;
    background-color: #fff;
    border: 2px solid #d5d5d5;
    opacity: .9
}

.popup-requisites .popup__btn-main.disabled:active:not(.progress):active, .popup-requisites .popup__btn-main.disabled:hover:not(.progress):active, .popup-requisites .popup__btn-main.disabled:not(.progress):active, .popup-requisites .popup__btn-main:disabled:active:not(.progress):active, .popup-requisites .popup__btn-main:disabled:hover:not(.progress):active, .popup-requisites .popup__btn-main:disabled:not(.progress):active {
    -webkit-transform: none;
    transform: none
}

.popup-requisites .popup__btn-main.progress, .popup-requisites .popup__btn-main.progress:active {
    color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

@-webkit-keyframes mySpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes mySpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.popup-requisites .popup__btn-main.progress:active:after, .popup-requisites .popup__btn-main.progress:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 20px / 2);
    right: calc(50% - 20px / 2);
    -webkit-animation: mySpin .9s linear 0s infinite;
    animation: mySpin .9s linear 0s infinite
}

.popup-requisites .popup__btn-main.progress:active:after, .popup-requisites .popup__btn-main.progress:after {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%208A7.999%207.999%200%201%201%200%208h2.002a5.998%205.998%200%201%200%2011.996%200H16z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
    .popup-requisites .popup__btn-main:hover {
        background-color: #e313bf;
        border-color: #e313bf
    }

    .popup-requisites .popup__btn-main:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (hover: hover) and (pointer: fine) {
    .popup-requisites .popup__btn-main:hover {
        background-color: #e313bf;
        border-color: #e313bf
    }

    .popup-requisites .popup__btn-main:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (max-width: 575.98px) {
    .popup-requisites .popup__btn-main {
        margin-right: 15px
    }
}

.popup-requisites .popup__btn-base {
    font-family: system, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Segoe WP', Roboto, Ubuntu, Oxygen, Cantarell, 'Fira Sans', 'Helvetica Neue', Helvetica, 'Lucida Grande', 'Droid Sans', Tahoma, 'Microsoft Sans Serif', sans-serif;
    line-height: 1.1;
    letter-spacing: 0;
    font-weight: 700;
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    color: #cb11ab;
    font-size: 16px;
    padding: 11px 25px 12px;
    margin-top: 12px
}

.popup-requisites .popup__btn-base.disabled, .popup-requisites .popup__btn-base.disabled:active, .popup-requisites .popup__btn-base.disabled:hover, .popup-requisites .popup__btn-base:disabled, .popup-requisites .popup__btn-base:disabled:active, .popup-requisites .popup__btn-base:disabled:hover {
    cursor: default
}

.popup-requisites .popup__btn-base.disabled:active:not(.progress), .popup-requisites .popup__btn-base.disabled:hover:not(.progress), .popup-requisites .popup__btn-base.disabled:not(.progress), .popup-requisites .popup__btn-base:disabled:active:not(.progress), .popup-requisites .popup__btn-base:disabled:hover:not(.progress), .popup-requisites .popup__btn-base:disabled:not(.progress) {
    color: #d7d7d7;
    background-color: #fff;
    border: 2px solid #d5d5d5;
    opacity: .9
}

.popup-requisites .popup__btn-base.disabled:active:not(.progress):active, .popup-requisites .popup__btn-base.disabled:hover:not(.progress):active, .popup-requisites .popup__btn-base.disabled:not(.progress):active, .popup-requisites .popup__btn-base:disabled:active:not(.progress):active, .popup-requisites .popup__btn-base:disabled:hover:not(.progress):active, .popup-requisites .popup__btn-base:disabled:not(.progress):active {
    -webkit-transform: none;
    transform: none
}

.popup-requisites .popup__btn-base.progress, .popup-requisites .popup__btn-base.progress:active {
    color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

@keyframes mySpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.popup-requisites .popup__btn-base.progress:active:after, .popup-requisites .popup__btn-base.progress:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 20px / 2);
    right: calc(50% - 20px / 2);
    -webkit-animation: mySpin .9s linear 0s infinite;
    animation: mySpin .9s linear 0s infinite
}

.popup-requisites .popup__btn-base.progress:active:after, .popup-requisites .popup__btn-base.progress:after {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%208A7.999%207.999%200%201%201%200%208h2.002a5.998%205.998%200%201%200%2011.996%200H16z%22%20fill%3D%22%23CB11AB%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain
}

.popup-requisites .popup__btn-base, .popup-requisites .popup__btn-base.progress:active, .popup-requisites .popup__btn-base.progress:hover {
    background-color: #fff;
    border: 2px solid #cb11ab
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
    .popup-requisites .popup__btn-base:hover {
        background-color: #cb11ab;
        color: #fff
    }

    .popup-requisites .popup__btn-base:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (hover: hover) and (pointer: fine) {
    .popup-requisites .popup__btn-base:hover {
        background-color: #cb11ab;
        color: #fff
    }

    .popup-requisites .popup__btn-base:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (max-width: 1023.98px) {
    .popup-requisites {
        height: 100%
    }
}

@media (max-width: 767.98px) {
    .popup-requisites .popup__content {
        height: 100%;
        padding-top: 0 !important
    }
}

.popup-requisites .popup__header {
    margin-top: 0 !important
}

@media (max-width: 767.98px) {
    .popup-requisites .popup__header {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        max-height: 24px;
        overflow: hidden
    }
}

@media (max-width: 767.98px) {
    .popup-requisites form {
        height: calc(100% - 40px)
    }
}

@media (max-width: 767.98px) {
    .popup-requisites .popup__inner-wrap {
        scrollbar-width: none;
        overflow-y: auto;
        height: 100%;
        padding-left: 8px;
        margin-left: -8px
    }

    .popup-requisites .popup__inner-wrap::-webkit-scrollbar {
        display: none
    }
}

@media (min-width: 768px) {
    .popup-requisites .form-list {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: 0 -4px
    }
}

@media (min-width: 1024px) {
    .popup-requisites .form-list {
        margin: 0 -8px
    }
}

.popup-requisites .form-list:last-of-type {
    margin-top: 28px
}

@media (min-width: 1024px) {
    .popup-requisites .form-list:last-of-type {
        margin-top: 24px
    }
}

.popup-requisites .form-list__item {
    margin-bottom: 16px
}

.popup-requisites .form-list__item:last-of-type {
    margin-bottom: 0
}

@media (min-width: 768px) {
    .popup-requisites .form-list__item {
        width: calc(100% / 12 * 6 - 8px);
        margin: 0 4px 12px
    }
}

@media (min-width: 1024px) {
    .popup-requisites .form-list__item {
        width: calc(100% / 12 * 4 - 16px);
        margin: 0 8px
    }

    .popup-requisites .form-list__item:nth-of-type(n+4) {
        margin-top: 16px
    }
}

.popup-requisites .form-block__autocomplete-wrap {
    position: relative
}

.popup-requisites .form-block__label {
    max-width: 100%
}

.popup-requisites .form-block__name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%
}

.popup-requisites .field-validation-error {
    font-size: 14px;
    line-height: 20px;
    display: block;
    margin-top: 8px
}

.popup-requisites .popup__info {
    font-size: 14px;
    line-height: 20px
}

@media (min-width: 768px) {
    .popup-requisites .popup__info {
        font-size: 16px;
        line-height: 22px
    }
}

.popup-requisites .popup__info--tip {
    margin-top: 8px
}

@media (min-width: 1024px) {
    .popup-requisites .popup__info--tip {
        margin-top: 12px
    }
}

@media (min-width: 1024px) {
    .popup-requisites .popup__info--tip ~ .popup__info--tip {
        margin-top: 28px
    }
}

.popup-requisites .popup__info--bottom {
    margin-top: 16px
}

@media (min-width: 1024px) {
    .popup-requisites .popup__info--bottom {
        margin-top: 12px
    }
}

@media (min-width: 768px) {
    .popup-requisites .popup__btn {
        margin-top: 32px
    }
}

@media (max-width: 1023.98px) {
    .popup-requisites .popup__btn {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin: 20px -4px 0
    }
}

.popup-requisites .btn-base, .popup-requisites .btn-main {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-left: 10px;
    padding-right: 10px
}

@media (max-width: 1023.98px) {
    .popup-requisites .btn-base, .popup-requisites .btn-main {
        width: calc(100% / 12 * 6 - 8px);
        margin: 0 4px
    }
}

@media (min-width: 1024px) {
    .popup-requisites .btn-base, .popup-requisites .btn-main {
        min-width: 178px
    }
}

@media (min-width: 1024px) {
    .popup-requisites .btn-base {
        margin-right: 12px
    }
}

.popup-requisites .text-field.autocomplete {
    font-size: 16px;
    line-height: 22px;
    color: #000;
    margin-top: 8px
}

.popup-requisites .text-field .autocomplete__content {
    background: #fff;
    border-radius: 4px;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    padding: 12px;
    overflow: auto
}

@media (min-width: 1024px) {
    .popup-requisites .text-field .autocomplete__content {
        -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .1);
        box-shadow: 0 0 20px rgba(0, 0, 0, .1)
    }
}

.popup-requisites .text-field .autocomplete__content .selected .autocomplete__item {
    background: #f6f6f6
}

.popup-requisites .text-field .autocomplete__item {
    padding: 8px;
    border-radius: 4px;
    cursor: pointer
}

.popup-edit-name {
    font-size: 16px;
    line-height: 22px;
    color: #8b8b8b;
    display: none;
    width: 98%;
    max-width: 400px;
    min-width: 320px;
    padding: 12px 16px 16px;
    position: fixed;
    z-index: 1001;
    background: #fff;
    border-radius: 8px;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    scrollbar-width: none;
    overflow-y: auto
}

@media (min-width: 1024px) {
    .popup-edit-name {
        width: calc(100% - 32px);
        max-width: 400px;
        padding: 28px 40px 40px;
        border-radius: 8px;
        position: absolute
    }
}

@media (max-width: 1023.98px) {
    .popup-edit-name {
        left: 0 !important;
        bottom: 0;
        max-height: 100%
    }
}

.popup-edit-name .close, .popup-edit-name .popup__close {
    width: 26px;
    height: 26px;
    display: inline-block;
    position: relative;
    font-size: 0;
    line-height: 1;
    border: none;
    background-color: transparent;
    position: absolute;
    z-index: 1;
    top: 13px;
    right: 12px;
    cursor: pointer
}

.popup-edit-name .close:after, .popup-edit-name .close:before, .popup-edit-name .popup__close:after, .popup-edit-name .popup__close:before {
    -webkit-transition: background .3s ease 0s;
    transition: background .3s ease 0s;
    background-color: #999;
    border-radius: 2px;
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg)
}

.popup-edit-name .close:before, .popup-edit-name .popup__close:before {
    width: 2px;
    height: 20px
}

.popup-edit-name .close:after, .popup-edit-name .popup__close:after {
    width: 20px;
    height: 2px
}

@media (min-width: 1024px) {
    .popup-edit-name .close, .popup-edit-name .popup__close {
        top: 8px;
        right: 8px
    }
}

@media (max-width: 575.98px) {
    .popup-edit-name .close ~ .popup__content, .popup-edit-name .popup__close ~ .popup__content {
        padding-top: 28px
    }
}

@media (max-width: 575.98px) {
    .popup-edit-name .close ~ .popup__content .popup__header, .popup-edit-name .popup__close ~ .popup__content .popup__header {
        margin-top: -28px
    }
}

.popup-edit-name .popup__close-btn {
    font-size: 16px;
    line-height: 22px;
    position: absolute;
    z-index: 1;
    top: 16px;
    right: 16px;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    padding: 4px 12px 6px;
    background-color: #fff;
    border-radius: 24px;
    border: none;
    cursor: pointer
}

@media (max-width: 1023.98px) {
    .popup-edit-name .popup__close-btn {
        width: 26px;
        height: 26px;
        display: inline-block;
        position: relative;
        font-size: 0;
        line-height: 1;
        border: none;
        background-color: transparent;
        position: absolute;
        z-index: 1;
        top: 13px;
        right: 12px;
        cursor: pointer;
        line-height: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        padding: 0;
        border-radius: 0
    }

    .popup-edit-name .popup__close-btn:after, .popup-edit-name .popup__close-btn:before {
        -webkit-transition: background .3s ease 0s;
        transition: background .3s ease 0s;
        background-color: #999;
        border-radius: 2px;
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
        transform: translate(-50%, -50%) rotate(45deg)
    }

    .popup-edit-name .popup__close-btn:before {
        width: 2px;
        height: 20px
    }

    .popup-edit-name .popup__close-btn:after {
        width: 20px;
        height: 2px
    }
}

@media (max-width: 1023.98px) and (min-width: 1024px) {
    .popup-edit-name .popup__close-btn {
        top: 8px;
        right: 8px
    }
}

@media (max-width: 1023.98px) and (max-width: 575.98px) {
    .popup-edit-name .popup__close-btn ~ .popup__content {
        padding-top: 28px
    }
}

@media (max-width: 1023.98px) and (max-width: 575.98px) {
    .popup-edit-name .popup__close-btn ~ .popup__content .popup__header {
        margin-top: -28px
    }
}

.popup-edit-name .popup__header {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: #000;
    margin: 0 0 16px;
    padding-right: 24px
}

@media (min-width: 768px) {
    .popup-edit-name .popup__header {
        font-size: 20px;
        line-height: 28px
    }
}

@media (min-width: 1024px) {
    .popup-edit-name .popup__header {
        font-size: 28px;
        line-height: 36px
    }
}

@media (min-width: 1024px) {
    .popup-edit-name .popup__header {
        margin: 0 0 20px;
        padding-right: 0
    }
}

.popup-edit-name .popup__btn {
    margin: 10px 0 0;
    font-size: 0
}

@media (min-width: 576px) {
    .popup-edit-name .popup__btn {
        margin: 20px 0 0
    }
}

.popup-edit-name .popup__btn-main {
    font-family: system, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Segoe WP', Roboto, Ubuntu, Oxygen, Cantarell, 'Fira Sans', 'Helvetica Neue', Helvetica, 'Lucida Grande', 'Droid Sans', Tahoma, 'Microsoft Sans Serif', sans-serif;
    line-height: 1.1;
    letter-spacing: 0;
    font-weight: 700;
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    background-color: #cb11ab;
    border: 2px solid #cb11ab;
    font-size: 16px;
    padding: 11px 25px 12px;
    margin-top: 12px;
    margin-right: 20px
}

.popup-edit-name .popup__btn-main.disabled, .popup-edit-name .popup__btn-main.disabled:active, .popup-edit-name .popup__btn-main.disabled:hover, .popup-edit-name .popup__btn-main:disabled, .popup-edit-name .popup__btn-main:disabled:active, .popup-edit-name .popup__btn-main:disabled:hover {
    cursor: default
}

.popup-edit-name .popup__btn-main.disabled:active:not(.progress), .popup-edit-name .popup__btn-main.disabled:hover:not(.progress), .popup-edit-name .popup__btn-main.disabled:not(.progress), .popup-edit-name .popup__btn-main:disabled:active:not(.progress), .popup-edit-name .popup__btn-main:disabled:hover:not(.progress), .popup-edit-name .popup__btn-main:disabled:not(.progress) {
    color: #d7d7d7;
    background-color: #fff;
    border: 2px solid #d5d5d5;
    opacity: .9
}

.popup-edit-name .popup__btn-main.disabled:active:not(.progress):active, .popup-edit-name .popup__btn-main.disabled:hover:not(.progress):active, .popup-edit-name .popup__btn-main.disabled:not(.progress):active, .popup-edit-name .popup__btn-main:disabled:active:not(.progress):active, .popup-edit-name .popup__btn-main:disabled:hover:not(.progress):active, .popup-edit-name .popup__btn-main:disabled:not(.progress):active {
    -webkit-transform: none;
    transform: none
}

.popup-edit-name .popup__btn-main.progress, .popup-edit-name .popup__btn-main.progress:active {
    color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

@keyframes mySpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.popup-edit-name .popup__btn-main.progress:active:after, .popup-edit-name .popup__btn-main.progress:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 20px / 2);
    right: calc(50% - 20px / 2);
    -webkit-animation: mySpin .9s linear 0s infinite;
    animation: mySpin .9s linear 0s infinite
}

.popup-edit-name .popup__btn-main.progress:active:after, .popup-edit-name .popup__btn-main.progress:after {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%208A7.999%207.999%200%201%201%200%208h2.002a5.998%205.998%200%201%200%2011.996%200H16z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
    .popup-edit-name .popup__btn-main:hover {
        background-color: #e313bf;
        border-color: #e313bf
    }

    .popup-edit-name .popup__btn-main:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (hover: hover) and (pointer: fine) {
    .popup-edit-name .popup__btn-main:hover {
        background-color: #e313bf;
        border-color: #e313bf
    }

    .popup-edit-name .popup__btn-main:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (max-width: 575.98px) {
    .popup-edit-name .popup__btn-main {
        margin-right: 15px
    }
}

.popup-edit-name .popup__btn-base {
    font-family: system, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Segoe WP', Roboto, Ubuntu, Oxygen, Cantarell, 'Fira Sans', 'Helvetica Neue', Helvetica, 'Lucida Grande', 'Droid Sans', Tahoma, 'Microsoft Sans Serif', sans-serif;
    line-height: 1.1;
    letter-spacing: 0;
    font-weight: 700;
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    color: #cb11ab;
    font-size: 16px;
    padding: 11px 25px 12px;
    margin-top: 12px
}

.popup-edit-name .popup__btn-base.disabled, .popup-edit-name .popup__btn-base.disabled:active, .popup-edit-name .popup__btn-base.disabled:hover, .popup-edit-name .popup__btn-base:disabled, .popup-edit-name .popup__btn-base:disabled:active, .popup-edit-name .popup__btn-base:disabled:hover {
    cursor: default
}

.popup-edit-name .popup__btn-base.disabled:active:not(.progress), .popup-edit-name .popup__btn-base.disabled:hover:not(.progress), .popup-edit-name .popup__btn-base.disabled:not(.progress), .popup-edit-name .popup__btn-base:disabled:active:not(.progress), .popup-edit-name .popup__btn-base:disabled:hover:not(.progress), .popup-edit-name .popup__btn-base:disabled:not(.progress) {
    color: #d7d7d7;
    background-color: #fff;
    border: 2px solid #d5d5d5;
    opacity: .9
}

.popup-edit-name .popup__btn-base.disabled:active:not(.progress):active, .popup-edit-name .popup__btn-base.disabled:hover:not(.progress):active, .popup-edit-name .popup__btn-base.disabled:not(.progress):active, .popup-edit-name .popup__btn-base:disabled:active:not(.progress):active, .popup-edit-name .popup__btn-base:disabled:hover:not(.progress):active, .popup-edit-name .popup__btn-base:disabled:not(.progress):active {
    -webkit-transform: none;
    transform: none
}

.popup-edit-name .popup__btn-base.progress, .popup-edit-name .popup__btn-base.progress:active {
    color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

@keyframes mySpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.popup-edit-name .popup__btn-base.progress:active:after, .popup-edit-name .popup__btn-base.progress:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 20px / 2);
    right: calc(50% - 20px / 2);
    -webkit-animation: mySpin .9s linear 0s infinite;
    animation: mySpin .9s linear 0s infinite
}

.popup-edit-name .popup__btn-base.progress:active:after, .popup-edit-name .popup__btn-base.progress:after {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%208A7.999%207.999%200%201%201%200%208h2.002a5.998%205.998%200%201%200%2011.996%200H16z%22%20fill%3D%22%23CB11AB%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain
}

.popup-edit-name .popup__btn-base, .popup-edit-name .popup__btn-base.progress:active, .popup-edit-name .popup__btn-base.progress:hover {
    background-color: #fff;
    border: 2px solid #cb11ab
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
    .popup-edit-name .popup__btn-base:hover {
        background-color: #cb11ab;
        color: #fff
    }

    .popup-edit-name .popup__btn-base:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (hover: hover) and (pointer: fine) {
    .popup-edit-name .popup__btn-base:hover {
        background-color: #cb11ab;
        color: #fff
    }

    .popup-edit-name .popup__btn-base:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

.popup-edit-name::-webkit-scrollbar {
    display: none
}

@media (min-width: 768px) {
    .popup-edit-name .form-list {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: 0 -4px
    }
}

@media (min-width: 1024px) {
    .popup-edit-name .form-list {
        margin: 0
    }
}

.popup-edit-name .form-list__item {
    margin-bottom: 16px
}

.popup-edit-name .form-list__item:last-of-type {
    margin-bottom: 0
}

@media (min-width: 768px) {
    .popup-edit-name .form-list__item {
        margin-left: 4px;
        margin-right: 4px
    }
}

@media (min-width: 1024px) {
    .popup-edit-name .form-list__item {
        width: calc(100% / 12 * 12);
        margin-right: 0;
        margin-left: 0
    }
}

.popup-edit-name .form-block__message {
    margin-top: 0
}

.popup-edit-name .field-validation-error {
    display: block;
    margin-top: 8px
}

.popup-edit-name .popup__btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 16px -4px 0
}

@media (min-width: 1024px) {
    .popup-edit-name .popup__btn {
        margin-top: 32px
    }
}

.popup-edit-name .popup__btn .btn-base, .popup-edit-name .popup__btn .btn-main {
    width: calc(100% / 12 * 6 - 8px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 0 4px
}

@media (min-width: 768px) {
    .popup-edit-name .popup__btn .btn-base, .popup-edit-name .popup__btn .btn-main {
        width: calc(100% / 12 * 3 - 8px)
    }
}

@media (min-width: 1024px) {
    .popup-edit-name .popup__btn .btn-base, .popup-edit-name .popup__btn .btn-main {
        width: calc(100% / 12 * 6 - 8px)
    }
}

.popup-edit-email {
    font-size: 16px;
    line-height: 22px;
    color: #8b8b8b;
    display: none;
    width: 98%;
    max-width: 400px;
    min-width: 320px;
    padding: 12px 16px 16px;
    position: fixed;
    z-index: 1001;
    background: #fff;
    border-radius: 8px;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    scrollbar-width: none;
    overflow-y: auto
}

@media (min-width: 1024px) {
    .popup-edit-email {
        width: calc(100% - 32px);
        max-width: 400px;
        padding: 28px 40px 40px;
        border-radius: 8px;
        position: absolute
    }
}

@media (max-width: 1023.98px) {
    .popup-edit-email {
        left: 0 !important;
        bottom: 0;
        max-height: 100%
    }
}

.popup-edit-email .close, .popup-edit-email .popup__close {
    width: 26px;
    height: 26px;
    display: inline-block;
    position: relative;
    font-size: 0;
    line-height: 1;
    border: none;
    background-color: transparent;
    position: absolute;
    z-index: 1;
    top: 13px;
    right: 12px;
    cursor: pointer
}

.popup-edit-email .close:after, .popup-edit-email .close:before, .popup-edit-email .popup__close:after, .popup-edit-email .popup__close:before {
    -webkit-transition: background .3s ease 0s;
    transition: background .3s ease 0s;
    background-color: #999;
    border-radius: 2px;
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg)
}

.popup-edit-email .close:before, .popup-edit-email .popup__close:before {
    width: 2px;
    height: 20px
}

.popup-edit-email .close:after, .popup-edit-email .popup__close:after {
    width: 20px;
    height: 2px
}

@media (min-width: 1024px) {
    .popup-edit-email .close, .popup-edit-email .popup__close {
        top: 8px;
        right: 8px
    }
}

@media (max-width: 575.98px) {
    .popup-edit-email .close ~ .popup__content, .popup-edit-email .popup__close ~ .popup__content {
        padding-top: 28px
    }
}

@media (max-width: 575.98px) {
    .popup-edit-email .close ~ .popup__content .popup__header, .popup-edit-email .popup__close ~ .popup__content .popup__header {
        margin-top: -28px
    }
}

.popup-edit-email .popup__close-btn {
    font-size: 16px;
    line-height: 22px;
    position: absolute;
    z-index: 1;
    top: 16px;
    right: 16px;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    padding: 4px 12px 6px;
    background-color: #fff;
    border-radius: 24px;
    border: none;
    cursor: pointer
}

@media (max-width: 1023.98px) {
    .popup-edit-email .popup__close-btn {
        width: 26px;
        height: 26px;
        display: inline-block;
        position: relative;
        font-size: 0;
        line-height: 1;
        border: none;
        background-color: transparent;
        position: absolute;
        z-index: 1;
        top: 13px;
        right: 12px;
        cursor: pointer;
        line-height: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        padding: 0;
        border-radius: 0
    }

    .popup-edit-email .popup__close-btn:after, .popup-edit-email .popup__close-btn:before {
        -webkit-transition: background .3s ease 0s;
        transition: background .3s ease 0s;
        background-color: #999;
        border-radius: 2px;
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
        transform: translate(-50%, -50%) rotate(45deg)
    }

    .popup-edit-email .popup__close-btn:before {
        width: 2px;
        height: 20px
    }

    .popup-edit-email .popup__close-btn:after {
        width: 20px;
        height: 2px
    }
}

@media (max-width: 1023.98px) and (min-width: 1024px) {
    .popup-edit-email .popup__close-btn {
        top: 8px;
        right: 8px
    }
}

@media (max-width: 1023.98px) and (max-width: 575.98px) {
    .popup-edit-email .popup__close-btn ~ .popup__content {
        padding-top: 28px
    }
}

@media (max-width: 1023.98px) and (max-width: 575.98px) {
    .popup-edit-email .popup__close-btn ~ .popup__content .popup__header {
        margin-top: -28px
    }
}

.popup-edit-email .popup__header {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: #000;
    margin: 0 0 16px;
    padding-right: 24px
}

@media (min-width: 768px) {
    .popup-edit-email .popup__header {
        font-size: 20px;
        line-height: 28px
    }
}

@media (min-width: 1024px) {
    .popup-edit-email .popup__header {
        font-size: 28px;
        line-height: 36px
    }
}

@media (min-width: 1024px) {
    .popup-edit-email .popup__header {
        margin: 0 0 20px;
        padding-right: 0
    }
}

.popup-edit-email .popup__btn {
    margin: 10px 0 0;
    font-size: 0
}

@media (min-width: 576px) {
    .popup-edit-email .popup__btn {
        margin: 20px 0 0
    }
}

.popup-edit-email .popup__btn-main {
    font-family: system, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Segoe WP', Roboto, Ubuntu, Oxygen, Cantarell, 'Fira Sans', 'Helvetica Neue', Helvetica, 'Lucida Grande', 'Droid Sans', Tahoma, 'Microsoft Sans Serif', sans-serif;
    line-height: 1.1;
    letter-spacing: 0;
    font-weight: 700;
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    background-color: #cb11ab;
    border: 2px solid #cb11ab;
    font-size: 16px;
    padding: 11px 25px 12px;
    margin-top: 12px;
    margin-right: 20px
}

.popup-edit-email .popup__btn-main.disabled, .popup-edit-email .popup__btn-main.disabled:active, .popup-edit-email .popup__btn-main.disabled:hover, .popup-edit-email .popup__btn-main:disabled, .popup-edit-email .popup__btn-main:disabled:active, .popup-edit-email .popup__btn-main:disabled:hover {
    cursor: default
}

.popup-edit-email .popup__btn-main.disabled:active:not(.progress), .popup-edit-email .popup__btn-main.disabled:hover:not(.progress), .popup-edit-email .popup__btn-main.disabled:not(.progress), .popup-edit-email .popup__btn-main:disabled:active:not(.progress), .popup-edit-email .popup__btn-main:disabled:hover:not(.progress), .popup-edit-email .popup__btn-main:disabled:not(.progress) {
    color: #d7d7d7;
    background-color: #fff;
    border: 2px solid #d5d5d5;
    opacity: .9
}

.popup-edit-email .popup__btn-main.disabled:active:not(.progress):active, .popup-edit-email .popup__btn-main.disabled:hover:not(.progress):active, .popup-edit-email .popup__btn-main.disabled:not(.progress):active, .popup-edit-email .popup__btn-main:disabled:active:not(.progress):active, .popup-edit-email .popup__btn-main:disabled:hover:not(.progress):active, .popup-edit-email .popup__btn-main:disabled:not(.progress):active {
    -webkit-transform: none;
    transform: none
}

.popup-edit-email .popup__btn-main.progress, .popup-edit-email .popup__btn-main.progress:active {
    color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

@keyframes mySpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.popup-edit-email .popup__btn-main.progress:active:after, .popup-edit-email .popup__btn-main.progress:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 20px / 2);
    right: calc(50% - 20px / 2);
    -webkit-animation: mySpin .9s linear 0s infinite;
    animation: mySpin .9s linear 0s infinite
}

.popup-edit-email .popup__btn-main.progress:active:after, .popup-edit-email .popup__btn-main.progress:after {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%208A7.999%207.999%200%201%201%200%208h2.002a5.998%205.998%200%201%200%2011.996%200H16z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
    .popup-edit-email .popup__btn-main:hover {
        background-color: #e313bf;
        border-color: #e313bf
    }

    .popup-edit-email .popup__btn-main:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (hover: hover) and (pointer: fine) {
    .popup-edit-email .popup__btn-main:hover {
        background-color: #e313bf;
        border-color: #e313bf
    }

    .popup-edit-email .popup__btn-main:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (max-width: 575.98px) {
    .popup-edit-email .popup__btn-main {
        margin-right: 15px
    }
}

.popup-edit-email .popup__btn-base {
    font-family: system, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Segoe WP', Roboto, Ubuntu, Oxygen, Cantarell, 'Fira Sans', 'Helvetica Neue', Helvetica, 'Lucida Grande', 'Droid Sans', Tahoma, 'Microsoft Sans Serif', sans-serif;
    line-height: 1.1;
    letter-spacing: 0;
    font-weight: 700;
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    color: #cb11ab;
    font-size: 16px;
    padding: 11px 25px 12px;
    margin-top: 12px
}

.popup-edit-email .popup__btn-base.disabled, .popup-edit-email .popup__btn-base.disabled:active, .popup-edit-email .popup__btn-base.disabled:hover, .popup-edit-email .popup__btn-base:disabled, .popup-edit-email .popup__btn-base:disabled:active, .popup-edit-email .popup__btn-base:disabled:hover {
    cursor: default
}

.popup-edit-email .popup__btn-base.disabled:active:not(.progress), .popup-edit-email .popup__btn-base.disabled:hover:not(.progress), .popup-edit-email .popup__btn-base.disabled:not(.progress), .popup-edit-email .popup__btn-base:disabled:active:not(.progress), .popup-edit-email .popup__btn-base:disabled:hover:not(.progress), .popup-edit-email .popup__btn-base:disabled:not(.progress) {
    color: #d7d7d7;
    background-color: #fff;
    border: 2px solid #d5d5d5;
    opacity: .9
}

.popup-edit-email .popup__btn-base.disabled:active:not(.progress):active, .popup-edit-email .popup__btn-base.disabled:hover:not(.progress):active, .popup-edit-email .popup__btn-base.disabled:not(.progress):active, .popup-edit-email .popup__btn-base:disabled:active:not(.progress):active, .popup-edit-email .popup__btn-base:disabled:hover:not(.progress):active, .popup-edit-email .popup__btn-base:disabled:not(.progress):active {
    -webkit-transform: none;
    transform: none
}

.popup-edit-email .popup__btn-base.progress, .popup-edit-email .popup__btn-base.progress:active {
    color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

@keyframes mySpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.popup-edit-email .popup__btn-base.progress:active:after, .popup-edit-email .popup__btn-base.progress:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 20px / 2);
    right: calc(50% - 20px / 2);
    -webkit-animation: mySpin .9s linear 0s infinite;
    animation: mySpin .9s linear 0s infinite
}

.popup-edit-email .popup__btn-base.progress:active:after, .popup-edit-email .popup__btn-base.progress:after {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%208A7.999%207.999%200%201%201%200%208h2.002a5.998%205.998%200%201%200%2011.996%200H16z%22%20fill%3D%22%23CB11AB%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain
}

.popup-edit-email .popup__btn-base, .popup-edit-email .popup__btn-base.progress:active, .popup-edit-email .popup__btn-base.progress:hover {
    background-color: #fff;
    border: 2px solid #cb11ab
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
    .popup-edit-email .popup__btn-base:hover {
        background-color: #cb11ab;
        color: #fff
    }

    .popup-edit-email .popup__btn-base:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (hover: hover) and (pointer: fine) {
    .popup-edit-email .popup__btn-base:hover {
        background-color: #cb11ab;
        color: #fff
    }

    .popup-edit-email .popup__btn-base:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

.popup-edit-email::-webkit-scrollbar {
    display: none
}

.popup-edit-email .popup__text {
    font-size: 14px;
    line-height: 20px;
    margin-top: 16px
}

@media (min-width: 768px) {
    .popup-edit-email .popup__text {
        font-size: 16px;
        line-height: 22px
    }
}

.popup-edit-email .popup__btn {
    margin-top: 16px
}

@media (min-width: 1024px) {
    .popup-edit-email .popup__btn {
        margin-top: 32px
    }
}

.popup-edit-email .form-block__message {
    margin-top: 0
}

.popup-edit-email .field-validation-error {
    display: block;
    margin-top: 8px
}

.popup-edit-email .btn-main {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%
}

@media (min-width: 768px) and (max-width: 1023.98px) {
    .popup-edit-email .btn-main {
        width: auto;
        min-width: 288px
    }
}

.popup-edit-phone {
    font-size: 16px;
    line-height: 22px;
    color: #8b8b8b;
    display: none;
    width: 100%;
    max-width: 100%;
    min-width: 320px;
    padding: 12px 16px 16px;
    position: fixed;
    z-index: 1001;
    background: #fff;
    border-radius: 8px 8px 0 0;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    scrollbar-width: none;
    overflow-y: auto
}

@media (min-width: 1024px) {
    .popup-edit-phone {
        width: calc(100% - 32px);
        max-width: 400px;
        padding: 28px 40px 40px;
        border-radius: 8px;
        position: absolute
    }
}

@media (max-width: 1023.98px) {
    .popup-edit-phone {
        top: auto !important;
        left: 0 !important;
        bottom: 0;
        max-height: 100%
    }
}

.popup-edit-phone .close, .popup-edit-phone .popup__close {
    width: 26px;
    height: 26px;
    display: inline-block;
    position: relative;
    font-size: 0;
    line-height: 1;
    border: none;
    background-color: transparent;
    position: absolute;
    z-index: 1;
    top: 13px;
    right: 12px;
    cursor: pointer
}

.popup-edit-phone .close:after, .popup-edit-phone .close:before, .popup-edit-phone .popup__close:after, .popup-edit-phone .popup__close:before {
    -webkit-transition: background .3s ease 0s;
    transition: background .3s ease 0s;
    background-color: #999;
    border-radius: 2px;
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg)
}

.popup-edit-phone .close:before, .popup-edit-phone .popup__close:before {
    width: 2px;
    height: 20px
}

.popup-edit-phone .close:after, .popup-edit-phone .popup__close:after {
    width: 20px;
    height: 2px
}

@media (min-width: 1024px) {
    .popup-edit-phone .close, .popup-edit-phone .popup__close {
        top: 8px;
        right: 8px
    }
}

@media (max-width: 575.98px) {
    .popup-edit-phone .close ~ .popup__content, .popup-edit-phone .popup__close ~ .popup__content {
        padding-top: 28px
    }
}

@media (max-width: 575.98px) {
    .popup-edit-phone .close ~ .popup__content .popup__header, .popup-edit-phone .popup__close ~ .popup__content .popup__header {
        margin-top: -28px
    }
}

.popup-edit-phone .popup__close-btn {
    font-size: 16px;
    line-height: 22px;
    position: absolute;
    z-index: 1;
    top: 16px;
    right: 16px;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    padding: 4px 12px 6px;
    background-color: #fff;
    border-radius: 24px;
    border: none;
    cursor: pointer
}

@media (max-width: 1023.98px) {
    .popup-edit-phone .popup__close-btn {
        width: 26px;
        height: 26px;
        display: inline-block;
        position: relative;
        font-size: 0;
        line-height: 1;
        border: none;
        background-color: transparent;
        position: absolute;
        z-index: 1;
        top: 13px;
        right: 12px;
        cursor: pointer;
        line-height: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        padding: 0;
        border-radius: 0
    }

    .popup-edit-phone .popup__close-btn:after, .popup-edit-phone .popup__close-btn:before {
        -webkit-transition: background .3s ease 0s;
        transition: background .3s ease 0s;
        background-color: #999;
        border-radius: 2px;
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
        transform: translate(-50%, -50%) rotate(45deg)
    }

    .popup-edit-phone .popup__close-btn:before {
        width: 2px;
        height: 20px
    }

    .popup-edit-phone .popup__close-btn:after {
        width: 20px;
        height: 2px
    }
}

@media (max-width: 1023.98px) and (min-width: 1024px) {
    .popup-edit-phone .popup__close-btn {
        top: 8px;
        right: 8px
    }
}

@media (max-width: 1023.98px) and (max-width: 575.98px) {
    .popup-edit-phone .popup__close-btn ~ .popup__content {
        padding-top: 28px
    }
}

@media (max-width: 1023.98px) and (max-width: 575.98px) {
    .popup-edit-phone .popup__close-btn ~ .popup__content .popup__header {
        margin-top: -28px
    }
}

.popup-edit-phone .popup__header {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: #000;
    margin: 0 0 16px;
    padding-right: 24px
}

@media (min-width: 768px) {
    .popup-edit-phone .popup__header {
        font-size: 20px;
        line-height: 28px
    }
}

@media (min-width: 1024px) {
    .popup-edit-phone .popup__header {
        font-size: 28px;
        line-height: 36px
    }
}

@media (min-width: 1024px) {
    .popup-edit-phone .popup__header {
        margin: 0 0 20px;
        padding-right: 0
    }
}

.popup-edit-phone .popup__btn {
    margin: 10px 0 0;
    font-size: 0
}

@media (min-width: 576px) {
    .popup-edit-phone .popup__btn {
        margin: 20px 0 0
    }
}

.popup-edit-phone .popup__btn-main {
    font-family: system, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Segoe WP', Roboto, Ubuntu, Oxygen, Cantarell, 'Fira Sans', 'Helvetica Neue', Helvetica, 'Lucida Grande', 'Droid Sans', Tahoma, 'Microsoft Sans Serif', sans-serif;
    line-height: 1.1;
    letter-spacing: 0;
    font-weight: 700;
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    background-color: #cb11ab;
    border: 2px solid #cb11ab;
    font-size: 16px;
    padding: 11px 25px 12px;
    margin-top: 12px;
    margin-right: 20px
}

.popup-edit-phone .popup__btn-main.disabled, .popup-edit-phone .popup__btn-main.disabled:active, .popup-edit-phone .popup__btn-main.disabled:hover, .popup-edit-phone .popup__btn-main:disabled, .popup-edit-phone .popup__btn-main:disabled:active, .popup-edit-phone .popup__btn-main:disabled:hover {
    cursor: default
}

.popup-edit-phone .popup__btn-main.disabled:active:not(.progress), .popup-edit-phone .popup__btn-main.disabled:hover:not(.progress), .popup-edit-phone .popup__btn-main.disabled:not(.progress), .popup-edit-phone .popup__btn-main:disabled:active:not(.progress), .popup-edit-phone .popup__btn-main:disabled:hover:not(.progress), .popup-edit-phone .popup__btn-main:disabled:not(.progress) {
    color: #d7d7d7;
    background-color: #fff;
    border: 2px solid #d5d5d5;
    opacity: .9
}

.popup-edit-phone .popup__btn-main.disabled:active:not(.progress):active, .popup-edit-phone .popup__btn-main.disabled:hover:not(.progress):active, .popup-edit-phone .popup__btn-main.disabled:not(.progress):active, .popup-edit-phone .popup__btn-main:disabled:active:not(.progress):active, .popup-edit-phone .popup__btn-main:disabled:hover:not(.progress):active, .popup-edit-phone .popup__btn-main:disabled:not(.progress):active {
    -webkit-transform: none;
    transform: none
}

.popup-edit-phone .popup__btn-main.progress, .popup-edit-phone .popup__btn-main.progress:active {
    color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

@keyframes mySpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.popup-edit-phone .popup__btn-main.progress:active:after, .popup-edit-phone .popup__btn-main.progress:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 20px / 2);
    right: calc(50% - 20px / 2);
    -webkit-animation: mySpin .9s linear 0s infinite;
    animation: mySpin .9s linear 0s infinite
}

.popup-edit-phone .popup__btn-main.progress:active:after, .popup-edit-phone .popup__btn-main.progress:after {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%208A7.999%207.999%200%201%201%200%208h2.002a5.998%205.998%200%201%200%2011.996%200H16z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
    .popup-edit-phone .popup__btn-main:hover {
        background-color: #e313bf;
        border-color: #e313bf
    }

    .popup-edit-phone .popup__btn-main:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (hover: hover) and (pointer: fine) {
    .popup-edit-phone .popup__btn-main:hover {
        background-color: #e313bf;
        border-color: #e313bf
    }

    .popup-edit-phone .popup__btn-main:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (max-width: 575.98px) {
    .popup-edit-phone .popup__btn-main {
        margin-right: 15px
    }
}

.popup-edit-phone .popup__btn-base {
    font-family: system, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Segoe WP', Roboto, Ubuntu, Oxygen, Cantarell, 'Fira Sans', 'Helvetica Neue', Helvetica, 'Lucida Grande', 'Droid Sans', Tahoma, 'Microsoft Sans Serif', sans-serif;
    line-height: 1.1;
    letter-spacing: 0;
    font-weight: 700;
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    color: #cb11ab;
    font-size: 16px;
    padding: 11px 25px 12px;
    margin-top: 12px
}

.popup-edit-phone .popup__btn-base.disabled, .popup-edit-phone .popup__btn-base.disabled:active, .popup-edit-phone .popup__btn-base.disabled:hover, .popup-edit-phone .popup__btn-base:disabled, .popup-edit-phone .popup__btn-base:disabled:active, .popup-edit-phone .popup__btn-base:disabled:hover {
    cursor: default
}

.popup-edit-phone .popup__btn-base.disabled:active:not(.progress), .popup-edit-phone .popup__btn-base.disabled:hover:not(.progress), .popup-edit-phone .popup__btn-base.disabled:not(.progress), .popup-edit-phone .popup__btn-base:disabled:active:not(.progress), .popup-edit-phone .popup__btn-base:disabled:hover:not(.progress), .popup-edit-phone .popup__btn-base:disabled:not(.progress) {
    color: #d7d7d7;
    background-color: #fff;
    border: 2px solid #d5d5d5;
    opacity: .9
}

.popup-edit-phone .popup__btn-base.disabled:active:not(.progress):active, .popup-edit-phone .popup__btn-base.disabled:hover:not(.progress):active, .popup-edit-phone .popup__btn-base.disabled:not(.progress):active, .popup-edit-phone .popup__btn-base:disabled:active:not(.progress):active, .popup-edit-phone .popup__btn-base:disabled:hover:not(.progress):active, .popup-edit-phone .popup__btn-base:disabled:not(.progress):active {
    -webkit-transform: none;
    transform: none
}

.popup-edit-phone .popup__btn-base.progress, .popup-edit-phone .popup__btn-base.progress:active {
    color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

@keyframes mySpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.popup-edit-phone .popup__btn-base.progress:active:after, .popup-edit-phone .popup__btn-base.progress:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 20px / 2);
    right: calc(50% - 20px / 2);
    -webkit-animation: mySpin .9s linear 0s infinite;
    animation: mySpin .9s linear 0s infinite
}

.popup-edit-phone .popup__btn-base.progress:active:after, .popup-edit-phone .popup__btn-base.progress:after {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%208A7.999%207.999%200%201%201%200%208h2.002a5.998%205.998%200%201%200%2011.996%200H16z%22%20fill%3D%22%23CB11AB%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain
}

.popup-edit-phone .popup__btn-base, .popup-edit-phone .popup__btn-base.progress:active, .popup-edit-phone .popup__btn-base.progress:hover {
    background-color: #fff;
    border: 2px solid #cb11ab
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
    .popup-edit-phone .popup__btn-base:hover {
        background-color: #cb11ab;
        color: #fff
    }

    .popup-edit-phone .popup__btn-base:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (hover: hover) and (pointer: fine) {
    .popup-edit-phone .popup__btn-base:hover {
        background-color: #cb11ab;
        color: #fff
    }

    .popup-edit-phone .popup__btn-base:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

.popup-edit-phone::-webkit-scrollbar {
    display: none
}

.popup-edit-phone .popup__info {
    font-size: 14px;
    line-height: 20px
}

@media (min-width: 768px) {
    .popup-edit-phone .popup__info {
        font-size: 16px;
        line-height: 22px
    }
}

@media (min-width: 768px) and (max-width: 1023.98px) {
    .popup-edit-phone .popup__block-wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        margin: 0 -4px
    }
}

.popup-edit-phone .popup__code-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    margin: 16px -4px 0
}

.popup-edit-phone .popup__first-content .popup__form-block {
    width: calc(100% / 12 * 6 - 8px);
    margin: 0 4px
}

@media (min-width: 768px) and (max-width: 1023.98px) {
    .popup-edit-phone .popup__first-content .popup__form-block {
        width: 140px
    }
}

.popup-edit-phone .popup__second-content .popup__form-block {
    margin-top: 16px
}

@media (min-width: 768px) and (max-width: 1023.98px) {
    .popup-edit-phone .popup__form-block--dropdown {
        width: calc(100% / 12 * 6 - 8px);
        -ms-flex-negative: 0;
        flex-shrink: 0;
        margin: 0 4px
    }
}

@media (min-width: 768px) and (max-width: 1023.98px) {
    .popup-edit-phone .popup__form-block--code {
        width: calc(100% / 12 * 6 - 4px)
    }
}

.popup-edit-phone .popup__first-content .popup__get-code {
    width: calc(100% / 12 * 6 - 8px);
    margin: 0 4px;
    padding-left: 4px;
    padding-right: 4px
}

@media (max-width: 1023.98px) {
    .popup-edit-phone .popup__first-content .popup__get-code {
        font-size: 14px;
        line-height: 15px;
        text-transform: initial
    }
}

@media (min-width: 768px) and (max-width: 1023.98px) {
    .popup-edit-phone .popup__first-content .popup__get-code {
        width: 140px
    }
}

.popup-edit-phone .popup__second-content .popup__get-code {
    width: 100%;
    margin-top: 16px
}

@media (min-width: 768px) and (max-width: 1023.98px) {
    .popup-edit-phone .popup__second-content .popup__get-code {
        width: calc(100% / 12 * 6 - 8px);
        -ms-flex-negative: 0;
        flex-shrink: 0;
        margin: 46px 4px 0
    }
}

.popup-edit-phone .popup__first-content .popup__msg {
    font-size: 14px;
    line-height: 20px;
    margin-top: 8px
}

.popup-edit-phone .popup__first-content .popup__msg:first-of-type {
    color: #cb11ab
}

@media (min-width: 768px) {
    .popup-edit-phone .popup__first-content .popup__msg {
        font-size: 16px;
        line-height: 22px
    }
}

.popup-edit-phone .popup__btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 16px -4px 0
}

.popup-edit-phone .popup__btn .btn-base, .popup-edit-phone .popup__btn .btn-main {
    width: calc(100% / 12 * 6 - 8px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 0 4px
}

@media (min-width: 768px) and (max-width: 1023.98px) {
    .popup-edit-phone .popup__btn .btn-base, .popup-edit-phone .popup__btn .btn-main {
        width: calc(100% / 12 * 3 - 8px)
    }
}

.popup-edit-phone .form-block__success input {
    padding-right: 30px
}

.popup-edit-phone .form-block__success {
    position: relative
}

.popup-edit-phone .form-block__success::after {
    content: '';
    border-right: 2px solid #cb11ab;
    border-bottom: 2px solid #cb11ab;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    display: inline-block;
    position: absolute;
    z-index: 1;
    width: 5px;
    height: 11px;
    right: 13px;
    top: 13px
}

.popup-edit-phone .form-block__message {
    margin-top: 0
}

.popup-edit-phone .field-validation-error {
    font-size: 14px;
    line-height: 20px;
    display: block;
    margin-top: 8px
}

.popup-edit-inn {
    font-size: 16px;
    line-height: 22px;
    color: #8b8b8b;
    display: none;
    width: 100%;
    max-width: 100%;
    min-width: 320px;
    padding: 12px 16px 16px;
    position: fixed;
    z-index: 1001;
    background: #fff;
    border-radius: 8px 8px 0 0;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2)
}

@media (min-width: 1024px) {
    .popup-edit-inn {
        width: calc(100% - 32px);
        max-width: 400px;
        padding: 28px 40px 40px;
        border-radius: 8px;
        position: absolute
    }
}

@media (max-width: 1023.98px) {
    .popup-edit-inn {
        top: auto !important;
        left: 0 !important;
        bottom: 0;
        max-height: 100%
    }
}

.popup-edit-inn .close, .popup-edit-inn .popup__close {
    width: 26px;
    height: 26px;
    display: inline-block;
    position: relative;
    font-size: 0;
    line-height: 1;
    border: none;
    background-color: transparent;
    position: absolute;
    z-index: 1;
    top: 13px;
    right: 12px;
    cursor: pointer
}

.popup-edit-inn .close:after, .popup-edit-inn .close:before, .popup-edit-inn .popup__close:after, .popup-edit-inn .popup__close:before {
    -webkit-transition: background .3s ease 0s;
    transition: background .3s ease 0s;
    background-color: #999;
    border-radius: 2px;
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg)
}

.popup-edit-inn .close:before, .popup-edit-inn .popup__close:before {
    width: 2px;
    height: 20px
}

.popup-edit-inn .close:after, .popup-edit-inn .popup__close:after {
    width: 20px;
    height: 2px
}

@media (min-width: 1024px) {
    .popup-edit-inn .close, .popup-edit-inn .popup__close {
        top: 8px;
        right: 8px
    }
}

@media (max-width: 575.98px) {
    .popup-edit-inn .close ~ .popup__content, .popup-edit-inn .popup__close ~ .popup__content {
        padding-top: 28px
    }
}

@media (max-width: 575.98px) {
    .popup-edit-inn .close ~ .popup__content .popup__header, .popup-edit-inn .popup__close ~ .popup__content .popup__header {
        margin-top: -28px
    }
}

.popup-edit-inn .popup__close-btn {
    font-size: 16px;
    line-height: 22px;
    position: absolute;
    z-index: 1;
    top: 16px;
    right: 16px;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    padding: 4px 12px 6px;
    background-color: #fff;
    border-radius: 24px;
    border: none;
    cursor: pointer
}

@media (max-width: 1023.98px) {
    .popup-edit-inn .popup__close-btn {
        width: 26px;
        height: 26px;
        display: inline-block;
        position: relative;
        font-size: 0;
        line-height: 1;
        border: none;
        background-color: transparent;
        position: absolute;
        z-index: 1;
        top: 13px;
        right: 12px;
        cursor: pointer;
        line-height: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        padding: 0;
        border-radius: 0
    }

    .popup-edit-inn .popup__close-btn:after, .popup-edit-inn .popup__close-btn:before {
        -webkit-transition: background .3s ease 0s;
        transition: background .3s ease 0s;
        background-color: #999;
        border-radius: 2px;
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
        transform: translate(-50%, -50%) rotate(45deg)
    }

    .popup-edit-inn .popup__close-btn:before {
        width: 2px;
        height: 20px
    }

    .popup-edit-inn .popup__close-btn:after {
        width: 20px;
        height: 2px
    }
}

@media (max-width: 1023.98px) and (min-width: 1024px) {
    .popup-edit-inn .popup__close-btn {
        top: 8px;
        right: 8px
    }
}

@media (max-width: 1023.98px) and (max-width: 575.98px) {
    .popup-edit-inn .popup__close-btn ~ .popup__content {
        padding-top: 28px
    }
}

@media (max-width: 1023.98px) and (max-width: 575.98px) {
    .popup-edit-inn .popup__close-btn ~ .popup__content .popup__header {
        margin-top: -28px
    }
}

.popup-edit-inn .popup__header {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: #000;
    margin: 0 0 16px;
    padding-right: 24px
}

@media (min-width: 768px) {
    .popup-edit-inn .popup__header {
        font-size: 20px;
        line-height: 28px
    }
}

@media (min-width: 1024px) {
    .popup-edit-inn .popup__header {
        font-size: 28px;
        line-height: 36px
    }
}

@media (min-width: 1024px) {
    .popup-edit-inn .popup__header {
        margin: 0 0 20px;
        padding-right: 0
    }
}

.popup-edit-inn .popup__btn {
    margin: 10px 0 0;
    font-size: 0
}

@media (min-width: 576px) {
    .popup-edit-inn .popup__btn {
        margin: 20px 0 0
    }
}

.popup-edit-inn .popup__btn-main {
    font-family: system, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Segoe WP', Roboto, Ubuntu, Oxygen, Cantarell, 'Fira Sans', 'Helvetica Neue', Helvetica, 'Lucida Grande', 'Droid Sans', Tahoma, 'Microsoft Sans Serif', sans-serif;
    line-height: 1.1;
    letter-spacing: 0;
    font-weight: 700;
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    background-color: #cb11ab;
    border: 2px solid #cb11ab;
    font-size: 16px;
    padding: 11px 25px 12px;
    margin-top: 12px;
    margin-right: 20px
}

.popup-edit-inn .popup__btn-main.disabled, .popup-edit-inn .popup__btn-main.disabled:active, .popup-edit-inn .popup__btn-main.disabled:hover, .popup-edit-inn .popup__btn-main:disabled, .popup-edit-inn .popup__btn-main:disabled:active, .popup-edit-inn .popup__btn-main:disabled:hover {
    cursor: default
}

.popup-edit-inn .popup__btn-main.disabled:active:not(.progress), .popup-edit-inn .popup__btn-main.disabled:hover:not(.progress), .popup-edit-inn .popup__btn-main.disabled:not(.progress), .popup-edit-inn .popup__btn-main:disabled:active:not(.progress), .popup-edit-inn .popup__btn-main:disabled:hover:not(.progress), .popup-edit-inn .popup__btn-main:disabled:not(.progress) {
    color: #d7d7d7;
    background-color: #fff;
    border: 2px solid #d5d5d5;
    opacity: .9
}

.popup-edit-inn .popup__btn-main.disabled:active:not(.progress):active, .popup-edit-inn .popup__btn-main.disabled:hover:not(.progress):active, .popup-edit-inn .popup__btn-main.disabled:not(.progress):active, .popup-edit-inn .popup__btn-main:disabled:active:not(.progress):active, .popup-edit-inn .popup__btn-main:disabled:hover:not(.progress):active, .popup-edit-inn .popup__btn-main:disabled:not(.progress):active {
    -webkit-transform: none;
    transform: none
}

.popup-edit-inn .popup__btn-main.progress, .popup-edit-inn .popup__btn-main.progress:active {
    color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

@keyframes mySpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.popup-edit-inn .popup__btn-main.progress:active:after, .popup-edit-inn .popup__btn-main.progress:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 20px / 2);
    right: calc(50% - 20px / 2);
    -webkit-animation: mySpin .9s linear 0s infinite;
    animation: mySpin .9s linear 0s infinite
}

.popup-edit-inn .popup__btn-main.progress:active:after, .popup-edit-inn .popup__btn-main.progress:after {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%208A7.999%207.999%200%201%201%200%208h2.002a5.998%205.998%200%201%200%2011.996%200H16z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
    .popup-edit-inn .popup__btn-main:hover {
        background-color: #e313bf;
        border-color: #e313bf
    }

    .popup-edit-inn .popup__btn-main:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (hover: hover) and (pointer: fine) {
    .popup-edit-inn .popup__btn-main:hover {
        background-color: #e313bf;
        border-color: #e313bf
    }

    .popup-edit-inn .popup__btn-main:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (max-width: 575.98px) {
    .popup-edit-inn .popup__btn-main {
        margin-right: 15px
    }
}

.popup-edit-inn .popup__btn-base {
    font-family: system, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Segoe WP', Roboto, Ubuntu, Oxygen, Cantarell, 'Fira Sans', 'Helvetica Neue', Helvetica, 'Lucida Grande', 'Droid Sans', Tahoma, 'Microsoft Sans Serif', sans-serif;
    line-height: 1.1;
    letter-spacing: 0;
    font-weight: 700;
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    color: #cb11ab;
    font-size: 16px;
    padding: 11px 25px 12px;
    margin-top: 12px
}

.popup-edit-inn .popup__btn-base.disabled, .popup-edit-inn .popup__btn-base.disabled:active, .popup-edit-inn .popup__btn-base.disabled:hover, .popup-edit-inn .popup__btn-base:disabled, .popup-edit-inn .popup__btn-base:disabled:active, .popup-edit-inn .popup__btn-base:disabled:hover {
    cursor: default
}

.popup-edit-inn .popup__btn-base.disabled:active:not(.progress), .popup-edit-inn .popup__btn-base.disabled:hover:not(.progress), .popup-edit-inn .popup__btn-base.disabled:not(.progress), .popup-edit-inn .popup__btn-base:disabled:active:not(.progress), .popup-edit-inn .popup__btn-base:disabled:hover:not(.progress), .popup-edit-inn .popup__btn-base:disabled:not(.progress) {
    color: #d7d7d7;
    background-color: #fff;
    border: 2px solid #d5d5d5;
    opacity: .9
}

.popup-edit-inn .popup__btn-base.disabled:active:not(.progress):active, .popup-edit-inn .popup__btn-base.disabled:hover:not(.progress):active, .popup-edit-inn .popup__btn-base.disabled:not(.progress):active, .popup-edit-inn .popup__btn-base:disabled:active:not(.progress):active, .popup-edit-inn .popup__btn-base:disabled:hover:not(.progress):active, .popup-edit-inn .popup__btn-base:disabled:not(.progress):active {
    -webkit-transform: none;
    transform: none
}

.popup-edit-inn .popup__btn-base.progress, .popup-edit-inn .popup__btn-base.progress:active {
    color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

@keyframes mySpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.popup-edit-inn .popup__btn-base.progress:active:after, .popup-edit-inn .popup__btn-base.progress:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 20px / 2);
    right: calc(50% - 20px / 2);
    -webkit-animation: mySpin .9s linear 0s infinite;
    animation: mySpin .9s linear 0s infinite
}

.popup-edit-inn .popup__btn-base.progress:active:after, .popup-edit-inn .popup__btn-base.progress:after {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%208A7.999%207.999%200%201%201%200%208h2.002a5.998%205.998%200%201%200%2011.996%200H16z%22%20fill%3D%22%23CB11AB%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain
}

.popup-edit-inn .popup__btn-base, .popup-edit-inn .popup__btn-base.progress:active, .popup-edit-inn .popup__btn-base.progress:hover {
    background-color: #fff;
    border: 2px solid #cb11ab
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
    .popup-edit-inn .popup__btn-base:hover {
        background-color: #cb11ab;
        color: #fff
    }

    .popup-edit-inn .popup__btn-base:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (hover: hover) and (pointer: fine) {
    .popup-edit-inn .popup__btn-base:hover {
        background-color: #cb11ab;
        color: #fff
    }

    .popup-edit-inn .popup__btn-base:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

.popup-edit-inn .field-validation-error {
    font-size: 14px;
    line-height: 20px;
    display: block;
    margin-top: 8px
}

.popup-edit-inn .popup__btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 16px -4px 0
}

.popup-edit-inn .popup__btn .btn-base, .popup-edit-inn .popup__btn .btn-main {
    width: calc(100% / 12 * 6 - 8px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 0 4px
}

@media (min-width: 768px) and (max-width: 1023.98px) {
    .popup-edit-inn .popup__btn .btn-base, .popup-edit-inn .popup__btn .btn-main {
        width: auto;
        min-width: 178px
    }
}

.popup-delete-account {
    font-size: 16px;
    line-height: 22px;
    color: #8b8b8b;
    display: none;
    width: 100%;
    max-width: 100%;
    min-width: 320px;
    padding: 12px 16px 16px;
    position: fixed;
    z-index: 1001;
    background: #fff;
    border-radius: 8px 8px 0 0;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2)
}

@media (min-width: 1024px) {
    .popup-delete-account {
        width: calc(100% - 32px);
        max-width: 964px;
        padding: 28px 40px 40px;
        border-radius: 8px;
        position: absolute
    }
}

@media (max-width: 1023.98px) {
    .popup-delete-account {
        top: auto !important;
        left: 0 !important;
        bottom: 0;
        max-height: 100%
    }
}

.popup-delete-account .close, .popup-delete-account .popup__close {
    width: 26px;
    height: 26px;
    display: inline-block;
    position: relative;
    font-size: 0;
    line-height: 1;
    border: none;
    background-color: transparent;
    position: absolute;
    z-index: 1;
    top: 13px;
    right: 12px;
    cursor: pointer
}

.popup-delete-account .close:after, .popup-delete-account .close:before, .popup-delete-account .popup__close:after, .popup-delete-account .popup__close:before {
    -webkit-transition: background .3s ease 0s;
    transition: background .3s ease 0s;
    background-color: #999;
    border-radius: 2px;
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg)
}

.popup-delete-account .close:before, .popup-delete-account .popup__close:before {
    width: 2px;
    height: 20px
}

.popup-delete-account .close:after, .popup-delete-account .popup__close:after {
    width: 20px;
    height: 2px
}

@media (min-width: 1024px) {
    .popup-delete-account .close, .popup-delete-account .popup__close {
        top: 8px;
        right: 8px
    }
}

@media (max-width: 575.98px) {
    .popup-delete-account .close ~ .popup__content, .popup-delete-account .popup__close ~ .popup__content {
        padding-top: 28px
    }
}

@media (max-width: 575.98px) {
    .popup-delete-account .close ~ .popup__content .popup__header, .popup-delete-account .popup__close ~ .popup__content .popup__header {
        margin-top: -28px
    }
}

.popup-delete-account .popup__close-btn {
    font-size: 16px;
    line-height: 22px;
    position: absolute;
    z-index: 1;
    top: 16px;
    right: 16px;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    padding: 4px 12px 6px;
    background-color: #fff;
    border-radius: 24px;
    border: none;
    cursor: pointer
}

@media (max-width: 1023.98px) {
    .popup-delete-account .popup__close-btn {
        width: 26px;
        height: 26px;
        display: inline-block;
        position: relative;
        font-size: 0;
        line-height: 1;
        border: none;
        background-color: transparent;
        position: absolute;
        z-index: 1;
        top: 13px;
        right: 12px;
        cursor: pointer;
        line-height: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        padding: 0;
        border-radius: 0
    }

    .popup-delete-account .popup__close-btn:after, .popup-delete-account .popup__close-btn:before {
        -webkit-transition: background .3s ease 0s;
        transition: background .3s ease 0s;
        background-color: #999;
        border-radius: 2px;
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
        transform: translate(-50%, -50%) rotate(45deg)
    }

    .popup-delete-account .popup__close-btn:before {
        width: 2px;
        height: 20px
    }

    .popup-delete-account .popup__close-btn:after {
        width: 20px;
        height: 2px
    }
}

@media (max-width: 1023.98px) and (min-width: 1024px) {
    .popup-delete-account .popup__close-btn {
        top: 8px;
        right: 8px
    }
}

@media (max-width: 1023.98px) and (max-width: 575.98px) {
    .popup-delete-account .popup__close-btn ~ .popup__content {
        padding-top: 28px
    }
}

@media (max-width: 1023.98px) and (max-width: 575.98px) {
    .popup-delete-account .popup__close-btn ~ .popup__content .popup__header {
        margin-top: -28px
    }
}

.popup-delete-account .popup__header {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: #000;
    margin: 0 0 16px;
    padding-right: 24px
}

@media (min-width: 768px) {
    .popup-delete-account .popup__header {
        font-size: 20px;
        line-height: 28px
    }
}

@media (min-width: 1024px) {
    .popup-delete-account .popup__header {
        font-size: 28px;
        line-height: 36px
    }
}

@media (min-width: 1024px) {
    .popup-delete-account .popup__header {
        margin: 0 0 20px;
        padding-right: 0
    }
}

.popup-delete-account .popup__btn {
    margin: 10px 0 0;
    font-size: 0
}

@media (min-width: 576px) {
    .popup-delete-account .popup__btn {
        margin: 20px 0 0
    }
}

.popup-delete-account .popup__btn-main {
    font-family: system, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Segoe WP', Roboto, Ubuntu, Oxygen, Cantarell, 'Fira Sans', 'Helvetica Neue', Helvetica, 'Lucida Grande', 'Droid Sans', Tahoma, 'Microsoft Sans Serif', sans-serif;
    line-height: 1.1;
    letter-spacing: 0;
    font-weight: 700;
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    background-color: #cb11ab;
    border: 2px solid #cb11ab;
    font-size: 16px;
    padding: 11px 25px 12px;
    margin-top: 12px;
    margin-right: 20px
}

.popup-delete-account .popup__btn-main.disabled, .popup-delete-account .popup__btn-main.disabled:active, .popup-delete-account .popup__btn-main.disabled:hover, .popup-delete-account .popup__btn-main:disabled, .popup-delete-account .popup__btn-main:disabled:active, .popup-delete-account .popup__btn-main:disabled:hover {
    cursor: default
}

.popup-delete-account .popup__btn-main.disabled:active:not(.progress), .popup-delete-account .popup__btn-main.disabled:hover:not(.progress), .popup-delete-account .popup__btn-main.disabled:not(.progress), .popup-delete-account .popup__btn-main:disabled:active:not(.progress), .popup-delete-account .popup__btn-main:disabled:hover:not(.progress), .popup-delete-account .popup__btn-main:disabled:not(.progress) {
    color: #d7d7d7;
    background-color: #fff;
    border: 2px solid #d5d5d5;
    opacity: .9
}

.popup-delete-account .popup__btn-main.disabled:active:not(.progress):active, .popup-delete-account .popup__btn-main.disabled:hover:not(.progress):active, .popup-delete-account .popup__btn-main.disabled:not(.progress):active, .popup-delete-account .popup__btn-main:disabled:active:not(.progress):active, .popup-delete-account .popup__btn-main:disabled:hover:not(.progress):active, .popup-delete-account .popup__btn-main:disabled:not(.progress):active {
    -webkit-transform: none;
    transform: none
}

.popup-delete-account .popup__btn-main.progress, .popup-delete-account .popup__btn-main.progress:active {
    color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

@keyframes mySpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.popup-delete-account .popup__btn-main.progress:active:after, .popup-delete-account .popup__btn-main.progress:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 20px / 2);
    right: calc(50% - 20px / 2);
    -webkit-animation: mySpin .9s linear 0s infinite;
    animation: mySpin .9s linear 0s infinite
}

.popup-delete-account .popup__btn-main.progress:active:after, .popup-delete-account .popup__btn-main.progress:after {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%208A7.999%207.999%200%201%201%200%208h2.002a5.998%205.998%200%201%200%2011.996%200H16z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
    .popup-delete-account .popup__btn-main:hover {
        background-color: #e313bf;
        border-color: #e313bf
    }

    .popup-delete-account .popup__btn-main:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (hover: hover) and (pointer: fine) {
    .popup-delete-account .popup__btn-main:hover {
        background-color: #e313bf;
        border-color: #e313bf
    }

    .popup-delete-account .popup__btn-main:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (max-width: 575.98px) {
    .popup-delete-account .popup__btn-main {
        margin-right: 15px
    }
}

.popup-delete-account .popup__btn-base {
    font-family: system, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Segoe WP', Roboto, Ubuntu, Oxygen, Cantarell, 'Fira Sans', 'Helvetica Neue', Helvetica, 'Lucida Grande', 'Droid Sans', Tahoma, 'Microsoft Sans Serif', sans-serif;
    line-height: 1.1;
    letter-spacing: 0;
    font-weight: 700;
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    color: #cb11ab;
    font-size: 16px;
    padding: 11px 25px 12px;
    margin-top: 12px
}

.popup-delete-account .popup__btn-base.disabled, .popup-delete-account .popup__btn-base.disabled:active, .popup-delete-account .popup__btn-base.disabled:hover, .popup-delete-account .popup__btn-base:disabled, .popup-delete-account .popup__btn-base:disabled:active, .popup-delete-account .popup__btn-base:disabled:hover {
    cursor: default
}

.popup-delete-account .popup__btn-base.disabled:active:not(.progress), .popup-delete-account .popup__btn-base.disabled:hover:not(.progress), .popup-delete-account .popup__btn-base.disabled:not(.progress), .popup-delete-account .popup__btn-base:disabled:active:not(.progress), .popup-delete-account .popup__btn-base:disabled:hover:not(.progress), .popup-delete-account .popup__btn-base:disabled:not(.progress) {
    color: #d7d7d7;
    background-color: #fff;
    border: 2px solid #d5d5d5;
    opacity: .9
}

.popup-delete-account .popup__btn-base.disabled:active:not(.progress):active, .popup-delete-account .popup__btn-base.disabled:hover:not(.progress):active, .popup-delete-account .popup__btn-base.disabled:not(.progress):active, .popup-delete-account .popup__btn-base:disabled:active:not(.progress):active, .popup-delete-account .popup__btn-base:disabled:hover:not(.progress):active, .popup-delete-account .popup__btn-base:disabled:not(.progress):active {
    -webkit-transform: none;
    transform: none
}

.popup-delete-account .popup__btn-base.progress, .popup-delete-account .popup__btn-base.progress:active {
    color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

@keyframes mySpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.popup-delete-account .popup__btn-base.progress:active:after, .popup-delete-account .popup__btn-base.progress:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 20px / 2);
    right: calc(50% - 20px / 2);
    -webkit-animation: mySpin .9s linear 0s infinite;
    animation: mySpin .9s linear 0s infinite
}

.popup-delete-account .popup__btn-base.progress:active:after, .popup-delete-account .popup__btn-base.progress:after {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%208A7.999%207.999%200%201%201%200%208h2.002a5.998%205.998%200%201%200%2011.996%200H16z%22%20fill%3D%22%23CB11AB%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain
}

.popup-delete-account .popup__btn-base, .popup-delete-account .popup__btn-base.progress:active, .popup-delete-account .popup__btn-base.progress:hover {
    background-color: #fff;
    border: 2px solid #cb11ab
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
    .popup-delete-account .popup__btn-base:hover {
        background-color: #cb11ab;
        color: #fff
    }

    .popup-delete-account .popup__btn-base:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (hover: hover) and (pointer: fine) {
    .popup-delete-account .popup__btn-base:hover {
        background-color: #cb11ab;
        color: #fff
    }

    .popup-delete-account .popup__btn-base:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (max-width: 1023.98px) {
    .popup-delete-account {
        height: 100%
    }
}

@media (max-width: 1023.98px) {
    .popup-delete-account .popup__content {
        height: 100%;
        padding-top: 0 !important
    }
}

.popup-delete-account .popup__header {
    margin-top: 0 !important
}

@media (max-width: 767.98px) {
    .popup-delete-account .popup__header {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        max-height: 24px;
        overflow: hidden
    }
}

@media (max-width: 1023.98px) {
    .popup-delete-account .popup__scroll-wrap {
        scrollbar-width: none;
        overflow-y: auto;
        height: calc(100% - 40px);
        padding-right: 8px
    }

    .popup-delete-account .popup__scroll-wrap::-webkit-scrollbar {
        display: none
    }
}

.popup-delete-account .popup__info {
    font-size: 14px;
    line-height: 20px
}

@media (min-width: 768px) {
    .popup-delete-account .popup__info {
        font-size: 16px;
        line-height: 22px
    }
}

.popup-delete-account .popup__info--bottom {
    margin-top: 12px
}

@media (min-width: 768px) {
    .popup-delete-account .popup__info--bottom {
        margin-top: 16px
    }
}

@media (min-width: 768px) {
    .popup-delete-account .popup__info--bottom {
        margin-top: 20px
    }
}

.popup-delete-account .popup__code-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    margin: 24px -4px 0
}

@media (min-width: 768px) {
    .popup-delete-account .popup__code-wrap {
        margin-top: 28px
    }
}

@media (min-width: 1024px) {
    .popup-delete-account .popup__code-wrap {
        margin-top: 16px
    }
}

.popup-delete-account .popup__form-block {
    width: calc(100% / 12 * 6 - 8px);
    margin: 0 4px
}

@media (min-width: 768px) {
    .popup-delete-account .popup__form-block {
        width: 140px
    }
}

@media (min-width: 1024px) {
    .popup-delete-account .popup__form-block {
        width: 178px
    }
}

.popup-delete-account .popup__get-code {
    width: calc(100% / 12 * 6 - 8px);
    margin: 0 4px;
    padding-left: 4px;
    padding-right: 4px
}

@media (max-width: 1023.98px) {
    .popup-delete-account .popup__get-code {
        font-size: 14px;
        line-height: 15px;
        text-transform: initial
    }
}

@media (min-width: 768px) {
    .popup-delete-account .popup__get-code {
        width: 140px
    }
}

@media (min-width: 1024px) {
    .popup-delete-account .popup__get-code {
        width: 178px
    }
}

.popup-delete-account .popup__msg-block {
    font-size: 14px;
    line-height: 20px;
    margin-top: 8px
}

@media (min-width: 1024px) {
    .popup-delete-account .popup__msg-block {
        font-size: 16px;
        line-height: 22px
    }
}

.popup-delete-account .popup__msg-error {
    color: #cb11ab
}

.popup-delete-account .popup__btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 24px -4px 0
}

@media (min-width: 768px) {
    .popup-delete-account .popup__btn {
        margin-top: 40px
    }
}

.popup-delete-account .popup__btn button {
    width: calc(100% / 12 * 6 - 8px);
    margin: 0 4px
}

@media (min-width: 1024px) {
    .popup-delete-account .popup__btn button {
        width: 178px
    }
}

.popup-delete-account .textarea-block {
    margin-top: 16px
}

@media (min-width: 1024px) {
    .popup-delete-account .textarea-block {
        margin-top: 24px
    }
}

.popup-delete-account .textarea-block textarea {
    height: 152px
}

.popup-delete-account .textarea-block__message {
    margin: 0
}

.popup-delete-account .textarea-block__count-symbols {
    margin: 8px 0 0
}

.popup-delete-account .form-block__success input {
    padding-right: 30px
}

.popup-delete-account .form-block__success {
    position: relative
}

.popup-delete-account .form-block__success::after {
    content: '';
    border-right: 2px solid #cb11ab;
    border-bottom: 2px solid #cb11ab;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    display: inline-block;
    position: absolute;
    z-index: 1;
    width: 5px;
    height: 11px;
    right: 13px;
    top: 13px
}

.i-popup-image-crop {
    font-size: 16px;
    line-height: 22px;
    color: #8b8b8b;
    display: none;
    width: 100%;
    max-width: 100%;
    min-width: 320px;
    padding: 12px 16px 16px;
    position: fixed;
    z-index: 1001;
    background: #fff;
    border-radius: 8px 8px 0 0;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2)
}

@media (min-width: 1024px) {
    .i-popup-image-crop {
        width: calc(100% - 32px);
        max-width: 1440px;
        padding: 28px 40px 40px;
        border-radius: 8px;
        position: absolute
    }
}

@media (max-width: 1023.98px) {
    .i-popup-image-crop {
        bottom: 0;
        max-height: 100%
    }
}

.i-popup-image-crop .close, .i-popup-image-crop .popup__close {
    width: 26px;
    height: 26px;
    display: inline-block;
    position: relative;
    font-size: 0;
    line-height: 1;
    border: none;
    background-color: transparent;
    position: absolute;
    z-index: 1;
    top: 13px;
    right: 12px;
    cursor: pointer
}

.i-popup-image-crop .close:after, .i-popup-image-crop .close:before, .i-popup-image-crop .popup__close:after, .i-popup-image-crop .popup__close:before {
    -webkit-transition: background .3s ease 0s;
    transition: background .3s ease 0s;
    background-color: #999;
    border-radius: 2px;
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg)
}

.i-popup-image-crop .close:before, .i-popup-image-crop .popup__close:before {
    width: 2px;
    height: 20px
}

.i-popup-image-crop .close:after, .i-popup-image-crop .popup__close:after {
    width: 20px;
    height: 2px
}

@media (min-width: 1024px) {
    .i-popup-image-crop .close, .i-popup-image-crop .popup__close {
        top: 8px;
        right: 8px
    }
}

@media (max-width: 575.98px) {
    .i-popup-image-crop .close ~ .popup__content, .i-popup-image-crop .popup__close ~ .popup__content {
        padding-top: 28px
    }
}

@media (max-width: 575.98px) {
    .i-popup-image-crop .close ~ .popup__content .popup__header, .i-popup-image-crop .popup__close ~ .popup__content .popup__header {
        margin-top: -28px
    }
}

.i-popup-image-crop .popup__close-btn {
    font-size: 16px;
    line-height: 22px;
    position: absolute;
    z-index: 1;
    top: 16px;
    right: 16px;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    padding: 4px 12px 6px;
    background-color: #fff;
    border-radius: 24px;
    border: none;
    cursor: pointer
}

@media (max-width: 1023.98px) {
    .i-popup-image-crop .popup__close-btn {
        width: 26px;
        height: 26px;
        display: inline-block;
        position: relative;
        font-size: 0;
        line-height: 1;
        border: none;
        background-color: transparent;
        position: absolute;
        z-index: 1;
        top: 13px;
        right: 12px;
        cursor: pointer;
        line-height: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        padding: 0;
        border-radius: 0
    }

    .i-popup-image-crop .popup__close-btn:after, .i-popup-image-crop .popup__close-btn:before {
        -webkit-transition: background .3s ease 0s;
        transition: background .3s ease 0s;
        background-color: #999;
        border-radius: 2px;
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
        transform: translate(-50%, -50%) rotate(45deg)
    }

    .i-popup-image-crop .popup__close-btn:before {
        width: 2px;
        height: 20px
    }

    .i-popup-image-crop .popup__close-btn:after {
        width: 20px;
        height: 2px
    }
}

@media (max-width: 1023.98px) and (min-width: 1024px) {
    .i-popup-image-crop .popup__close-btn {
        top: 8px;
        right: 8px
    }
}

@media (max-width: 1023.98px) and (max-width: 575.98px) {
    .i-popup-image-crop .popup__close-btn ~ .popup__content {
        padding-top: 28px
    }
}

@media (max-width: 1023.98px) and (max-width: 575.98px) {
    .i-popup-image-crop .popup__close-btn ~ .popup__content .popup__header {
        margin-top: -28px
    }
}

.i-popup-image-crop .popup__header {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: #000;
    margin: 0 0 16px;
    padding-right: 24px
}

@media (min-width: 768px) {
    .i-popup-image-crop .popup__header {
        font-size: 20px;
        line-height: 28px
    }
}

@media (min-width: 1024px) {
    .i-popup-image-crop .popup__header {
        font-size: 28px;
        line-height: 36px
    }
}

@media (min-width: 1024px) {
    .i-popup-image-crop .popup__header {
        margin: 0 0 20px;
        padding-right: 0
    }
}

.i-popup-image-crop .popup__btn {
    margin: 10px 0 0;
    font-size: 0
}

@media (min-width: 576px) {
    .i-popup-image-crop .popup__btn {
        margin: 20px 0 0
    }
}

.i-popup-image-crop .popup__btn-main {
    font-family: system, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Segoe WP', Roboto, Ubuntu, Oxygen, Cantarell, 'Fira Sans', 'Helvetica Neue', Helvetica, 'Lucida Grande', 'Droid Sans', Tahoma, 'Microsoft Sans Serif', sans-serif;
    line-height: 1.1;
    letter-spacing: 0;
    font-weight: 700;
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    background-color: #cb11ab;
    border: 2px solid #cb11ab;
    font-size: 16px;
    padding: 11px 25px 12px;
    margin-top: 12px;
    margin-right: 20px
}

.i-popup-image-crop .popup__btn-main.disabled, .i-popup-image-crop .popup__btn-main.disabled:active, .i-popup-image-crop .popup__btn-main.disabled:hover, .i-popup-image-crop .popup__btn-main:disabled, .i-popup-image-crop .popup__btn-main:disabled:active, .i-popup-image-crop .popup__btn-main:disabled:hover {
    cursor: default
}

.i-popup-image-crop .popup__btn-main.disabled:active:not(.progress), .i-popup-image-crop .popup__btn-main.disabled:hover:not(.progress), .i-popup-image-crop .popup__btn-main.disabled:not(.progress), .i-popup-image-crop .popup__btn-main:disabled:active:not(.progress), .i-popup-image-crop .popup__btn-main:disabled:hover:not(.progress), .i-popup-image-crop .popup__btn-main:disabled:not(.progress) {
    color: #d7d7d7;
    background-color: #fff;
    border: 2px solid #d5d5d5;
    opacity: .9
}

.i-popup-image-crop .popup__btn-main.disabled:active:not(.progress):active, .i-popup-image-crop .popup__btn-main.disabled:hover:not(.progress):active, .i-popup-image-crop .popup__btn-main.disabled:not(.progress):active, .i-popup-image-crop .popup__btn-main:disabled:active:not(.progress):active, .i-popup-image-crop .popup__btn-main:disabled:hover:not(.progress):active, .i-popup-image-crop .popup__btn-main:disabled:not(.progress):active {
    -webkit-transform: none;
    transform: none
}

.i-popup-image-crop .popup__btn-main.progress, .i-popup-image-crop .popup__btn-main.progress:active {
    color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

@keyframes mySpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.i-popup-image-crop .popup__btn-main.progress:active:after, .i-popup-image-crop .popup__btn-main.progress:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 20px / 2);
    right: calc(50% - 20px / 2);
    -webkit-animation: mySpin .9s linear 0s infinite;
    animation: mySpin .9s linear 0s infinite
}

.i-popup-image-crop .popup__btn-main.progress:active:after, .i-popup-image-crop .popup__btn-main.progress:after {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%208A7.999%207.999%200%201%201%200%208h2.002a5.998%205.998%200%201%200%2011.996%200H16z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
    .i-popup-image-crop .popup__btn-main:hover {
        background-color: #e313bf;
        border-color: #e313bf
    }

    .i-popup-image-crop .popup__btn-main:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (hover: hover) and (pointer: fine) {
    .i-popup-image-crop .popup__btn-main:hover {
        background-color: #e313bf;
        border-color: #e313bf
    }

    .i-popup-image-crop .popup__btn-main:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (max-width: 575.98px) {
    .i-popup-image-crop .popup__btn-main {
        margin-right: 15px
    }
}

.i-popup-image-crop .popup__btn-base {
    font-family: system, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Segoe WP', Roboto, Ubuntu, Oxygen, Cantarell, 'Fira Sans', 'Helvetica Neue', Helvetica, 'Lucida Grande', 'Droid Sans', Tahoma, 'Microsoft Sans Serif', sans-serif;
    line-height: 1.1;
    letter-spacing: 0;
    font-weight: 700;
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    color: #cb11ab;
    font-size: 16px;
    padding: 11px 25px 12px;
    margin-top: 12px
}

.i-popup-image-crop .popup__btn-base.disabled, .i-popup-image-crop .popup__btn-base.disabled:active, .i-popup-image-crop .popup__btn-base.disabled:hover, .i-popup-image-crop .popup__btn-base:disabled, .i-popup-image-crop .popup__btn-base:disabled:active, .i-popup-image-crop .popup__btn-base:disabled:hover {
    cursor: default
}

.i-popup-image-crop .popup__btn-base.disabled:active:not(.progress), .i-popup-image-crop .popup__btn-base.disabled:hover:not(.progress), .i-popup-image-crop .popup__btn-base.disabled:not(.progress), .i-popup-image-crop .popup__btn-base:disabled:active:not(.progress), .i-popup-image-crop .popup__btn-base:disabled:hover:not(.progress), .i-popup-image-crop .popup__btn-base:disabled:not(.progress) {
    color: #d7d7d7;
    background-color: #fff;
    border: 2px solid #d5d5d5;
    opacity: .9
}

.i-popup-image-crop .popup__btn-base.disabled:active:not(.progress):active, .i-popup-image-crop .popup__btn-base.disabled:hover:not(.progress):active, .i-popup-image-crop .popup__btn-base.disabled:not(.progress):active, .i-popup-image-crop .popup__btn-base:disabled:active:not(.progress):active, .i-popup-image-crop .popup__btn-base:disabled:hover:not(.progress):active, .i-popup-image-crop .popup__btn-base:disabled:not(.progress):active {
    -webkit-transform: none;
    transform: none
}

.i-popup-image-crop .popup__btn-base.progress, .i-popup-image-crop .popup__btn-base.progress:active {
    color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

@keyframes mySpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.i-popup-image-crop .popup__btn-base.progress:active:after, .i-popup-image-crop .popup__btn-base.progress:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 20px / 2);
    right: calc(50% - 20px / 2);
    -webkit-animation: mySpin .9s linear 0s infinite;
    animation: mySpin .9s linear 0s infinite
}

.i-popup-image-crop .popup__btn-base.progress:active:after, .i-popup-image-crop .popup__btn-base.progress:after {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%208A7.999%207.999%200%201%201%200%208h2.002a5.998%205.998%200%201%200%2011.996%200H16z%22%20fill%3D%22%23CB11AB%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain
}

.i-popup-image-crop .popup__btn-base, .i-popup-image-crop .popup__btn-base.progress:active, .i-popup-image-crop .popup__btn-base.progress:hover {
    background-color: #fff;
    border: 2px solid #cb11ab
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
    .i-popup-image-crop .popup__btn-base:hover {
        background-color: #cb11ab;
        color: #fff
    }

    .i-popup-image-crop .popup__btn-base:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (hover: hover) and (pointer: fine) {
    .i-popup-image-crop .popup__btn-base:hover {
        background-color: #cb11ab;
        color: #fff
    }

    .i-popup-image-crop .popup__btn-base:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (max-width: 1023.98px) {
    .i-popup-image-crop {
        height: 100%
    }
}

@media (min-width: 1024px) {
    .i-popup-image-crop {
        height: 830px
    }
}

.i-popup-image-crop .popup__header {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 24px
}

@media (min-width: 768px) {
    .i-popup-image-crop .popup__header {
        max-height: 28px
    }
}

@media (min-width: 1024px) {
    .i-popup-image-crop .popup__header {
        max-height: 36px
    }
}

.i-popup-image-crop .popup__content, .i-popup-image-crop .popup__content form {
    height: 100%
}

.i-popup-image-crop .popup__top-wrap {
    min-height: 82px;
    margin-bottom: 4px
}

@media (min-width: 768px) {
    .i-popup-image-crop .popup__top-wrap {
        min-height: 56px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start
    }
}

@media (min-width: 1024px) {
    .i-popup-image-crop .popup__top-wrap {
        min-height: initial;
        margin-bottom: 20px
    }
}

.i-popup-image-crop .popup__text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 40px
}

@media (max-width: 767.98px) {
    .i-popup-image-crop .popup__text {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 8px
    }
}

@media (min-width: 768px) {
    .i-popup-image-crop .popup__text {
        -ms-flex-negative: 0;
        flex-shrink: 0;
        width: calc(100% - 280px - 20px);
        max-height: 50px;
        margin-right: 20px;
        padding-top: 6px
    }
}

@media (min-width: 1024px) {
    .i-popup-image-crop .popup__text {
        -webkit-line-clamp: 1;
        max-height: 28px
    }
}

.i-popup-image-crop .popup__btn-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

@media (min-width: 768px) {
    .i-popup-image-crop .popup__btn-wrap {
        width: 280px;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end
    }
}

.i-popup-image-crop .popup__btn-turn {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 16px;
    line-height: 22px;
    padding: 6px 0 6px 26px;
    -webkit-transition: color .3s ease 0s;
    transition: color .3s ease 0s
}

.i-popup-image-crop .popup__btn-turn:hover {
    color: #cb11ab
}

.i-popup-image-crop .popup__btn-turn--left {
    background: left 0 top 13px 10px no-repeat url(data:image/svg+xml,%3Csvg%20width%3D%2220%22%20height%3D%2210%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M2%205.366c.5-.654%201.15-1.492%201.57-1.953C5.535%201.258%207.762%200%2010.5%200%2016.89%200%2020%204.412%2020%2010h-2c0-4.603-2.395-8-7.5-8-2.091%200-3.832.983-5.45%202.76-.424.464-1.23%201.522-1.782%202.24H9v2H0V0h2v5.366z%22%20fill%3D%22%23CB11AB%22%2F%3E%3C%2Fsvg%3E)
}

@media (min-width: 768px) {
    .i-popup-image-crop .popup__btn-turn--left {
        margin-left: 22px
    }
}

.i-popup-image-crop .popup__btn-turn--right {
    background: left 0 top 13px 10px no-repeat url(data:image/svg+xml,%3Csvg%20width%3D%2220%22%20height%3D%2210%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M18%205.366V0h2v9h-9V7h5.732c-.552-.718-1.358-1.776-1.781-2.24C13.332%202.983%2011.59%202%209.5%202%204.395%202%202%205.397%202%2010H0C0%204.412%203.11%200%209.5%200c2.739%200%204.966%201.258%206.93%203.413.42.461%201.07%201.299%201.57%201.953z%22%20fill%3D%22%23CB11AB%22%2F%3E%3C%2Fsvg%3E);
    margin-left: 22px
}

.i-popup-image-crop .popup__crop-container {
    height: calc(100% - 40px - 86px - 60px + 28px)
}

@media (max-width: 1023.98px) {
    .i-popup-image-crop .popup__crop-container {
        margin: 0 -16px
    }
}

@media (min-width: 576px) {
    .i-popup-image-crop .popup__crop-container {
        height: calc(100% - 40px - 86px - 60px)
    }
}

@media (min-width: 768px) {
    .i-popup-image-crop .popup__crop-container {
        height: calc(100% - 44px - 60px - 72px)
    }
}

@media (min-width: 1024px) {
    .i-popup-image-crop .popup__crop-container {
        height: calc(100% - 56px - 54px - 72px)
    }
}

.i-popup-image-crop .cropper-preview {
    border-color: #cb11ab
}

.i-popup-image-crop .cropper-dashed, .i-popup-image-crop .cropper-line {
    display: none
}

.i-popup-image-crop .cropper-point {
    width: 16px;
    height: 16px;
    background: #cb11ab;
    opacity: 1
}

.i-popup-image-crop .cropper-point.point-e, .i-popup-image-crop .cropper-point.point-w {
    top: 50%;
    margin-top: -8px
}

.i-popup-image-crop .cropper-point.point-n, .i-popup-image-crop .cropper-point.point-s {
    left: 50%;
    margin-left: -8px
}

.i-popup-image-crop .cropper-point.point-e, .i-popup-image-crop .cropper-point.point-ne, .i-popup-image-crop .cropper-point.point-se {
    right: 0
}

.i-popup-image-crop .cropper-point.point-s, .i-popup-image-crop .cropper-point.point-se, .i-popup-image-crop .cropper-point.point-sw {
    bottom: 0
}

.i-popup-image-crop .cropper-point.point-nw, .i-popup-image-crop .cropper-point.point-sw, .i-popup-image-crop .cropper-point.point-w {
    left: 0
}

.i-popup-image-crop .cropper-point.point-n, .i-popup-image-crop .cropper-point.point-ne, .i-popup-image-crop .cropper-point.point-nw {
    top: 0
}

.i-popup-image-crop.round-image .cropper-canvas::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000;
    opacity: .5;
    z-index: 1
}

.i-popup-image-crop.round-image .cropper-view-box {
    outline: 0;
    border-radius: 50%
}

.i-popup-image-crop.round-image .cropper-line {
    display: none
}

.i-popup-image-crop.round-image .cropper-face {
    border-radius: 50%
}

.i-popup-image-crop.round-image .cropper-point {
    width: 16px;
    height: 16px;
    background-color: #cb11ab;
    opacity: 1
}

.i-popup-image-crop.round-image .point-e, .i-popup-image-crop.round-image .point-n, .i-popup-image-crop.round-image .point-s, .i-popup-image-crop.round-image .point-w {
    display: none
}

.i-popup-image-crop.round-image .point-nw {
    left: 0;
    top: 0
}

.i-popup-image-crop.round-image .point-sw {
    left: 0;
    bottom: 0
}

.i-popup-image-crop.round-image .point-ne {
    right: 0;
    top: 0
}

.i-popup-image-crop.round-image .point-se {
    right: 0;
    bottom: 0
}

.i-popup-image-crop .popup__btn-block {
    margin-top: 16px
}

@media (max-width: 1023.98px) {
    .i-popup-image-crop .popup__btn-block {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between
    }
}

@media (min-width: 768px) {
    .i-popup-image-crop .popup__btn-block {
        margin-top: 28px
    }
}

@media (max-width: 1023.98px) {
    .i-popup-image-crop .btn-base, .i-popup-image-crop .btn-main {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: calc((100% - 8px) / 2);
        padding-left: 10px;
        padding-right: 10px
    }
}

@media (min-width: 1024px) {
    .i-popup-image-crop .btn-base {
        margin-left: 20px
    }
}

.popup-confirm-code {
    font-size: 16px;
    line-height: 22px;
    color: #8b8b8b;
    display: none;
    width: 100%;
    max-width: 100%;
    min-width: 320px;
    padding: 12px 16px 16px;
    position: fixed;
    z-index: 1001;
    background: #fff;
    border-radius: 8px 8px 0 0;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2)
}

@media (min-width: 1024px) {
    .popup-confirm-code {
        width: calc(100% - 32px);
        max-width: 400px;
        padding: 28px 40px 40px;
        border-radius: 8px;
        position: absolute
    }
}

@media (max-width: 1023.98px) {
    .popup-confirm-code {
        top: auto !important;
        left: 0 !important;
        bottom: 0;
        max-height: 100%
    }
}

.popup-confirm-code .close, .popup-confirm-code .popup__close {
    width: 26px;
    height: 26px;
    display: inline-block;
    position: relative;
    font-size: 0;
    line-height: 1;
    border: none;
    background-color: transparent;
    position: absolute;
    z-index: 1;
    top: 13px;
    right: 12px;
    cursor: pointer
}

.popup-confirm-code .close:after, .popup-confirm-code .close:before, .popup-confirm-code .popup__close:after, .popup-confirm-code .popup__close:before {
    -webkit-transition: background .3s ease 0s;
    transition: background .3s ease 0s;
    background-color: #999;
    border-radius: 2px;
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg)
}

.popup-confirm-code .close:before, .popup-confirm-code .popup__close:before {
    width: 2px;
    height: 20px
}

.popup-confirm-code .close:after, .popup-confirm-code .popup__close:after {
    width: 20px;
    height: 2px
}

@media (min-width: 1024px) {
    .popup-confirm-code .close, .popup-confirm-code .popup__close {
        top: 8px;
        right: 8px
    }
}

@media (max-width: 575.98px) {
    .popup-confirm-code .close ~ .popup__content, .popup-confirm-code .popup__close ~ .popup__content {
        padding-top: 28px
    }
}

@media (max-width: 575.98px) {
    .popup-confirm-code .close ~ .popup__content .popup__header, .popup-confirm-code .popup__close ~ .popup__content .popup__header {
        margin-top: -28px
    }
}

.popup-confirm-code .popup__close-btn {
    font-size: 16px;
    line-height: 22px;
    position: absolute;
    z-index: 1;
    top: 16px;
    right: 16px;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    padding: 4px 12px 6px;
    background-color: #fff;
    border-radius: 24px;
    border: none;
    cursor: pointer
}

@media (max-width: 1023.98px) {
    .popup-confirm-code .popup__close-btn {
        width: 26px;
        height: 26px;
        display: inline-block;
        position: relative;
        font-size: 0;
        line-height: 1;
        border: none;
        background-color: transparent;
        position: absolute;
        z-index: 1;
        top: 13px;
        right: 12px;
        cursor: pointer;
        line-height: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        padding: 0;
        border-radius: 0
    }

    .popup-confirm-code .popup__close-btn:after, .popup-confirm-code .popup__close-btn:before {
        -webkit-transition: background .3s ease 0s;
        transition: background .3s ease 0s;
        background-color: #999;
        border-radius: 2px;
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
        transform: translate(-50%, -50%) rotate(45deg)
    }

    .popup-confirm-code .popup__close-btn:before {
        width: 2px;
        height: 20px
    }

    .popup-confirm-code .popup__close-btn:after {
        width: 20px;
        height: 2px
    }
}

@media (max-width: 1023.98px) and (min-width: 1024px) {
    .popup-confirm-code .popup__close-btn {
        top: 8px;
        right: 8px
    }
}

@media (max-width: 1023.98px) and (max-width: 575.98px) {
    .popup-confirm-code .popup__close-btn ~ .popup__content {
        padding-top: 28px
    }
}

@media (max-width: 1023.98px) and (max-width: 575.98px) {
    .popup-confirm-code .popup__close-btn ~ .popup__content .popup__header {
        margin-top: -28px
    }
}

.popup-confirm-code .popup__header {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: #000;
    margin: 0 0 16px;
    padding-right: 24px
}

@media (min-width: 768px) {
    .popup-confirm-code .popup__header {
        font-size: 20px;
        line-height: 28px
    }
}

@media (min-width: 1024px) {
    .popup-confirm-code .popup__header {
        font-size: 28px;
        line-height: 36px
    }
}

@media (min-width: 1024px) {
    .popup-confirm-code .popup__header {
        margin: 0 0 20px;
        padding-right: 0
    }
}

.popup-confirm-code .popup__btn {
    margin: 10px 0 0;
    font-size: 0
}

@media (min-width: 576px) {
    .popup-confirm-code .popup__btn {
        margin: 20px 0 0
    }
}

.popup-confirm-code .popup__btn-main {
    font-family: system, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Segoe WP', Roboto, Ubuntu, Oxygen, Cantarell, 'Fira Sans', 'Helvetica Neue', Helvetica, 'Lucida Grande', 'Droid Sans', Tahoma, 'Microsoft Sans Serif', sans-serif;
    line-height: 1.1;
    letter-spacing: 0;
    font-weight: 700;
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    background-color: #cb11ab;
    border: 2px solid #cb11ab;
    font-size: 16px;
    padding: 11px 25px 12px;
    margin-top: 12px;
    margin-right: 20px
}

.popup-confirm-code .popup__btn-main.disabled, .popup-confirm-code .popup__btn-main.disabled:active, .popup-confirm-code .popup__btn-main.disabled:hover, .popup-confirm-code .popup__btn-main:disabled, .popup-confirm-code .popup__btn-main:disabled:active, .popup-confirm-code .popup__btn-main:disabled:hover {
    cursor: default
}

.popup-confirm-code .popup__btn-main.disabled:active:not(.progress), .popup-confirm-code .popup__btn-main.disabled:hover:not(.progress), .popup-confirm-code .popup__btn-main.disabled:not(.progress), .popup-confirm-code .popup__btn-main:disabled:active:not(.progress), .popup-confirm-code .popup__btn-main:disabled:hover:not(.progress), .popup-confirm-code .popup__btn-main:disabled:not(.progress) {
    color: #d7d7d7;
    background-color: #fff;
    border: 2px solid #d5d5d5;
    opacity: .9
}

.popup-confirm-code .popup__btn-main.disabled:active:not(.progress):active, .popup-confirm-code .popup__btn-main.disabled:hover:not(.progress):active, .popup-confirm-code .popup__btn-main.disabled:not(.progress):active, .popup-confirm-code .popup__btn-main:disabled:active:not(.progress):active, .popup-confirm-code .popup__btn-main:disabled:hover:not(.progress):active, .popup-confirm-code .popup__btn-main:disabled:not(.progress):active {
    -webkit-transform: none;
    transform: none
}

.popup-confirm-code .popup__btn-main.progress, .popup-confirm-code .popup__btn-main.progress:active {
    color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

@keyframes mySpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.popup-confirm-code .popup__btn-main.progress:active:after, .popup-confirm-code .popup__btn-main.progress:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 20px / 2);
    right: calc(50% - 20px / 2);
    -webkit-animation: mySpin .9s linear 0s infinite;
    animation: mySpin .9s linear 0s infinite
}

.popup-confirm-code .popup__btn-main.progress:active:after, .popup-confirm-code .popup__btn-main.progress:after {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%208A7.999%207.999%200%201%201%200%208h2.002a5.998%205.998%200%201%200%2011.996%200H16z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
    .popup-confirm-code .popup__btn-main:hover {
        background-color: #e313bf;
        border-color: #e313bf
    }

    .popup-confirm-code .popup__btn-main:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (hover: hover) and (pointer: fine) {
    .popup-confirm-code .popup__btn-main:hover {
        background-color: #e313bf;
        border-color: #e313bf
    }

    .popup-confirm-code .popup__btn-main:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (max-width: 575.98px) {
    .popup-confirm-code .popup__btn-main {
        margin-right: 15px
    }
}

.popup-confirm-code .popup__btn-base {
    font-family: system, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Segoe WP', Roboto, Ubuntu, Oxygen, Cantarell, 'Fira Sans', 'Helvetica Neue', Helvetica, 'Lucida Grande', 'Droid Sans', Tahoma, 'Microsoft Sans Serif', sans-serif;
    line-height: 1.1;
    letter-spacing: 0;
    font-weight: 700;
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    color: #cb11ab;
    font-size: 16px;
    padding: 11px 25px 12px;
    margin-top: 12px
}

.popup-confirm-code .popup__btn-base.disabled, .popup-confirm-code .popup__btn-base.disabled:active, .popup-confirm-code .popup__btn-base.disabled:hover, .popup-confirm-code .popup__btn-base:disabled, .popup-confirm-code .popup__btn-base:disabled:active, .popup-confirm-code .popup__btn-base:disabled:hover {
    cursor: default
}

.popup-confirm-code .popup__btn-base.disabled:active:not(.progress), .popup-confirm-code .popup__btn-base.disabled:hover:not(.progress), .popup-confirm-code .popup__btn-base.disabled:not(.progress), .popup-confirm-code .popup__btn-base:disabled:active:not(.progress), .popup-confirm-code .popup__btn-base:disabled:hover:not(.progress), .popup-confirm-code .popup__btn-base:disabled:not(.progress) {
    color: #d7d7d7;
    background-color: #fff;
    border: 2px solid #d5d5d5;
    opacity: .9
}

.popup-confirm-code .popup__btn-base.disabled:active:not(.progress):active, .popup-confirm-code .popup__btn-base.disabled:hover:not(.progress):active, .popup-confirm-code .popup__btn-base.disabled:not(.progress):active, .popup-confirm-code .popup__btn-base:disabled:active:not(.progress):active, .popup-confirm-code .popup__btn-base:disabled:hover:not(.progress):active, .popup-confirm-code .popup__btn-base:disabled:not(.progress):active {
    -webkit-transform: none;
    transform: none
}

.popup-confirm-code .popup__btn-base.progress, .popup-confirm-code .popup__btn-base.progress:active {
    color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

@keyframes mySpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.popup-confirm-code .popup__btn-base.progress:active:after, .popup-confirm-code .popup__btn-base.progress:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 20px / 2);
    right: calc(50% - 20px / 2);
    -webkit-animation: mySpin .9s linear 0s infinite;
    animation: mySpin .9s linear 0s infinite
}

.popup-confirm-code .popup__btn-base.progress:active:after, .popup-confirm-code .popup__btn-base.progress:after {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%208A7.999%207.999%200%201%201%200%208h2.002a5.998%205.998%200%201%200%2011.996%200H16z%22%20fill%3D%22%23CB11AB%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain
}

.popup-confirm-code .popup__btn-base, .popup-confirm-code .popup__btn-base.progress:active, .popup-confirm-code .popup__btn-base.progress:hover {
    background-color: #fff;
    border: 2px solid #cb11ab
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
    .popup-confirm-code .popup__btn-base:hover {
        background-color: #cb11ab;
        color: #fff
    }

    .popup-confirm-code .popup__btn-base:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (hover: hover) and (pointer: fine) {
    .popup-confirm-code .popup__btn-base:hover {
        background-color: #cb11ab;
        color: #fff
    }

    .popup-confirm-code .popup__btn-base:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

.popup-confirm-code .popup__info {
    font-size: 14px;
    line-height: 20px
}

@media (min-width: 768px) {
    .popup-confirm-code .popup__info {
        font-size: 16px;
        line-height: 22px
    }
}

.popup-confirm-code .popup__code-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    margin: 16px -4px 0
}

.popup-confirm-code .popup__form-block {
    width: calc(100% / 12 * 6 - 8px);
    margin: 0 4px
}

@media (min-width: 768px) and (max-width: 1023.98px) {
    .popup-confirm-code .popup__form-block {
        width: 140px
    }
}

.popup-confirm-code .popup__get-code {
    width: calc(100% / 12 * 6 - 8px);
    margin: 0 4px;
    padding-left: 4px;
    padding-right: 4px
}

@media (max-width: 1023.98px) {
    .popup-confirm-code .popup__get-code {
        font-size: 14px;
        line-height: 15px;
        text-transform: initial
    }
}

@media (min-width: 768px) and (max-width: 1023.98px) {
    .popup-confirm-code .popup__get-code {
        width: 140px
    }
}

.popup-confirm-code .popup__msg {
    font-size: 14px;
    line-height: 20px;
    margin-top: 8px
}

.popup-confirm-code .popup__msg:first-of-type {
    color: #cb11ab
}

@media (min-width: 768px) {
    .popup-confirm-code .popup__msg {
        font-size: 16px;
        line-height: 22px
    }
}

.popup-delete-card {
    font-size: 16px;
    line-height: 22px;
    color: #8b8b8b;
    display: none;
    width: 100%;
    max-width: 100%;
    min-width: 320px;
    padding: 12px 16px 16px;
    position: fixed;
    z-index: 1001;
    background: #fff;
    border-radius: 8px 8px 0 0;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2)
}

@media (min-width: 1024px) {
    .popup-delete-card {
        width: calc(100% - 32px);
        max-width: 412px;
        padding: 28px 40px 40px;
        border-radius: 8px;
        position: absolute
    }
}

@media (max-width: 1023.98px) {
    .popup-delete-card {
        top: auto !important;
        left: 0 !important;
        bottom: 0;
        max-height: 100%
    }
}

.popup-delete-card .close, .popup-delete-card .popup__close {
    width: 26px;
    height: 26px;
    display: inline-block;
    position: relative;
    font-size: 0;
    line-height: 1;
    border: none;
    background-color: transparent;
    position: absolute;
    z-index: 1;
    top: 13px;
    right: 12px;
    cursor: pointer
}

.popup-delete-card .close:after, .popup-delete-card .close:before, .popup-delete-card .popup__close:after, .popup-delete-card .popup__close:before {
    -webkit-transition: background .3s ease 0s;
    transition: background .3s ease 0s;
    background-color: #999;
    border-radius: 2px;
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg)
}

.popup-delete-card .close:before, .popup-delete-card .popup__close:before {
    width: 2px;
    height: 20px
}

.popup-delete-card .close:after, .popup-delete-card .popup__close:after {
    width: 20px;
    height: 2px
}

@media (min-width: 1024px) {
    .popup-delete-card .close, .popup-delete-card .popup__close {
        top: 8px;
        right: 8px
    }
}

@media (max-width: 575.98px) {
    .popup-delete-card .close ~ .popup__content, .popup-delete-card .popup__close ~ .popup__content {
        padding-top: 28px
    }
}

@media (max-width: 575.98px) {
    .popup-delete-card .close ~ .popup__content .popup__header, .popup-delete-card .popup__close ~ .popup__content .popup__header {
        margin-top: -28px
    }
}

.popup-delete-card .popup__close-btn {
    font-size: 16px;
    line-height: 22px;
    position: absolute;
    z-index: 1;
    top: 16px;
    right: 16px;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    padding: 4px 12px 6px;
    background-color: #fff;
    border-radius: 24px;
    border: none;
    cursor: pointer
}

@media (max-width: 1023.98px) {
    .popup-delete-card .popup__close-btn {
        width: 26px;
        height: 26px;
        display: inline-block;
        position: relative;
        font-size: 0;
        line-height: 1;
        border: none;
        background-color: transparent;
        position: absolute;
        z-index: 1;
        top: 13px;
        right: 12px;
        cursor: pointer;
        line-height: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        padding: 0;
        border-radius: 0
    }

    .popup-delete-card .popup__close-btn:after, .popup-delete-card .popup__close-btn:before {
        -webkit-transition: background .3s ease 0s;
        transition: background .3s ease 0s;
        background-color: #999;
        border-radius: 2px;
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
        transform: translate(-50%, -50%) rotate(45deg)
    }

    .popup-delete-card .popup__close-btn:before {
        width: 2px;
        height: 20px
    }

    .popup-delete-card .popup__close-btn:after {
        width: 20px;
        height: 2px
    }
}

@media (max-width: 1023.98px) and (min-width: 1024px) {
    .popup-delete-card .popup__close-btn {
        top: 8px;
        right: 8px
    }
}

@media (max-width: 1023.98px) and (max-width: 575.98px) {
    .popup-delete-card .popup__close-btn ~ .popup__content {
        padding-top: 28px
    }
}

@media (max-width: 1023.98px) and (max-width: 575.98px) {
    .popup-delete-card .popup__close-btn ~ .popup__content .popup__header {
        margin-top: -28px
    }
}

.popup-delete-card .popup__header {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: #000;
    margin: 0 0 16px;
    padding-right: 24px
}

@media (min-width: 768px) {
    .popup-delete-card .popup__header {
        font-size: 20px;
        line-height: 28px
    }
}

@media (min-width: 1024px) {
    .popup-delete-card .popup__header {
        font-size: 28px;
        line-height: 36px
    }
}

@media (min-width: 1024px) {
    .popup-delete-card .popup__header {
        margin: 0 0 20px;
        padding-right: 0
    }
}

.popup-delete-card .popup__btn {
    margin: 10px 0 0;
    font-size: 0
}

@media (min-width: 576px) {
    .popup-delete-card .popup__btn {
        margin: 20px 0 0
    }
}

.popup-delete-card .popup__btn-main {
    font-family: system, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Segoe WP', Roboto, Ubuntu, Oxygen, Cantarell, 'Fira Sans', 'Helvetica Neue', Helvetica, 'Lucida Grande', 'Droid Sans', Tahoma, 'Microsoft Sans Serif', sans-serif;
    line-height: 1.1;
    letter-spacing: 0;
    font-weight: 700;
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    background-color: #cb11ab;
    border: 2px solid #cb11ab;
    font-size: 16px;
    padding: 11px 25px 12px;
    margin-top: 12px;
    margin-right: 20px
}

.popup-delete-card .popup__btn-main.disabled, .popup-delete-card .popup__btn-main.disabled:active, .popup-delete-card .popup__btn-main.disabled:hover, .popup-delete-card .popup__btn-main:disabled, .popup-delete-card .popup__btn-main:disabled:active, .popup-delete-card .popup__btn-main:disabled:hover {
    cursor: default
}

.popup-delete-card .popup__btn-main.disabled:active:not(.progress), .popup-delete-card .popup__btn-main.disabled:hover:not(.progress), .popup-delete-card .popup__btn-main.disabled:not(.progress), .popup-delete-card .popup__btn-main:disabled:active:not(.progress), .popup-delete-card .popup__btn-main:disabled:hover:not(.progress), .popup-delete-card .popup__btn-main:disabled:not(.progress) {
    color: #d7d7d7;
    background-color: #fff;
    border: 2px solid #d5d5d5;
    opacity: .9
}

.popup-delete-card .popup__btn-main.disabled:active:not(.progress):active, .popup-delete-card .popup__btn-main.disabled:hover:not(.progress):active, .popup-delete-card .popup__btn-main.disabled:not(.progress):active, .popup-delete-card .popup__btn-main:disabled:active:not(.progress):active, .popup-delete-card .popup__btn-main:disabled:hover:not(.progress):active, .popup-delete-card .popup__btn-main:disabled:not(.progress):active {
    -webkit-transform: none;
    transform: none
}

.popup-delete-card .popup__btn-main.progress, .popup-delete-card .popup__btn-main.progress:active {
    color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

@keyframes mySpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.popup-delete-card .popup__btn-main.progress:active:after, .popup-delete-card .popup__btn-main.progress:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 20px / 2);
    right: calc(50% - 20px / 2);
    -webkit-animation: mySpin .9s linear 0s infinite;
    animation: mySpin .9s linear 0s infinite
}

.popup-delete-card .popup__btn-main.progress:active:after, .popup-delete-card .popup__btn-main.progress:after {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%208A7.999%207.999%200%201%201%200%208h2.002a5.998%205.998%200%201%200%2011.996%200H16z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
    .popup-delete-card .popup__btn-main:hover {
        background-color: #e313bf;
        border-color: #e313bf
    }

    .popup-delete-card .popup__btn-main:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (hover: hover) and (pointer: fine) {
    .popup-delete-card .popup__btn-main:hover {
        background-color: #e313bf;
        border-color: #e313bf
    }

    .popup-delete-card .popup__btn-main:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (max-width: 575.98px) {
    .popup-delete-card .popup__btn-main {
        margin-right: 15px
    }
}

.popup-delete-card .popup__btn-base {
    font-family: system, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Segoe WP', Roboto, Ubuntu, Oxygen, Cantarell, 'Fira Sans', 'Helvetica Neue', Helvetica, 'Lucida Grande', 'Droid Sans', Tahoma, 'Microsoft Sans Serif', sans-serif;
    line-height: 1.1;
    letter-spacing: 0;
    font-weight: 700;
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    color: #cb11ab;
    font-size: 16px;
    padding: 11px 25px 12px;
    margin-top: 12px
}

.popup-delete-card .popup__btn-base.disabled, .popup-delete-card .popup__btn-base.disabled:active, .popup-delete-card .popup__btn-base.disabled:hover, .popup-delete-card .popup__btn-base:disabled, .popup-delete-card .popup__btn-base:disabled:active, .popup-delete-card .popup__btn-base:disabled:hover {
    cursor: default
}

.popup-delete-card .popup__btn-base.disabled:active:not(.progress), .popup-delete-card .popup__btn-base.disabled:hover:not(.progress), .popup-delete-card .popup__btn-base.disabled:not(.progress), .popup-delete-card .popup__btn-base:disabled:active:not(.progress), .popup-delete-card .popup__btn-base:disabled:hover:not(.progress), .popup-delete-card .popup__btn-base:disabled:not(.progress) {
    color: #d7d7d7;
    background-color: #fff;
    border: 2px solid #d5d5d5;
    opacity: .9
}

.popup-delete-card .popup__btn-base.disabled:active:not(.progress):active, .popup-delete-card .popup__btn-base.disabled:hover:not(.progress):active, .popup-delete-card .popup__btn-base.disabled:not(.progress):active, .popup-delete-card .popup__btn-base:disabled:active:not(.progress):active, .popup-delete-card .popup__btn-base:disabled:hover:not(.progress):active, .popup-delete-card .popup__btn-base:disabled:not(.progress):active {
    -webkit-transform: none;
    transform: none
}

.popup-delete-card .popup__btn-base.progress, .popup-delete-card .popup__btn-base.progress:active {
    color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

@keyframes mySpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.popup-delete-card .popup__btn-base.progress:active:after, .popup-delete-card .popup__btn-base.progress:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 20px / 2);
    right: calc(50% - 20px / 2);
    -webkit-animation: mySpin .9s linear 0s infinite;
    animation: mySpin .9s linear 0s infinite
}

.popup-delete-card .popup__btn-base.progress:active:after, .popup-delete-card .popup__btn-base.progress:after {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%208A7.999%207.999%200%201%201%200%208h2.002a5.998%205.998%200%201%200%2011.996%200H16z%22%20fill%3D%22%23CB11AB%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain
}

.popup-delete-card .popup__btn-base, .popup-delete-card .popup__btn-base.progress:active, .popup-delete-card .popup__btn-base.progress:hover {
    background-color: #fff;
    border: 2px solid #cb11ab
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
    .popup-delete-card .popup__btn-base:hover {
        background-color: #cb11ab;
        color: #fff
    }

    .popup-delete-card .popup__btn-base:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

@media (hover: hover) and (pointer: fine) {
    .popup-delete-card .popup__btn-base:hover {
        background-color: #cb11ab;
        color: #fff
    }

    .popup-delete-card .popup__btn-base:active {
        color: #fff;
        background-color: #b30f97;
        border-color: #b30f97
    }
}

.popup-delete-card .popup-delete-card__content {
    font-size: 14px;
    line-height: 20px;
    color: #8b8b8b
}

@media (min-width: 1024px) {
    .popup-delete-card .popup-delete-card__content {
        font-size: 16px;
        line-height: 22px
    }
}

.popup-delete-card .popup-delete-card__content span {
    white-space: nowrap;
    color: #000
}

.popup-delete-card .popup__btn {
    width: calc(100% / 1 - -8px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 24px -4px 0
}

@media (min-width: 576px) and (max-width: 1023.98px) {
    .popup-delete-card .popup__btn {
        max-width: 368px
    }
}

.popup-delete-card .popup__btn-base, .popup-delete-card .popup__btn-main {
    width: calc(100% / 2 - 8px);
    margin: 0 4px
}

.i-tooltip-personal-discount {
    position: absolute;
    z-index: 50;
    cursor: default;
    background: #fff;
    border-radius: 8px;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    display: block
}

.i-tooltip-personal-discount .tooltipster-content {
    font-size: 14px;
    line-height: 20px;
    padding: 12px;
    width: 378px
}

@media (min-width: 1024px) {
    .i-tooltip-personal-discount {
        border-radius: 4px
    }
}

.i-tooltip-personal-discount .tooltipster-arrow {
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1
}

.i-tooltip-personal-discount .tooltipster-arrow span {
    width: 0;
    height: 0;
    position: absolute
}

.i-tooltip-personal-discount .tooltipster-arrow-right span {
    border-top: 9px solid transparent !important;
    border-bottom: 9px solid transparent !important;
    border-right: 10px solid;
    top: 50%;
    margin-top: -9px;
    left: -10px
}

.i-tooltip-personal-discount .tooltipster-arrow-left span {
    border-top: 9px solid transparent !important;
    border-bottom: 9px solid transparent !important;
    border-left: 10px solid;
    top: 50%;
    margin-top: -9px;
    right: -10px
}

.i-tooltip-personal-discount .tooltipster-arrow-top span, .i-tooltip-personal-discount .tooltipster-arrow-top-left span, .i-tooltip-personal-discount .tooltipster-arrow-top-right span {
    border-left: 9px solid transparent !important;
    border-right: 9px solid transparent !important;
    border-top: 10px solid;
    bottom: -10px
}

.i-tooltip-personal-discount .tooltipster-arrow-bottom span, .i-tooltip-personal-discount .tooltipster-arrow-bottom-left span, .i-tooltip-personal-discount .tooltipster-arrow-bottom-right span {
    border-left: 9px solid transparent !important;
    border-right: 9px solid transparent !important;
    border-bottom: 10px solid;
    top: -10px
}

.i-tooltip-personal-discount .tooltipster-arrow-bottom span, .i-tooltip-personal-discount .tooltipster-arrow-top span {
    left: 0;
    right: 0;
    margin: auto
}

.i-tooltip-personal-discount .tooltipster-arrow-bottom-left span, .i-tooltip-personal-discount .tooltipster-arrow-top-left span {
    left: 20px
}

.i-tooltip-personal-discount .tooltipster-arrow-bottom-right span, .i-tooltip-personal-discount .tooltipster-arrow-top-right span {
    right: 20px
}

@media (min-width: 1024px) {
    .i-tooltip-personal-discount p {
        font-size: 16px;
        line-height: 22px
    }
}

.i-tooltip-personal-discount p ~ p {
    margin-top: 16px
}

@media (max-width: 1023.98px) {
    .personal-data-page {
        margin-top: -20px
    }
}

.personal-data-page .personal-data__item--birthday .gldp {
    margin: 4px 0 0;
    padding: 10px 12px;
    font-size: 0;
    background: #fff;
    border-radius: 4px;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2)
}

.personal-data-page .personal-data__item--birthday .gldp::after {
    display: none
}

.personal-data-page .personal-data__item--birthday .gldp .monyear {
    line-height: 20px !important;
    border-bottom: 1px solid #d5d5d5 !important;
    border-radius: 0
}

.personal-data-page .personal-data__item--birthday .gldp .title {
    font-size: 12px;
    line-height: 16px;
    padding-top: 3px
}

.personal-data-page .personal-data__item--birthday .gldp .title span {
    font-weight: 700;
    color: #8b8b8b;
    text-transform: capitalize;
    margin: 0 8px;
    border-bottom: 1px dashed #8b8b8b;
    cursor: pointer
}

.personal-data-page .personal-data__item--birthday .gldp .next-arrow, .personal-data-page .personal-data__item--birthday .gldp .next-arrow-off, .personal-data-page .personal-data__item--birthday .gldp .prev-arrow, .personal-data-page .personal-data__item--birthday .gldp .prev-arrow-off {
    height: 12px;
    width: 8px;
    background: 0 0;
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: calc(50% - 6px)
}

.personal-data-page .personal-data__item--birthday .gldp .next-arrow-off::after, .personal-data-page .personal-data__item--birthday .gldp .next-arrow::after, .personal-data-page .personal-data__item--birthday .gldp .prev-arrow-off::after, .personal-data-page .personal-data__item--birthday .gldp .prev-arrow::after {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-left: 2px solid #b6b6b6;
    border-top: 2px solid #b6b6b6
}

.personal-data-page .personal-data__item--birthday .gldp .prev-arrow, .personal-data-page .personal-data__item--birthday .gldp .prev-arrow-off {
    left: 12px
}

.personal-data-page .personal-data__item--birthday .gldp .prev-arrow-off::after, .personal-data-page .personal-data__item--birthday .gldp .prev-arrow::after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    position: relative;
    top: 2px;
    left: 2px
}

.personal-data-page .personal-data__item--birthday .gldp .next-arrow, .personal-data-page .personal-data__item--birthday .gldp .next-arrow-off {
    right: 12px
}

.personal-data-page .personal-data__item--birthday .gldp .next-arrow-off::after, .personal-data-page .personal-data__item--birthday .gldp .next-arrow::after {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    position: relative;
    top: 2px;
    right: 2px
}

.personal-data-page .personal-data__item--birthday .gldp .core {
    font-size: 14px;
    line-height: 20px;
    display: inline-block;
    vertical-align: top;
    float: none;
    text-indent: 0;
    outline: 0;
    margin: 0 !important
}

.personal-data-page .personal-data__item--birthday .gldp .core:not(.monyear) {
    border-radius: 4px
}

.personal-data-page .personal-data__item--birthday .gldp .core.dow {
    text-transform: none;
    font-weight: 700;
    color: #a7a7a7
}

.personal-data-page .personal-data__item--birthday .gldp .selected {
    background: #cb11ab;
    color: #fff
}

.personal-data-page .personal-data__item--birthday .gldp .sat, .personal-data-page .personal-data__item--birthday .gldp .sun {
    color: #000;
    background: #fdeef9
}

.personal-data-page .personal-data__item--birthday .gldp .fri:hover, .personal-data-page .personal-data__item--birthday .gldp .mon:hover, .personal-data-page .personal-data__item--birthday .gldp .sat:hover, .personal-data-page .personal-data__item--birthday .gldp .sun:hover, .personal-data-page .personal-data__item--birthday .gldp .thu:hover, .personal-data-page .personal-data__item--birthday .gldp .tue:hover, .personal-data-page .personal-data__item--birthday .gldp .wed:hover {
    background: #cb11ab;
    color: #fff;
    border-color: #fff
}

.personal-data-page .personal-data__item--birthday .gldp .today {
    border-width: 1px 1px 1px 1px !important
}

.personal-data-page .personal-data__item--birthday .gldp .outday {
    color: #a7a7a7;
    background-color: transparent
}

.personal-data-page .personal-data__item--birthday .gldp .noday {
    cursor: default
}

.personal-data-page .personal-data__item--birthday .gldp {
    left: 0 !important;
    top: 100% !important
}

@media (max-width: 1023.98px) {
    .personal-data-page .personal-data__item--birthday .gldp {
        position: fixed;
        top: 50% !important;
        left: 50% !important;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%)
    }
}

.personal-data-page .personal-data-page__section {
    padding-bottom: 32px;
    margin-bottom: 32px;
    border-bottom: 1px solid #e8e8e8
}

.personal-data-page .personal-data-page__section:last-of-type {
    margin: 0
}

@media (max-width: 1023.98px) {
    .personal-data-page .personal-data-page__section:last-of-type {
        padding: 0;
        border: none
    }
}

@media (min-width: 1024px) {
    .personal-data-page .personal-data-page__section {
        padding: 28px;
        -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .1);
        box-shadow: 0 0 20px rgba(0, 0, 0, .1);
        border-radius: 20px
    }
}

.personal-data-page .personal-data-page__header {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 12px
}

@media (min-width: 1024px) {
    .personal-data-page .personal-data-page__header {
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 20px
    }
}

.personal-data-page .personal-data--main .personal-data__top-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 16px
}

@media (min-width: 768px) {
    .personal-data-page .personal-data--main .personal-data__top-wrap {
        margin-bottom: 12px
    }
}

@media (min-width: 1024px) {
    .personal-data-page .personal-data--main .personal-data__top-wrap {
        margin-bottom: 36px
    }
}

.personal-data-page .personal-data__header-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

@media (max-width: 767.98px) {
    .personal-data-page .personal-data__header-wrap {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between
    }
}

.personal-data-page .personal-data--main .personal-data__header {
    font-size: 16px;
    line-height: 22px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
    font-weight: 700;
    margin-left: 8px
}

@media (min-width: 768px) {
    .personal-data-page .personal-data--main .personal-data__header {
        font-size: 18px;
        line-height: 24px;
        margin-left: 12px
    }
}

@media (min-width: 1024px) {
    .personal-data-page .personal-data--main .personal-data__header {
        font-size: 28px;
        line-height: 36px;
        margin-left: 16px
    }
}

@media (min-width: 1024px) {
    .personal-data-page .personal-data__list {
        margin: 0 -12px
    }
}

@media (min-width: 768px) {
    .personal-data-page .personal-data__list--common {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }
}

.personal-data-page .personal-data__item {
    margin-bottom: 16px
}

.personal-data-page .personal-data__item:last-of-type {
    margin-bottom: 0
}

@media (min-width: 768px) {
    .personal-data-page .personal-data__item {
        margin-bottom: 0
    }
}

@media (min-width: 1024px) {
    .personal-data-page .personal-data__item {
        width: calc(100% / 12 * 3 - 24px);
        margin-left: 12px;
        margin-right: 12px
    }
}

@media (min-width: 1024px) {
    .personal-data-page .personal-data__item:not(:nth-of-type(4n+1)) {
        padding-left: 20px
    }
}

@media (min-width: 768px) {
    .personal-data-page .personal-data__item--email {
        padding-right: 20px
    }
}

@media (min-width: 768px) and (max-width: 1023.98px) {
    .personal-data-page .personal-data__item--email {
        width: 31%
    }
}

@media (min-width: 768px) {
    .personal-data-page .personal-data__item--phone {
        padding-right: 20px
    }
}

@media (min-width: 768px) and (max-width: 1023.98px) {
    .personal-data-page .personal-data__item--phone {
        width: 27%
    }
}

.personal-data-page .personal-data__item--birthday {
    position: relative
}

@media (min-width: 768px) {
    .personal-data-page .personal-data__item--birthday {
        padding-right: 20px
    }
}

@media (min-width: 768px) and (max-width: 1023.98px) {
    .personal-data-page .personal-data__item--birthday {
        width: 20%
    }
}

@media (min-width: 768px) and (max-width: 1023.98px) {
    .personal-data-page .personal-data__item--gender {
        width: 22%
    }
}

@media (min-width: 768px) {
    .personal-data-page .personal-data__item--inn {
        padding-right: 20px;
        margin-top: 16px
    }
}

@media (min-width: 768px) and (max-width: 1023.98px) {
    .personal-data-page .personal-data__item--inn {
        width: 31%
    }
}

@media (min-width: 768px) {
    .personal-data-page .personal-data__item--inn {
        margin-top: 28px
    }
}

.personal-data-page .personal-data__data-title {
    font-size: 12px;
    line-height: 16px;
    color: #8b8b8b;
    font-weight: 400;
    margin-bottom: 4px
}

@media (min-width: 1024px) {
    .personal-data-page .personal-data__data-title {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 8px
    }
}

.personal-data-page .personal-data__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

@media (max-width: 767.98px) {
    .personal-data-page .personal-data__content {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between
    }
}

.personal-data-page .personal-data__data {
    font-size: 14px;
    line-height: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%
}

@media (min-width: 1024px) {
    .personal-data-page .personal-data__data {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 8px
    }
}

.personal-data-page .personal-data__radio-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.personal-data-page .personal-data__radio {
    max-width: calc((100% / 2) - 28px);
    padding-left: 30px
}

@media (min-width: 768px) {
    .personal-data-page .personal-data__radio {
        max-width: calc((100% / 2) - 4px)
    }
}

@media (min-width: 1024px) {
    .personal-data-page .personal-data__radio {
        max-width: calc((100% / 2) - 8px)
    }
}

.personal-data-page .personal-data__radio:first-of-type {
    margin-right: 56px
}

@media (min-width: 768px) {
    .personal-data-page .personal-data__radio:first-of-type {
        margin-right: 8px
    }
}

@media (min-width: 1024px) {
    .personal-data-page .personal-data__radio:first-of-type {
        margin-right: 16px
    }
}

.personal-data-page .radio-with-text__text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block
}

@media (min-width: 768px) {
    .personal-data-page .radio-with-text__text.text-mob {
        display: none
    }
}

@media (max-width: 767.98px) {
    .personal-data-page .radio-with-text__text.text-tablet {
        display: none
    }
}

.personal-data-page .personal-data__edit {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 28px;
    height: 28px;
    background-size: 16px auto
}

.personal-data-page .personal-data__edit--name {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px)
}

@media (min-width: 1024px) {
    .personal-data-page .personal-data__edit--name {
        -webkit-transform: translateY(6px);
        transform: translateY(6px)
    }
}

.personal-data-page .personal-data__fake-edit, .personal-data-page .personal-data__list--common .personal-data__edit {
    -webkit-transform: translateY(-7px);
    transform: translateY(-7px)
}

@media (min-width: 1024px) {
    .personal-data-page .personal-data__fake-edit, .personal-data-page .personal-data__list--common .personal-data__edit {
        -webkit-transform: translateY(-4px);
        transform: translateY(-4px)
    }
}

.personal-data-page .personal-data__fake-edit label {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2217%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M12.407.758a.89.89%200%20011.253%200l2.08%202.08a.885.885%200%20010%201.253l-1.627%201.626-3.332-3.333L12.407.758zM0%2013.165l9.83-9.83%203.333%203.333-9.83%209.83H0v-3.333z%22%20fill%3D%22%23CB11AB%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: center;
    display: block;
    width: 26px;
    height: 26px;
    cursor: pointer
}

.personal-data-page .personal-data__fake-edit input {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden
}

.personal-data-page .user-photo {
    width: 64px;
    height: 64px;
    position: relative
}

@media (min-width: 768px) {
    .personal-data-page .user-photo {
        width: 80px;
        height: 80px
    }
}

.personal-data-page .user-photo.user-photo--success::before {
    display: none
}

.personal-data-page .user-photo__label {
    -webkit-transition: opacity .3s ease 0s;
    transition: opacity .3s ease 0s;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .3);
    border-radius: 50%;
    cursor: pointer;
    opacity: 0
}

.personal-data-page .user-photo__label:hover {
    opacity: 1
}

.personal-data-page .user-photo__text {
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #fff;
    max-height: 40px;
    max-width: 100%
}

.personal-data-page .user-photo__delete {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    z-index: 4;
    right: -4px;
    bottom: -4px;
    padding: 4px;
    border-radius: 50%
}

.personal-data-page .user-photo__delete .achtung-icon-white {
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    box-shadow: 0 0 20px rgba(0, 0, 0, .1)
}

.personal-data-page .user-photo__img-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
    position: relative;
    z-index: 1;
    border-radius: 50%;
    overflow: hidden
}

.personal-data-page .user-photo img {
    width: 100%;
    height: auto
}

.personal-data-page .user-photo input {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden
}

@media (min-width: 1024px) {
    .personal-data-page .payment-cards__list {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: 0 -12px
    }
}

.personal-data-page .payment-cards__item {
    position: relative;
    padding: 10px 38px 12px 12px;
    border: 1px solid #d5d5d5;
    border-radius: 12px
}

@media (min-width: 768px) {
    .personal-data-page .payment-cards__item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 12px
    }
}

@media (min-width: 1024px) {
    .personal-data-page .payment-cards__item {
        width: calc(100% / 12 * 3 - 24px);
        -webkit-transition: -webkit-box-shadow .3s ease 0s;
        transition: -webkit-box-shadow .3s ease 0s;
        transition: box-shadow .3s ease 0s;
        transition: box-shadow .3s ease 0s, -webkit-box-shadow .3s ease 0s;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        padding: 20px;
        margin: 0 12px;
        background-color: #f6f6f6;
        border: none;
        cursor: pointer
    }

    .personal-data-page .payment-cards__item.active {
        background-color: #fdeef9
    }

    .personal-data-page .payment-cards__item:hover {
        -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .1);
        box-shadow: 0 0 20px rgba(0, 0, 0, .1)
    }
}

@media (min-width: 1024px) {
    .personal-data-page .payment-cards__item.payment-cards__item--wbcard {
        color: #fff;
        background: -webkit-gradient(linear, left top, right top, from(#cb11ab), to(#481173));
        background: linear-gradient(90deg, #cb11ab, #481173 100%)
    }
}

@media (min-width: 1024px) {
    .personal-data-page .payment-cards__item.payment-cards__item--wbcard .payment-cards__set-default {
        color: #fff
    }
}

@media (min-width: 1024px) {
    .personal-data-page .payment-cards__item.payment-cards__item--wbcard .payment-cards__default::before {
        border-right-color: #fff;
        border-bottom-color: #fff
    }
}

.personal-data-page .payment-cards__item ~ .payment-cards__item {
    margin-top: 8px
}

@media (min-width: 1024px) {
    .personal-data-page .payment-cards__item ~ .payment-cards__item {
        margin-top: 0
    }
}

@media (min-width: 1024px) {
    .personal-data-page .payment-cards__item:nth-child(n+5) {
        margin-top: 24px
    }
}

@media (min-width: 1024px) {
    .personal-data-page .payment-cards__item--add {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        background-color: transparent;
        border: 4px solid #fdeef9
    }

    .personal-data-page .payment-cards__item--add:hover {
        -webkit-box-shadow: none;
        box-shadow: none
    }
}

.personal-data-page .payment-cards__card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

@media (min-width: 1024px) {
    .personal-data-page .payment-cards__card {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end
    }
}

.personal-data-page .payment-cards__card-img {
    width: 28px;
    height: 20px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-right: 8px;
    background-position: center
}

.personal-data-page .payment-cards__card-img.icon-vtb {
    width: 80px;
    height: 22px
}

@media (min-width: 1024px) and (max-width: 1108.98px) {
    .personal-data-page .payment-cards__card-img.icon-vtb {
        width: 57px;
        height: 22px;
        margin-right: 0
    }
}

@media (max-width: 1023.98px) {
    .personal-data-page .payment-cards__card-img.icon-vtb {
        width: 72px;
        height: 20px
    }
}

.personal-data-page .payment-cards__card-balance {
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    white-space: nowrap;
    margin-right: 8px
}

@media (max-width: 767.98px) {
    .personal-data-page .payment-cards__card-balance {
        font-size: 14px;
        line-height: 20px
    }
}

.personal-data-page .payment-cards__card-number {
    font-size: 14px;
    line-height: 20px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word
}

@media (min-width: 1024px) {
    .personal-data-page .payment-cards__card-number {
        font-size: 16px;
        line-height: 22px;
        text-align: right
    }
}

@media (max-width: 767.98px) {
    .personal-data-page .payment-cards__btn-wrap {
        margin-top: 4px;
        padding-left: 36px
    }
}

@media (min-width: 768px) {
    .personal-data-page .payment-cards__btn-wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }
}

@media (min-width: 1024px) {
    .personal-data-page .payment-cards__btn-wrap {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin-bottom: 60px
    }
}

.personal-data-page .payment-cards__default {
    font-size: 14px;
    line-height: 20px
}

@media (max-width: 767.98px) {
    .personal-data-page .payment-cards__default {
        color: #8b8b8b
    }
}

@media (min-width: 768px) {
    .personal-data-page .payment-cards__default {
        margin-right: 12px
    }
}

@media (min-width: 1024px) {
    .personal-data-page .payment-cards__default {
        font-size: 16px;
        line-height: 22px
    }

    .personal-data-page .payment-cards__default::before {
        content: '';
        border-right: 2px solid #cb11ab;
        border-bottom: 2px solid #cb11ab;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        display: inline-block;
        position: absolute;
        z-index: 1;
        width: 5px;
        height: 11px;
        position: static;
        margin-right: 8px
    }
}

.personal-data-page .payment-cards__set-default {
    font-size: 14px;
    line-height: 20px;
    color: #cb11ab
}

@media (min-width: 768px) {
    .personal-data-page .payment-cards__set-default {
        margin-right: 12px
    }
}

@media (min-width: 1024px) {
    .personal-data-page .payment-cards__set-default {
        font-size: 16px;
        line-height: 22px
    }
}

.personal-data-page .payment-cards__delete {
    -webkit-transition: opacity .3s ease 0s;
    transition: opacity .3s ease 0s;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 6px
}

@media (max-width: 767.98px) {
    .personal-data-page .payment-cards__delete {
        position: absolute;
        top: 6px;
        right: 6px
    }
}

@media (min-width: 768px) {
    .personal-data-page .payment-cards__delete {
        margin: -6px
    }
}

@media (min-width: 1024px) {
    .personal-data-page .payment-cards__delete {
        opacity: 0
    }
}

.personal-data-page .payment-cards__item:hover .payment-cards__delete {
    opacity: 1
}

.personal-data-page .payment-cards__add-card {
    font-size: 14px;
    line-height: 20px;
    color: #000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 12px
}

@media (min-width: 1024px) {
    .personal-data-page .payment-cards__add-card {
        font-size: 16px;
        line-height: 22px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        color: #cb11ab
    }
}

.personal-data-page .payment-cards__add-card::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0
}

.personal-data-page .payment-cards__card-decor {
    width: 28px;
    height: 20px;
    margin-right: 8px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2222%22%20height%3D%2218%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M20.182%200H1.818C.814%200%200%20.895%200%202v12c0%201.105.814%202%201.818%202H13v-2H2V7h18v3h2V2c0-1.105-.814-2-1.818-2zM20%205V2H2v3h18zm-4%2011v-2h2v-2h2v2h2v2h-2v2h-2v-2h-2z%22%20fill%3D%22%23000%22%2F%3E%3C%2Fsvg%3E)
}

@media (min-width: 1024px) {
    .personal-data-page .payment-cards__card-decor {
        position: relative;
        width: 32px;
        height: 32px;
        margin: 0 0 16px;
        background-image: none;
        border: 2px solid #cb11ab;
        border-radius: 50%
    }

    .personal-data-page .payment-cards__card-decor::after, .personal-data-page .payment-cards__card-decor::before {
        content: "";
        display: block;
        width: 18px;
        height: 2px;
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: #cb11ab;
        border-radius: 4px
    }

    .personal-data-page .payment-cards__card-decor::before {
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%)
    }

    .personal-data-page .payment-cards__card-decor::after {
        -webkit-transform: translate(-50%, -50%) rotate(90deg);
        transform: translate(-50%, -50%) rotate(90deg)
    }
}

@media (min-width: 768px) {
    .personal-data-page .requisites__list {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: 0 -4px
    }
}

@media (min-width: 1024px) {
    .personal-data-page .requisites__list {
        margin: 0 -12px
    }
}

.personal-data-page .requisites__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 12px;
    border: 1px solid #d5d5d5;
    border-radius: 12px
}

@media (min-width: 768px) {
    .personal-data-page .requisites__item {
        width: calc(100% / 12 * 6 - 8px);
        margin: 0 4px
    }
}

@media (min-width: 1024px) {
    .personal-data-page .requisites__item {
        width: calc(100% / 12 * 3 - 24px);
        margin: 0 12px;
        padding: 20px;
        border: none;
        background-color: #f6f6f6
    }

    .personal-data-page .requisites__item:first-of-type {
        background-color: #fdeef9
    }

    .personal-data-page .requisites__item:nth-child(n+5) {
        margin-top: 24px
    }
}

@media (max-width: 767.98px) {
    .personal-data-page .requisites__item ~ .requisites__item {
        margin-top: 8px
    }
}

.personal-data-page .requisites__card-title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    width: calc(100% - 56px);
    padding-right: 8px
}

@media (min-width: 1024px) {
    .personal-data-page .requisites__card-title {
        font-size: 16px;
        line-height: 22px
    }
}

.personal-data-page .requisites__btn-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transform: translate(4px, -4px);
    transform: translate(4px, -4px)
}

.personal-data-page .requisites__card-number {
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
    font-size: 14px;
    line-height: 20px
}

@media (min-width: 1024px) {
    .personal-data-page .requisites__card-number {
        font-size: 16px;
        line-height: 22px
    }
}

.personal-data-page .requisites__info, .personal-data-page .requisites__max-count {
    font-size: 14px;
    line-height: 20px;
    color: #8b8b8b;
    margin-top: 16px
}

@media (min-width: 1024px) {
    .personal-data-page .requisites__info, .personal-data-page .requisites__max-count {
        font-size: 16px;
        line-height: 22px
    }
}

.personal-data-page .requisites__add {
    border-bottom: 1px solid;
    font-size: 16px;
    line-height: 22px;
    color: #000;
    border-color: initial;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
    color: #cb11ab;
    border-color: transparent;
    font-weight: 700;
    display: inline-block;
    vertical-align: middle;
    margin-top: 12px
}

.personal-data-page .requisites__add:hover {
    color: #cb11ab;
    border-color: initial
}

@media (max-width: 1023.98px) {
    .personal-data-page .requisites__add {
        font-size: 14px;
        line-height: 20px
    }
}

@media (min-width: 1024px) {
    .personal-data-page .requisites__add {
        margin-top: 20px
    }
}

.personal-data-page .requisites__edit {
    width: 28px;
    height: 28px
}

.personal-data-page .requisites__delete {
    width: 28px;
    height: 28px;
    padding: 4px
}

@media (min-width: 1024px) {
    .personal-data-page .discount__header {
        margin-bottom: 16px;
        max-width: 75%
    }
}

.personal-data-page .discount__info {
    font-size: 12px;
    line-height: 16px;
    color: #8b8b8b;
    margin-bottom: 12px
}

@media (min-width: 1024px) {
    .personal-data-page .discount__info {
        font-size: 16px;
        line-height: 22px;
        max-width: 75%;
        margin-bottom: 0
    }
}

@media (min-width: 768px) {
    .personal-data-page .discount__list {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: 0 -4px
    }
}

@media (min-width: 1024px) {
    .personal-data-page .discount__list {
        height: 380px;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
        margin: -78px -12px 0
    }
}

.personal-data-page .discount__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 12px;
    margin-bottom: 8px;
    background-color: #f6f6f6;
    border-radius: 12px
}

.personal-data-page .discount__item:last-of-type {
    margin-bottom: 0
}

@media (min-width: 768px) {
    .personal-data-page .discount__item {
        width: calc(100% / 12 * 6 - 8px);
        margin: 0 4px 8px
    }

    .personal-data-page .discount__item:nth-last-child(-n+2) {
        margin-bottom: 0
    }
}

@media (min-width: 1024px) {
    .personal-data-page .discount__item {
        width: calc(100% / 12 * 3 - 24px);
        margin: 0 12px;
        padding: 20px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .personal-data-page .discount__item:nth-of-type(1) {
        height: 36%
    }

    .personal-data-page .discount__item:nth-of-type(2) {
        height: 50%
    }

    .personal-data-page .discount__item:nth-of-type(3) {
        height: 70%
    }

    .personal-data-page .discount__item:nth-of-type(4) {
        height: 100%
    }
}

.personal-data-page .discount__item.active {
    background-color: #fdeef9
}

.personal-data-page .discount__percent {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700
}

@media (min-width: 1024px) {
    .personal-data-page .discount__percent {
        font-size: 28px;
        line-height: 36px
    }
}

.personal-data-page .discount__percent span {
    display: inline-block;
    vertical-align: top
}

.personal-data-page .discount__percent del {
    font-size: 12px;
    line-height: 16px;
    color: #999;
    display: inline-block;
    vertical-align: top;
    margin-top: 1px
}

@media (min-width: 1024px) {
    .personal-data-page .discount__percent del {
        font-size: 20px;
        line-height: 28px
    }
}

.personal-data-page .discount__item.active .discount__percent {
    color: #cb11ab
}

.personal-data-page .discount__condition {
    font-size: 14px;
    line-height: 20px;
    color: #8b8b8b;
    text-align: right
}

@media (min-width: 1024px) {
    .personal-data-page .discount__condition {
        font-size: 18px;
        line-height: 24px;
        text-align: left;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        max-height: 48px;
        white-space: normal
    }
}

.personal-data-page .discount__item.active .discount__condition {
    color: #000
}

.personal-data-page .discount__btn-info {
    border-bottom: 1px dashed;
    font-size: 16px;
    line-height: 22px;
    color: #000;
    border-color: initial;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
    color: #8b8b8b;
    border-color: #8b8b8b;
    font-size: 14px;
    line-height: 20px;
    margin-top: 12px
}

.personal-data-page .discount__btn-info:hover {
    color: #cb11ab;
    border-color: initial
}

.personal-data-page .discount__btn-info:hover {
    color: #000;
    border-color: #000
}

@media (min-width: 1024px) {
    .personal-data-page .discount__btn-info {
        font-size: 16px;
        line-height: 22px
    }
}

@media (min-width: 1024px) {
    .personal-data-page .discount__btn-info {
        font-size: 18px;
        line-height: 24px;
        margin-top: 16px
    }
}

@media (min-width: 768px) {
    .personal-data-page .subscriptions__list {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin: 0 -4px
    }
}

@media (min-width: 1024px) {
    .personal-data-page .subscriptions__list {
        margin: 0 -12px
    }
}

@media (min-width: 768px) {
    .personal-data-page .subscriptions__item {
        width: calc(100% / 12 * 6 - 8px);
        margin: 0 4px
    }
}

@media (min-width: 1024px) {
    .personal-data-page .subscriptions__item {
        margin: 0 12px
    }
}

@media (max-width: 767.98px) {
    .personal-data-page .subscriptions__item ~ .subscriptions__item {
        margin-top: 24px
    }
}

@media (min-width: 1024px) {
    .personal-data-page .subscriptions__item--first {
        width: calc(100% / 12 * 9 - 24px)
    }
}

@media (min-width: 1024px) {
    .personal-data-page .subscriptions__item--second {
        width: calc(100% / 12 * 3 - 24px);
        padding-left: 20px
    }
}

.personal-data-page .subscriptions__fieldset {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

@media (min-width: 1024px) {
    .personal-data-page .subscriptions__item--first .subscriptions__fieldset {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: 0 -12px
    }
}

.personal-data-page .subscriptions__legend {
    font-size: 14px;
    line-height: 20px;
    color: #8b8b8b;
    width: 100%
}

@media (min-width: 1024px) {
    .personal-data-page .subscriptions__legend {
        font-size: 16px;
        line-height: 22px
    }
}

@media (min-width: 1024px) {
    .personal-data-page .subscriptions__item--first .subscriptions__legend {
        margin-left: 12px
    }
}

.personal-data-page .subscriptions__label {
    margin-top: 12px
}

@media (min-width: 1024px) {
    .personal-data-page .subscriptions__label {
        margin-top: 16px
    }
}

.personal-data-page .subscriptions__item--first .subscriptions__label:nth-of-type(1) {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2
}

.personal-data-page .subscriptions__item--first .subscriptions__label:nth-of-type(2) {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3
}

.personal-data-page .subscriptions__item--first .subscriptions__label:nth-of-type(3) {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1
}

@media (min-width: 1024px) {
    .personal-data-page .subscriptions__item--first .subscriptions__label {
        width: calc(100% / 12 * 4 - 24px);
        margin-left: 12px;
        margin-right: 12px
    }

    .personal-data-page .subscriptions__item--first .subscriptions__label:nth-of-type(1), .personal-data-page .subscriptions__item--first .subscriptions__label:nth-of-type(2) {
        padding-left: 54px
    }

    .personal-data-page .subscriptions__item--first .subscriptions__label:nth-of-type(1) .checkbox-with-text__decor, .personal-data-page .subscriptions__item--first .subscriptions__label:nth-of-type(2) .checkbox-with-text__decor {
        left: 20px
    }
}

.personal-data-page .subscriptions__label .checkbox-with-text__text {
    display: inline-block;
    vertical-align: top;
    max-width: 235px
}

@media (min-width: 1024px) {
    .personal-data-page .subscriptions__label .checkbox-with-text__text {
        max-width: initial
    }
}

.personal-data-page .subscriptions__msg {
    font-size: 14px;
    line-height: 20px;
    color: #8b8b8b
}

@media (min-width: 1024px) {
    .personal-data-page .subscriptions__msg {
        font-size: 16px;
        line-height: 22px
    }
}

.personal-data-page .subscriptions__msg:first-of-type {
    color: #cb11ab;
    margin-top: 24px
}

@media (min-width: 1024px) {
    .personal-data-page .subscriptions__msg:first-of-type {
        margin-top: 32px
    }
}

.personal-data-page .personal-safety__item {
    position: relative;
    margin-bottom: 8px;
    padding: 12px 36px 12px 12px
}

@media (min-width: 768px) {
    .personal-data-page .personal-safety__item {
        padding: 12px
    }
}

@media (max-width: 1023.98px) {
    .personal-data-page .personal-safety__item {
        border: 1px solid #d5d5d5;
        border-radius: 12px
    }
}

@media (min-width: 1024px) {
    .personal-data-page .personal-safety__item {
        margin-bottom: 28px;
        padding: 0
    }
}

.personal-data-page .personal-safety__item:last-of-type {
    margin-bottom: 0
}

.personal-data-page .personal-safety__text-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

@media (min-width: 768px) {
    .personal-data-page .personal-safety__text-container {
        margin: 0 -4px
    }
}

@media (min-width: 1024px) {
    .personal-data-page .personal-safety__text-container {
        margin: 0 -12px
    }
}

.personal-data-page .personal-safety__text {
    font-size: 14px;
    line-height: 20px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
    margin: 0 4px 4px 0
}

.personal-data-page .personal-safety__text:last-of-type {
    margin-bottom: 0
}

@media (min-width: 768px) {
    .personal-data-page .personal-safety__text {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: calc(100% / 12 * 6 - 8px);
        margin-left: 4px;
        margin-right: 4px
    }
}

@media (min-width: 1024px) {
    .personal-data-page .personal-safety__text {
        font-size: 16px;
        line-height: 22px;
        width: calc(100% / 12 * 3 - 24px);
        margin-left: 12px;
        margin-right: 12px
    }
}

.personal-data-page .personal-safety__item:first-of-type .personal-safety__text {
    color: #000;
    font-weight: 700
}

@media (min-width: 1024px) {
    .personal-data-page .personal-safety__text--device {
        -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5
    }
}

.personal-data-page .personal-safety__text--date {
    width: 100%
}

@media (min-width: 1024px) {
    .personal-data-page .personal-safety__text--date {
        width: calc(100% / 12 * 3 - 24px);
        padding-left: 20px;
        padding-right: 24px
    }
}

@media (min-width: 1024px) {
    .personal-data-page .personal-safety__text--geo, .personal-data-page .personal-safety__text--ip {
        padding-left: 20px
    }
}

.personal-data-page .personal-safety__text--break {
    width: 100%;
    margin: 0
}

@media (min-width: 768px) {
    .personal-data-page .personal-safety__text--break {
        display: none
    }
}

.personal-data-page .personal-safety__delete {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 28px;
    height: 28px;
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 4px
}

@media (min-width: 1024px) {
    .personal-data-page .personal-safety__delete {
        top: -2px;
        right: -4px
    }
}

.personal-data-page .personal-safety__btn-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 12px
}

@media (min-width: 1024px) {
    .personal-data-page .personal-safety__btn-wrap {
        margin-top: 20px
    }
}

.personal-data-page .personal-safety__close-all {
    border-bottom: 1px solid;
    font-size: 16px;
    line-height: 22px;
    color: #000;
    border-color: initial;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
    color: #cb11ab;
    border-color: transparent;
    font-weight: 700
}

.personal-data-page .personal-safety__close-all:hover {
    color: #cb11ab;
    border-color: initial
}

@media (max-width: 1023.98px) {
    .personal-data-page .personal-safety__close-all {
        font-size: 14px;
        line-height: 20px
    }
}

.personal-data-page .personal-safety__show {
    border-bottom: 1px dashed;
    font-size: 16px;
    line-height: 22px;
    color: #000;
    border-color: initial;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
    color: #8b8b8b;
    border-color: #8b8b8b;
    font-size: 14px;
    line-height: 20px
}

.personal-data-page .personal-safety__show:hover {
    color: #cb11ab;
    border-color: initial
}

.personal-data-page .personal-safety__show:hover {
    color: #000;
    border-color: #000
}

@media (min-width: 1024px) {
    .personal-data-page .personal-safety__show {
        font-size: 16px;
        line-height: 22px
    }
}

.personal-data-page .delete-profile__info {
    font-size: 14px;
    line-height: 20px;
    color: #8b8b8b
}

@media (min-width: 1024px) {
    .personal-data-page .delete-profile__info {
        font-size: 16px;
        line-height: 22px
    }
}

.personal-data-page .delete-profile__info ~ .delete-profile__info {
    margin-top: 12px
}

@media (min-width: 1024px) {
    .personal-data-page .delete-profile__info ~ .delete-profile__info {
        margin-top: 16px
    }
}

.personal-data-page .delete-profile__info--important {
    color: #cb11ab
}

.personal-data-page .delete-profile__info--important strong {
    font-weight: 400
}

.personal-data-page .delete-profile__info a {
    border-bottom: 1px solid;
    font-size: 16px;
    line-height: 22px;
    border-color: initial;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
    color: #000;
    border-color: #000
}

.personal-data-page .delete-profile__info a:hover {
    color: #cb11ab;
    border-color: initial
}

.personal-data-page .delete-profile__info a:hover {
    color: #000;
    border-color: transparent
}

@media (max-width: 1023.98px) {
    .personal-data-page .delete-profile__info a {
        font-size: 14px;
        line-height: 20px
    }
}

.personal-data-page .delete-profile__btn {
    border-bottom: 1px solid;
    font-size: 16px;
    line-height: 22px;
    color: #000;
    border-color: initial;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
    color: #cb11ab;
    border-color: transparent;
    font-weight: 700;
    display: inline-block;
    vertical-align: top;
    margin-top: 12px
}

.personal-data-page .delete-profile__btn:hover {
    color: #cb11ab;
    border-color: initial
}

@media (max-width: 1023.98px) {
    .personal-data-page .delete-profile__btn {
        font-size: 14px;
        line-height: 20px
    }
}

@media (min-width: 1024px) {
    .personal-data-page .delete-profile__btn {
        margin-top: 20px
    }
}